import React from 'react'
import { useSelector } from 'react-redux'
import styled from '@emotion/styled'
import i from 'libs/i18n'
import { RootState } from 'libs/store'

const PartnerHeaderContainer: React.FC = () => {
  const partnerList = useSelector((state: RootState) => state.app.partnerList)
  const selectedPartnerId = useSelector((state: RootState) => state.app.selectedPartnerId)
  const partnerInfo = partnerList?.find((partner) => partner.partnerId === selectedPartnerId)

  if (!partnerInfo) return null

  const { partnerId, thumbnail } = partnerInfo
  return (
    <PartnerHeaderWrapper>
      <div className="shop_info_wrapper">
        <img src={`${thumbnail}?h=200&w=200&q=60&f=webp`} alt="shop_logo" />
        <div className="info_wrapper">
          <div className="title">{i.t(`common_brand.${partnerId}`)}</div>
        </div>
      </div>
      <div className="cover_image" />
    </PartnerHeaderWrapper>
  )
}

const PartnerHeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  width: 100%;

  .cover_image {
    position: relative;
    width: 100%;
    height: 250px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    ::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 20;
      background-image: linear-gradient(to right, rgba(67, 143, 251, 1), rgba(67, 143, 251 0));
    }
  }

  .shop_info_wrapper {
    display: flex;
    align-items: center;

    img {
      border-radius: 50%;
      width: 100px;
      height: 100px;
    }

    .info_wrapper {
      margin-left: 20px;

      .title {
        font-size: 32px;
        font-weight: bold;
        color: #ffffff;
      }

      .collection_link_wrapper {
        display: flex;
        align-items: center;
        margin-top: 5px;

        .text {
          font-size: 14px;
          font-weight: 500;
          color: #ffffff;
        }

        .icon {
          width: 16px;
          height: 16px;
          background-image: url('icon/ic-ForwardArrow.svg');
          background-size: contain;
          background-repeat: no-repeat;
        }
      }
    }
  }
`

export default PartnerHeaderContainer
