import { call, delay, put, select, takeEvery } from 'redux-saga/effects'
import { _getDUID } from 'libs/kinesis/kinesis'
import { setUserToken } from 'libs/singleton'
import API from '../../api'
import { RootState } from '..'
import {
  REQ_GENERATE_USER_TOKEN,
  REQ_INIT_USER_SESSION,
  REQ_RESET_USER_SESSION,
  RES_INIT_USER_SESSION,
} from './session.store'

function* generateUserToken() {
  const selectedBroadcast: Broadcast | null = yield select(
    (state: RootState) => state.player.selectedBroadcast,
  )
  if (!selectedBroadcast) return

  const { partnerId } = selectedBroadcast

  const data = {
    partnerId,
    nickName: `SMART_TV_${Math.floor(Math.random() * Math.pow(10, 10))}`,
    memberType: '0',
    age: 'etc',
    gender: 'e',
    expiresIn: 315360000,
    refreshExpiresIn: 3600,
    userId: _getDUID(),
    userName: _getDUID(),
  }
  const res: {
    response: Token & {
      accessToken: string
    }
  } = yield call(API.postToken, data)

  const userToken = res.response.accessToken
  localStorage.setItem(`accessToken${`_${partnerId}`}`, userToken)
  setUserToken(userToken)

  delay(0.5 * 1000)

  yield put(RES_INIT_USER_SESSION({ ...res.response, tokenType: '0' }))
}

function* initUserSession() {
  const selectedBroadcast: Broadcast | null = yield select(
    (state: RootState) => state.player.selectedBroadcast,
  )
  if (!selectedBroadcast) return

  const { broadcastId, partnerId } = selectedBroadcast

  const userToken = localStorage.getItem(`accessToken${`_${partnerId}`}`)

  try {
    if (!userToken) throw new Error('no token')
    const res: {
      response: Token
    } = yield call(API.getTokenValidation, {
      accessToken: userToken,
      broadcastId,
      tokenType: '0',
    })

    setUserToken(userToken)

    yield put(RES_INIT_USER_SESSION({ ...res.response, tokenType: '0' }))
  } catch (e) {
    yield put(REQ_GENERATE_USER_TOKEN())
  }
}

function* resetUserSession() {
  const selectedBroadcast: Broadcast | null = yield select(
    (state: RootState) => state.player.selectedBroadcast,
  )
  if (!selectedBroadcast) return

  const { partnerId } = selectedBroadcast

  localStorage.removeItem(`accessToken${`_${partnerId}`}`)
  setUserToken(undefined)

  yield put(REQ_GENERATE_USER_TOKEN())
}

export default function* sessionSaga() {
  yield takeEvery(REQ_GENERATE_USER_TOKEN, generateUserToken)
  yield takeEvery(REQ_INIT_USER_SESSION, initUserSession)
  yield takeEvery(REQ_RESET_USER_SESSION, resetUserSession)
}
