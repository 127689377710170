import React, { useEffect, useLayoutEffect } from 'react'
import { useDispatch } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ThemeProvider } from '@emotion/react'
import HomePage from 'pages/HomePage'
import PartnerPage from 'pages/PartnerPage'
import PlayerPage from 'pages/PlayerPage'
import SchedulePage from 'pages/SchedulePage'
import RouteWrapper from 'wrappers/RouteWrapper'
import { SET_IS_SHOW_POLICY } from 'libs/store/interaction/interaction.store'
import GlobalStyle from 'libs/style/global'
import { defaultTheme } from 'libs/style/theme'

function App() {
  const dispatch = useDispatch()

  /**
   * tizen 객체 초기화 useEffect
   */
  useEffect(() => {
    try {
      // @ts-ignore tizen 타입스크립트 에러 비활성화 목적
      window.tizen = tizen
    } catch (_e) {
      console.error('No tizen.')
    }
  }, [])

  /**
   * 스토리지에 저장된 정책 숨기기 여부 로드 및 없는 경우 정책 보여주기 useEffect
   */
  useLayoutEffect(() => {
    if (localStorage.getItem('policyHideState')) return
    dispatch(SET_IS_SHOW_POLICY(true))
  }, [dispatch])

  /**
   * 키다운 이벤트 매핑 useEffect
   */
  useEffect(() => {
    const handleKeydownApp = (e: KeyboardEvent) => {
      e.preventDefault()

      dispatch(SET_IS_SHOW_POLICY(false))
      localStorage.setItem('policyHideState', 'true')
    }
    window.addEventListener('keydown', handleKeydownApp)

    return () => {
      window.removeEventListener('keydown', handleKeydownApp)
    }
  }, [dispatch])

  /**
   * preview 로 들어왔을 경우 visibility 에 따라 앱 종료 함수 및 useEffect
   */

  useEffect(() => {
    const exitAppHandler = () => {
      if (document.hidden) {
        if (window.tizen) {
          window.tizen.application.getCurrentApplication().exit() // 웹뷰에서는 작동불가
        }
      }
    }
    document.addEventListener('visibilitychange', exitAppHandler)
    return () => {
      document.removeEventListener('visibilitychange', exitAppHandler)
    }
  }, [])

  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyle />
      <BrowserRouter>
        <Routes>
          <Route element={<RouteWrapper />}>
            <Route path="/" element={<HomePage />} />
            <Route path="/schedule" element={<SchedulePage />} />
            <Route path="/partners" element={<PartnerPage />} />
            <Route path="/player" element={<PlayerPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App
