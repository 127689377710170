import React from 'react'
import styled from '@emotion/styled'
import Lottie from 'lottie-react'
import LoadingCSS from './Loading'
import loadingLottie from './sauceTV_splash.json'

interface SplashProps {
  onPause?: (status: unknown) => void
  isShowSplash: boolean
}

const Splash = ({ onPause, isShowSplash }: SplashProps) => {
  return (
    <>
      {isShowSplash ? (
        <LottieWrapper>
          <Lottie animationData={loadingLottie} loop={false} onComplete={onPause} />
        </LottieWrapper>
      ) : (
        <>{!isShowSplash && <LoadingCSS />}</>
      )}
    </>
  )
}

const LottieWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  z-index: 10;
`

export default Splash
