import React from 'react'
import { css, Global } from '@emotion/react'
const GlobalStyle = () => {
  return (
    <Global
      styles={css`
        @font-face {
          font-family: 'SamsungOneUI 600';
          src: url('https://d2kkk8cefusmqi.cloudfront.net/fonts/SamsungOneUI-600.eot');
          src: url('https://d2kkk8cefusmqi.cloudfront.net/fonts/SamsungOneUI-600.eot?#iefix')
              format('embedded-opentype'),
            url('https://d2kkk8cefusmqi.cloudfront.net/fonts/SamsungOneUI-600.woff2')
              format('font/woff2'),
            url('https://d2kkk8cefusmqi.cloudfront.net/fonts/SamsungOneUI-600.woff')
              format('font/woff'),
            url('https://d2kkk8cefusmqi.cloudfront.net/fonts/SamsungOneUI-600.ttf')
              format('font/truetype'),
            url('https://d2kkk8cefusmqi.cloudfront.net/fonts/SamsungOneUI-600.svg#SamsungOneUI-600')
              format('svg');
          font-weight: normal;
          font-style: normal;
          font-display: swap;
        }

        @font-face {
          font-family: 'SamsungOneUI 200';
          src: url('https://d2kkk8cefusmqi.cloudfront.net/fonts/SamsungOneUI-200.eot');
          src: url('https://d2kkk8cefusmqi.cloudfront.net/fonts/SamsungOneUI-200.eot?#iefix')
              format('embedded-opentype'),
            url('https://d2kkk8cefusmqi.cloudfront.net/fonts/SamsungOneUI-200.woff2')
              format('font/woff2'),
            url('https://d2kkk8cefusmqi.cloudfront.net/fonts/SamsungOneUI-200.woff')
              format('font/woff'),
            url('https://d2kkk8cefusmqi.cloudfront.net/fonts/SamsungOneUI-200.ttf')
              format('font/truetype'),
            url('https://d2kkk8cefusmqi.cloudfront.net/fonts/SamsungOneUI-200.svg#SamsungOneUI-200')
              format('svg');
          font-weight: 300;
          font-style: normal;
          font-display: swap;
        }

        @font-face {
          font-family: 'SamsungOneUIKorean 200';
          src: url('https://d2kkk8cefusmqi.cloudfront.net/fonts/SamsungOneUIKorean-200.eot');
          src: url('https://d2kkk8cefusmqi.cloudfront.net/fonts/SamsungOneUIKorean-200.eot?#iefix')
              format('embedded-opentype'),
            url('https://d2kkk8cefusmqi.cloudfront.net/fonts/SamsungOneUIKorean-200.woff2')
              format('font/woff2'),
            url('https://d2kkk8cefusmqi.cloudfront.net/fonts/SamsungOneUIKorean-200.woff')
              format('font/woff'),
            url('https://d2kkk8cefusmqi.cloudfront.net/fonts/SamsungOneUIKorean-200.ttf')
              format('font/truetype'),
            url('https://d2kkk8cefusmqi.cloudfront.net/fonts/SamsungOneUIKorean-200.svg#SamsungOneUIKorean-200')
              format('svg');
          font-weight: normal;
          font-style: normal;
          font-display: swap;
        }

        @font-face {
          font-family: 'SamsungOneUIKorean 300';
          src: url('https://d2kkk8cefusmqi.cloudfront.net/fonts/SamsungOneUIKorean-300.eot');
          src: url('https://d2kkk8cefusmqi.cloudfront.net/fonts/SamsungOneUIKorean-300.eot?#iefix')
              format('embedded-opentype'),
            url('https://d2kkk8cefusmqi.cloudfront.net/fonts/SamsungOneUIKorean-300.woff2')
              format('font/woff2'),
            url('https://d2kkk8cefusmqi.cloudfront.net/fonts/SamsungOneUIKorean-300.woff')
              format('font/woff'),
            url('https://d2kkk8cefusmqi.cloudfront.net/fonts/SamsungOneUIKorean-300.ttf')
              format('font/truetype'),
            url('https://d2kkk8cefusmqi.cloudfront.net/fonts/SamsungOneUIKorean-300.svg#SamsungOneUIKorean-300')
              format('svg');
          font-weight: 300;
          font-style: normal;
          font-display: swap;
        }

        @font-face {
          font-family: 'SamsungOneUIKorean 400';
          src: url('https://d2kkk8cefusmqi.cloudfront.net/fonts/SamsungOneUIKorean-400.eot');
          src: url('https://d2kkk8cefusmqi.cloudfront.net/fonts/SamsungOneUIKorean-400.eot?#iefix')
              format('embedded-opentype'),
            url('https://d2kkk8cefusmqi.cloudfront.net/fonts/SamsungOneUIKorean-400.woff2')
              format('font/woff2'),
            url('https://d2kkk8cefusmqi.cloudfront.net/fonts/SamsungOneUIKorean-400.woff')
              format('font/woff'),
            url('https://d2kkk8cefusmqi.cloudfront.net/fonts/SamsungOneUIKorean-400.ttf')
              format('font/truetype'),
            url('https://d2kkk8cefusmqi.cloudfront.net/fonts/SamsungOneUIKorean-400.svg#SamsungOneUIKorean-400')
              format('svg');
          font-weight: normal;
          font-style: normal;
          font-display: swap;
        }

        @font-face {
          font-family: 'SamsungOneUIKorean 600';
          src: url('https://d2kkk8cefusmqi.cloudfront.net/fonts/SamsungOneUIKorean-600.eot');
          src: url('https://d2kkk8cefusmqi.cloudfront.net/fonts/SamsungOneUIKorean-600.eot?#iefix')
              format('embedded-opentype'),
            url('https://d2kkk8cefusmqi.cloudfront.net/fonts/SamsungOneUIKorean-600.woff2')
              format('font/woff2'),
            url('https://d2kkk8cefusmqi.cloudfront.net/fonts/SamsungOneUIKorean-600.woff')
              format('font/woff'),
            url('https://d2kkk8cefusmqi.cloudfront.net/fonts/SamsungOneUIKorean-600.ttf')
              format('font/truetype'),
            url('https://d2kkk8cefusmqi.cloudfront.net/fonts/SamsungOneUIKorean-600.svg#SamsungOneUIKorean-600')
              format('svg');
          font-weight: normal;
          font-style: normal;
          font-display: swap;
        }

        body {
          background-color: #16161a;
          margin: 0;
          width: 100vw;
          overflow-x: hidden;
          font-family: 'SamsungOneUIKorean 400', 'Noto Sans KR', 'Open Sans', 'Apple SD Gothic Neo',
            'Nanum Gothic', '나눔고딕', 'Malgun Gothic', '맑은고딕', '돋움', '굴림',
            'Helvetica Neue', Helvetica, Arial, sans-serif;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          //-webkit-overflow-scrolling: touch;
          user-select: none;
        }

        code {
          font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
        }
        /* normalize */
        * {
          box-sizing: border-box;
        }

        *::-webkit-media-controls-start-playback-button {
          display: none !important;
          -webkit-appearance: none;
        }

        *::-webkit-scrollbar {
          background-color: transparent;
          width: 0;
        }

        *:-webkit-autofill,
        *:-webkit-autofill:hover,
        *:-webkit-autofill:focus,
        *:-webkit-autofill:active {
          /* if input has one color, and didn't have bg-image use shadow */
          -webkit-box-shadow: 0 0 0 1000px #fff inset;
        }
        html {
          -ms-overflow-style: none;
          -webkit-text-size-adjust: none;
        }

        body,
        html {
          overscroll-behavior-y: none;
        }

        html,
        body,
        div,
        span,
        applet,
        object,
        iframe,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        blockquote,
        pre,
        a,
        abbr,
        acronym,
        address,
        big,
        cite,
        code,
        del,
        dfn,
        em,
        img,
        ins,
        kbd,
        q,
        s,
        samp,
        small,
        strike,
        strong,
        sub,
        sup,
        tt,
        var,
        b,
        u,
        i,
        center,
        dl,
        dt,
        dd,
        ol,
        ul,
        li,
        fieldset,
        form,
        label,
        legend,
        table,
        caption,
        tbody,
        tfoot,
        thead,
        tr,
        th,
        td,
        article,
        aside,
        canvas,
        details,
        embed,
        figure,
        figcaption,
        footer,
        header,
        hgroup,
        menu,
        nav,
        output,
        ruby,
        section,
        summary,
        time,
        mark,
        audio,
        video,
        input,
        button {
          outline: none;
          text-decoration: none;
          margin: 0;
          padding: 0;
          border: 0;
          font-size: 100%;
        }

        body {
          font-size: 14px;
          margin: 0;
          padding: 0;
        }

        a {
          text-decoration: none;
          color: inherit;
        }

        button {
          border: none;
          font-family: inherit;
          &:focus {
            outline: none;
            border: none;
          }
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }

        input {
          border: none;
          background: none;
          background-color: transparent;
          box-shadow: none;
          &:focus {
            outline: none;
          }
        }
      `}
    />
  )
}

export default GlobalStyle
