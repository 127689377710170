import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import {
  FocusableComponentLayout,
  FocusDetails,
  KeyPressDetails,
  useFocusable,
} from '@noriginmedia/norigin-spatial-navigation'
import { originCurrencyUnitFn, sellingCurrencyUnitFn } from 'libs/utils'
import { convertToTime, dateParser } from 'libs/utils'

interface Props {
  broadcastName?: string
  columnNum: number
  isFocused?: boolean
  programmingStartDt?: string
  representativeProduct?: ProductDetail | null
  shopName?: string
  source?: string
  index?: number
  thumbnail?: string
  focusKey?: string
  onFocus?: (layout: FocusableComponentLayout, props: object, details: FocusDetails) => void
  onEnterPress?: (props: object, details: KeyPressDetails) => void
  type?: 'list' | 'feed'
}

const ScheduleCard: React.FC<Props> = ({
  broadcastName,
  columnNum,
  focusKey,
  onFocus,
  onEnterPress,
  programmingStartDt,
  representativeProduct,
  shopName,
  source,
  thumbnail,
  type = 'list',
}) => {
  const {
    productThumbnailUrlList,
    productName,
    sellingPrice,
    priceTypeCode,
    discountTypeCode,
    discountRate,
    price,
    currencyId,
  } = representativeProduct || {}

  const productImg = productThumbnailUrlList?.filter((x) => x.isRepresentative === true)
  const productImgUrl = productImg && productImg[0].thumbnailUrl + '?w=84&h=84&f=webp&q=60'
  const { ref, focused } = useFocusable({
    focusKey,
    onFocus,
    onEnterPress,
  })

  const priceInfoEl = useMemo(() => {
    if (priceTypeCode === 'text') {
      return <div className="product_price">{price}</div>
    }
    if (discountTypeCode === 'none') {
      return (
        <div className="product_price">
          {originCurrencyUnitFn({ currencyId, price: price?.toString() ?? '' })}
        </div>
      )
    }
    return (
      <div className="product_price_wrapper">
        <div className="product_price">
          {sellingCurrencyUnitFn({ currencyId, sellingPrice: sellingPrice?.toString() ?? '' })}
        </div>
        <div className="product_discount">{discountRate}%</div>
      </div>
    )
  }, [discountRate, discountTypeCode, price, priceTypeCode, sellingPrice, currencyId])

  const productInfoEl = useMemo(() => {
    if (!representativeProduct) return null
    return (
      <div className="product_info_wrapper">
        <img src={`${productImgUrl}?h=200&w=200&q=60&f=webp`} alt="product_img" />
        <div className="product_info">
          <div className="product_name">{productName}</div>
          {priceInfoEl}
        </div>
      </div>
    )
  }, [priceInfoEl, productImgUrl, productName, representativeProduct])

  const scheduleInfoEl = useMemo(() => {
    return (
      <div className="info_wrapper">
        <div className="title">{broadcastName}</div>
        {productInfoEl}
      </div>
    )
  }, [broadcastName, productInfoEl])

  const scheduleCardClass = type === 'feed' ? 'feed' : undefined
  const infoWrapperClass = type === 'feed' ? 'info_feed' : undefined
  const imageWrapperClass = type === 'feed' ? 'image_wrapper image_feed' : 'image_wrapper'

  const scheduleTime = convertToTime(programmingStartDt)

  return (
    <ScheduleCardWrapper
      ref={ref}
      className={focused ? `styledScheduleCard ${scheduleCardClass} focused` : `styledScheduleCard`}
      style={{ width: `calc(100% / ${columnNum} - 20px)` }}
      columnNum={2}
    >
      <div className={imageWrapperClass} style={{ backgroundImage: `url(${thumbnail})` }}>
        {programmingStartDt && type !== 'feed' && (
          <div className="schedule_date">{dateParser(programmingStartDt)}</div>
        )}
        {programmingStartDt && scheduleTime && type === 'feed' && (
          <div className="schedule_time">
            {source !== 'partner' ? (
              scheduleTime
            ) : (
              <span>{`${dateParser(programmingStartDt)}`}</span>
            )}
          </div>
        )}
      </div>
      <div className={infoWrapperClass}>
        {scheduleInfoEl}
        <div className="shop_name">{shopName}</div>
      </div>
    </ScheduleCardWrapper>
  )
}

const ScheduleCardWrapper = styled.div<Props>`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: 100px;
  margin-right: 20px;

  &.feed {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &.focused {
    .image_wrapper {
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        border: 10px solid rgb(255, 255, 255);
        border-radius: 20px;
        width: calc(100% - 10px);
        height: calc(100% - 10px);
        transform: translate(-50%, -50%);
      }
    }
  }

  .info_feed {
    margin-left: 30px;
    width: calc(100% - 370px);
  }

  .schedule_time {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    font-size: 28px;
    font-weight: bold;
    line-height: 52px;
    color: ${(props) => props.theme.white};

    .parseDateInfo {
      text-align: center;

      .parseDate {
        display: block;
        font-size: 32px;
      }

      .parseTime {
        display: block;
      }
    }
  }

  .image_wrapper {
    position: relative;
    border-radius: 5px;
    width: 340px;
    height: 255px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    ::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      border-radius: 5px;
      height: 211px;
      z-index: 1;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
    }

    &.image_feed {
      ::before {
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
      }
    }

    .schedule_date {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      text-align: center;
      transform: translate(-50%, -50%);
      font-size: 19px;
      color: #ffffff;
      font-weight: bold;
      z-index: 2;
    }
  }

  .info_wrapper {
    margin-top: 0px;
    width: 100%;

    .title {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      font-size: 46px;
      line-height: 56px;
      color: #ffffff;
      font-weight: 600;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
      word-wrap: break-word;
      margin-bottom: 40px;
    }
  }

  .product_info_wrapper {
    display: flex;
    align-items: center;
    margin-top: 0px;

    img {
      border-radius: 5px;
      margin-right: 16px;
      width: 80px;
      height: 80px;
    }

    .product_info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: calc(100% - 80px);

      .product_name {
        padding-bottom: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 32px;
        line-height: 32px;
        color: ${(props) => props.theme.brownGrey2};
        font-weight: bold;
      }

      .product_price_wrapper {
        display: flex;
        align-items: center;
      }

      .product_price {
        font-size: 32px;
        line-height: 32px;
        color: #ffffff;
        font-weight: bold;
      }

      .product_discount {
        margin-left: 10px;
        color: #ff2d55;
        font-size: 32px;
        line-height: 32px;
        font-weight: bold;
      }
    }
  }

  .shop_name {
    padding-top: 20px;
    margin-top: 8px;
    font-size: 32px;
    color: ${(props) => props.theme.brownGrey2};
  }
`

export default ScheduleCard
