import axios from 'axios'
import { getUserToken } from 'libs/singleton'
import ENV from '../config'

export const broadcastAPI = axios.create({
  baseURL: ENV.BROADCAST_API_URL,
})
broadcastAPI.interceptors.request.use((config) => {
  config.headers['X-SauceFlex-Authorize'] = getUserToken()
  return config
})

export const cachedBroadcastAPI = axios.create({
  baseURL: ENV.CACHED_BROADCAST_API_URL,
})

export const resourceAPI = axios.create({
  baseURL: ENV.RESOURCE_URL,
})
