import { PLAYER_STATE } from 'libs/@types/enums'

export default class HlsPlayer implements IHlsPlayer {
  private getVideoEl = (): HTMLVideoElement | undefined => {
    return document.getElementById('videoEl') as HTMLVideoElement | undefined
  }

  play = async (): Promise<void> => {
    await this.getVideoEl()?.play()
  }

  pause = (): void => {
    this.getVideoEl()?.pause()
  }

  stop = (): void => {
    const videoEl = this.getVideoEl()
    if (!videoEl) return
    videoEl.pause()
    videoEl.currentTime = 0
  }

  seek = (currentTime: number): void => {
    const videoEl = this.getVideoEl()
    if (!videoEl) return
    videoEl.currentTime = currentTime
  }

  getCurrentTime = (): number => {
    return this.getVideoEl()?.currentTime ?? 0
  }

  getDuration = (): number => {
    return this.getVideoEl()?.duration ?? 0
  }

  setPlaybackRate = (playbackRate: number): void => {
    const videoEl = this.getVideoEl()
    if (!videoEl) return
    videoEl.playbackRate = playbackRate
  }

  getPosition = this.getCurrentTime

  getState = (): PLAYER_STATE => {
    const videoEl = this.getVideoEl()
    if (!videoEl) return PLAYER_STATE.IDLE
    if (videoEl.ended) return PLAYER_STATE.COMPLETE
    if (videoEl.paused) return PLAYER_STATE.PAUSED
    if (videoEl.readyState === 0) return PLAYER_STATE.IDLE
    if (videoEl.readyState === 1) return PLAYER_STATE.BUFFERING
    return PLAYER_STATE.PLAYING
  }

  /** tv에서는 사용 안함 */
  remove = () => {}
  resize = () => {}
  getFloating = () => {}
  getFullscreen = () => {}
  getMute = () => {}
  setMute = () => {}
}
