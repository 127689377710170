import { all } from '@redux-saga/core/effects'
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import reduxSaga from 'redux-saga'
import { call } from 'redux-saga/effects'
import appSaga from './app/app.saga'
import { app } from './app/app.store'
import { interaction } from './interaction/interaction.store'
import kinesisSaga from './kinesis/kinesis.saga'
import { kinesis } from './kinesis/kinesis.store'
import playerSaga from './player/player.saga'
import { player } from './player/player.store'
import sessionSaga from './session/session.saga'
import { session } from './session/session.store'

const rootReducer = combineReducers({
  app,
  player,
  session,
  interaction,
  kinesis,
})

const rootSaga = function* () {
  yield all([appSaga(), playerSaga(), sessionSaga(), kinesisSaga()])
}

const initializeStore = () => {
  const sagaMiddleware = reduxSaga()
  const store = configureStore({
    reducer: rootReducer,
    middleware: [sagaMiddleware],
    devTools: process.env.NODE_ENV === 'development',
  })
  sagaMiddleware.run(rootSaga)
  return store
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
const errorHandler = function* (_e: any) {}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const errorGuard = (handler: any) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function* (action: any) {
    try {
      yield call(handler, action)
    } catch (error) {
      yield call(errorHandler, error)
    }
  }

export type RootState = ReturnType<typeof rootReducer>

export const store = initializeStore()
