const ja = {
  date_format: {
    date: '{{year}}年 {{month}}月 {{day}}日',
    schedule_time: '{{time,schedule_time}}',
  },

  time: {
    am: '午前',
    pm: '午後',
    today: '今日',
    tomorrow: '明日',
    hour: '{{hour}}時',
    hour_minute: '{{hour}}:{{minute}}',
  },

  unit: {
    hundred_million: '億',
    issue_count: '{{count}}枚',
    ten_thousand: '万',
    thousand: '千',
    won: '₩{{price}}',
  },

  add: '追加する',
  add_banned_terms: '追加したい禁止ワードをご入力ください。',
  administrator_commented: '作成したチャットに管理者のコメントがつきました。',
  administrator_froze_chat:
    '管理者によりチャットルームが凍結されました。\n管理者のみチャットに参加できます。',
  administrator_not_issue_coupons: '管理者はクーポンの発行ができません。',
  administrator_unfroze_chat:
    '管理者によりチャットルームが凍結解除されました。\nチャットに参加できます。',
  age: '年齢',
  agree_personal_info: '個人情報の収集・利用に関する同意',
  alarm_notifications: '通知設定',
  all_coupon_issued: 'すべてのクーポンが発行されました。',
  already_added_banned_term: 'すでに登録された禁止ワードです。',
  auto_report_due_to_spam: '同じ内容の繰り返しによる自動報告',
  available_until_period: '{{period}}まで使用可能',
  banned_terms: '禁止ワード',
  banned_terms_added: 'リアルタイム禁止ワードリストに追加されました。',
  banned_terms_deleted: 'リアルタイム禁止ワードリストから削除されました。',
  banned_terms_included: 'チャットに禁止ワードが含まれています。',
  banned_terms_only_applicable_to_live:
    '* 追加された禁止ワードは、当該ライブ配信でのみ適用されます。',
  broadcast_alarm_notified: 'ライブ配信が開始したらお知らせします。',
  broadcast_alarm_success: 'ライブ配信の通知が設定されました。',
  broadcast_disconnected: '只今、ライブ配信の信号が切断されました。しばらくお待ちください。',
  broadcast_ended: 'ライブ配信が終了しました。',
  broadcast_notice: 'ライブ配信のお知らせ',
  broadcast_restored: 'ライブ配信の信号が復旧しました。',
  broadcast_schedule_can_be_changed: '配信時間は当社の配信事情により変更されることがあります。',
  broadcast_started: 'ライブ配信が開始しました。',
  broadcast_unstable: '申し訳ございません。ライブ配信中に問題が発生しました。',
  buttons_at_bottom_right: '※ 右下のボタン > ',
  cancel: 'キャンセル',
  cancel_notifications: 'ライブ配信のプッシュ通知を\nオフにしますか？',
  cancel_to_register_purchase_auth_winner: '当選者情報の登録をキャンセルしますか？',
  canceled_broadcast: 'This broadcast has been cancelled.\nSee you in the next LIVE.',
  cannot_add_sold_out_to_wish_list: '品切れになった商品は、お気に入りに追加できません。',
  cannot_be_replayed: 'リプレイ機能に対応しないライブ配信です。',
  cannot_edit_after_sign_in: 'ログイン後には、上記の情報を修正することができません。',
  cannot_edited_after_sent:
    '転送した返信は、修正や削除ができませんので、一度確認してから転送を行ってください。',
  change_nickname: 'チャット時のニックネームを変更する',
  change_to_portrait: `Sorry, We doesn't support landscape mode.`,
  chat_report: 'Report',
  chat_restrict: 'チャット禁止',
  chat_restrict_included: 'チャット禁止を含む',
  chat_restricted_by_administrator: '管理者によってチャットが禁止されました。',
  chat_restriction: 'チャット禁止',
  chat_warning: '不適切な発言を行う場合、サービスの利用が制限されることがあります。',
  chatroom_frozen: 'チャットルームが凍結されました。',
  check_broadcast_alarm_notifications: 'ライブ配信の通知を受け取りますか？',
  check_code: '入場コードをご確認ください。',
  click_to_play_info_video: 'Click here to play the instructional video.',
  close: '閉じる',
  confirm: '確認',
  congratulations_on_winning: 'ご当選おめでとうございます。',
  connection_unstable: '申し訳ございません。接続中に問題が発生しました。\nしばらくお待ちください。',
  contact_exclude_dashes: '連絡先(ハイフンを除く)',
  converting_to_vod: 'ライブ配信が終了したため、VODに切り替えます。 ',
  copy: 'コピー',
  coupon: 'クーポン',
  coupon_issued: 'クーポンが発行されました。',
  coupon_received: 'クーポンを獲得しました',
  current_nickname: '現在のニックネーム',
  days_ago: '日前',
  delete: '削除する',
  delete_comment: 'コメントを削除する',
  delete_report: '報告リストから削除',
  deleted_comment_no_revert: 'Unable to revert deleted comments.',
  discount: '割引',
  do_cancel: 'キャンセル',
  do_copy: 'コピーする',
  do_delete: '削除する',
  do_pin: 'Pin',
  do_report: '報告する',
  download: 'ダウンロード',
  edit: '編集',
  edit_comment: 'コメントを編集する',
  email: 'メールアドレス',
  enter_at_lease_one: '最低1文字以上ご入力ください。',
  enter_at_least_3_purchase_number: '3桁以上の購入番号を入力',
  enter_banned_terms: '禁止ワードをご入力ください。',
  enter_chat: 'チャットに参加する',
  enter_code: '入場コード{{codeLength}}桁をご入力ください。',
  enter_contact: '連絡先をご入力ください。',
  enter_contact_number_or_email: '連絡先またはメールアドレスの入力（未入力時には非表示）',
  enter_email: 'メールアドレスをご入力ください。',
  enter_max_15_chars: 'Enter up to 15 characters',
  enter_max_40_chars: '最大40文字まで入力',
  enter_name: 'お名前をご入力ください。',
  enter_nickname: 'ニックネームをご入力ください。',
  enter_purchase_authentication_winner: '購入認証当選者の入力',
  enter_purchase_number: '購入(注文)番号をご入力ください。',
  enter_reply: '返信をご入力ください。',
  enter_same_message_consecutively: '同じ内容のチャットを繰り返して転送することはできません。',
  enter_short_time_consecutively: '短時間内にチャットを連続して転送することはできません。',
  enter_too_many_message: 'チャットの回数が制限に達したため、{{sec}}秒間転送できません。',
  enter_without_space: '空白なしにご入力ください。',
  entered_with_nickname: '{{nickname}}様が参加しました。',
  entered_with_nickname_other: '{{nickname}}様以外の{{other}}人が参加しました。',
  event_auto_exit_on_vod: 'VODに切り換えると、イベントは自動で終了します。',
  event_can_start_anytime_on_live: 'LIVE中なら、いつでもイベントを開始することができます。',
  event_management: 'イベント管理',
  event_start: 'イベント開始',
  exit: '終了する',
  expose_with_masking_and_max: '(最大50人まで、一部の情報はマスキング処理(＊)の後、表示されます。)',
  failed_issue_coupon: 'クーポンの発行に失敗しました。',
  failed_to_enter_message: 'チャットの転送に失敗しました。',
  failed_to_enter_message_with_error:
    'チャットの転送に失敗しました。 \nエラー：[ログイン情報が見つかりませんでした。\n再度ログインしてください。]',
  female: '女性',
  fill_out_form_to_chat: 'チャットに参加するために、以下の情報を入力してください。',
  freeze_chat: 'チャットを凍結する',
  full: 'フルバージョンで見る',
  harassment_abuse: '嫌がらせまたはいじめ',
  hide_comments: 'コメントを非表示にする',
  highlight: 'ハイライト映像',
  hours_ago: '時間前',
  info_area: '案内事項の部分',
  info_image: '案内画像',
  info_image_displayed: '登録された案内画像が\n視聴者に表示されています。',
  information_message_to_winner: '当選に関する案内メッセージ',
  intro: '소개',
  invalid_email_format: 'メールアドレスの形式に誤りがあります。',
  invalid_phone_format: '電話番号の形式に誤りがあります。01012345678のような形式にご記入ください。',
  is_where_see_all_comments:
    '<bold>{{location}}</bold>から管理者が作成したすべてのコメントを確認することができます。',
  issue_during_live: 'ライブ配信中のみ発行可能',
  join_broadcast: 'ライブ配信に参加する',
  join_chat_real_time: 'リアルタイムチャットにご参加ください。',
  join_secret: 'シークレットライブに参加する',
  leave_broadcast: 'ライブ配信の視聴を終了しますか？',
  live_comment: 'ライブのコメント',
  live_comments_tab: 'ライブのコメントタブ',
  live_intro: 'ライブの説明',
  live_notice: 'ライブのお知らせ',
  male: '男性',
  mins_ago: '分前',
  more_comments: `コメント{{count}}件を見る`,
  mr: '<bold>{{name}}<bold>',
  must_agree_collect_use_info: '個人情報の収集・利用への同意が必要です。',
  must_select_all_option: '選択事項をすべてお選びください。',
  name: 'お名前',
  new_nickname: '新しいニックネーム',
  nickname: 'ニックネーム',
  no: 'いいえ',
  no_content: '内容がありません。',
  no_coupon: 'ご利用可能なクーポンがありません。\n次回のクーポンをお待ちください！',
  no_intro: '内容がありません。',
  no_notice: '内容がありません。',
  no_products_in_wish_list:
    'お気に入りの商品がありません。\nお気に入りの商品を追加してみてください。',
  no_reply: '内容がありません。',
  no_shortcut: '登録したショートカットワードがありません。',
  none: 'なし',
  note: 'Attention',
  now: '先ほど',
  obscene_message: '性的表現',
  obscenity_abuse: '性的表現またはいじめ',
  only_possible_during_live: 'ライブ配信中のみ可能です。',
  optional: '選択事項',
  other_inappropriate_language: 'その他不適切な発言',
  participate: '参加する',
  participate_success: '購入認証の参加完了 !',
  phone_call_only_mobile: 'Calls are only available on mobile.',
  phone_number: 'Call',
  pin_chat: 'チャットをピン留めする',
  pin_chat_to_top: 'Pin chat to the top?',
  please_enter_nickname: 'ニックネームをご入力ください。',
  please_input_accurately:
    '景品当選者の場合、上記の情報に基づいてご連絡しますので、正確にご記入くださいますよう、お願いいたします。',
  price_differ_after_live: 'ライブ終了後、価格と特典が変わることがあります。',
  products: '販売商品',
  profanity_aggressive_expression: '悪口や誹謗中傷',
  purchase: '購入する',
  purchase_auth_icon_exposed_if_event_on:
    'イベント開始をONにすると、プレイヤーの画面内に購入認証のアイコンが表示されます。',
  purchase_auth_result_icon_exposed_if_announcement_on:
    '当選者発表をONにすると、プレイヤーの画面内に購入認証結果のアイコンが表示されます。',
  purchase_authentication: '購入認証',
  purchase_authentication_event: '購入認証イベント',
  purchase_authentication_event_winner: '購入認証イベントの当選者',
  purchase_authentication_participant_info: '購入認証の参加情報',
  push_notifications: 'プッシュ通知',
  push_notifications_turned_off_in_my_lotte:
    'プッシュ通知がオフになっています。\nマイロッテ＞設定からプッシュ通知をオンにしてください。',
  real_time_chat: 'リアルタイムチャット',
  reason_for_reporting: '報告理由',
  receive_coupon: 'クーポンをもらう',
  receive_notifications_of_broadcast: 'ライブ配信の通知を受け取る',
  receive_notifications: '通知設定',
  receiving_notifications: '通知の受取設定済み',
  recent_shortcut: '最近登録したショートカットワード',
  register: '登録',
  register_broadcast_alarm: 'ライブ配信の通知申請',
  register_notice_too: 'It is also added to the LIVE notice.',
  register_success: '当選者の登録が完了しました。',
  report_count: '報告{{count}}',
  report_list: '報告リスト',
  report_submitted: '報告が完了しました。',
  required: '必須',
  select_reason_for_reporting: '報告理由',
  send: '転送',
  send_report: '違反報告',
  service_restricted_with_false_report:
    '虚偽報告を行った場合、サービス利用が制限されることがあります。',
  set_nickname: 'ニックネームの設定',
  share_broadcast: 'ライブ配信を共有する',
  share_livestream: 'ライブ配信を共有する',
  shortcut: 'ショートカットワード',
  shortcut_can_use_in_liveconsole: 'The shortcut feature is available in the Live console.',
  show_nickname_on_chat: 'ニックネームはチャットの入力時に表示されます。',
  sign_in: 'ログイン',
  sign_in_chat: 'チャットにログイン',
  sign_in_info_timed_out: 'ログインセッションがタイムアウトしました。\n再度ログインしてください。',
  signed_in: '{{nickname}}でログインしました。',
  sold_out: '品切れ',
  spam: 'スパムメッセージ',
  speed: '倍速',
  stop: '転送を中止する',
  subscribe: 'チャンネル登録',
  subscribe_shop: `{{shopName}}'のライブ配信を\nチャンネル登録しますか？`,
  subscriber_with_count: 'チャンネル登録者数{{count}}',
  subscribing: 'チャンネル登録済み',
  touch_turn_on_sound: 'タップして音をオンにしてください。',
  transmit: '転送する',
  turn_on_notifications: 'プッシュ通知をオンにする',
  unable_find_sign_in_path: 'ログイン経路が見つかりませんでした。',
  unfreeze_chat: 'チャットを凍結解除する',
  unpin: 'ピン留めを外す',
  unpin_chat: 'Confirm',
  unpin_chat_to_top: 'Are you sure to unpin the chat?',
  unsubscribe_shop: `{{shopName}}のライブ配信を\nチャンネル登録から解除しますか？`,
  up_to_discount: '最大{{discount}}割引',
  up_to_discount_upon_purchasing: '{{threshold}}以上購入時、最大{{discount}}割引',
  upon_purchasing: '{{threshold}}以上購入時',
  url_copied: 'URLをコピーしました。',
  verify: '認証する',
  view_more: 'もっと見る',
  view_on_app: 'アプリで見る',
  view_on_web: 'Webで見る',
  view_terms: '規約を見る',
  viewable_full_horizontal_video:
    '横向きの映像の場合、横画面表示にすると全画面で視聴することができます。',
  wait_until_live_start:
    'チャットはライブ配信の開始後に参加できます。\nライブ配信が開始するまでしばらくお待ちください。',
  winner_add: '当選者入力',
  winner_announcement: '当選者発表',
  winner_announcement_can_control_after_add:
    '当選者情報の入力後、当選者発表をON／OFFすることができます。',
  winner_info_can_modify_after_vod: 'VODに切り換えた後も当選者情報を入力／修正することができます。',
  winner_information: '当選者情報',
  winner_management: '当選者管理',
  wish_list: 'お気に入り',
  wish_to_delete: '本当に削除しますか？',
  wish_to_receive_notifications: 'ライブ配信のプッシュ通知を\nオンにしますか？',
  wish_to_sign_in: 'ログインしますか？',
  write_comments: 'コメントを書く',
  writing_comments: '{{nickname}}にコメントを作成中',
  yes: 'はい',

  common_brand: {
    ebay: 'Gmarket',
    auction: 'Auction',
    samsung: 'SAMSUNG',
    ldfs: 'Hotel Lotte',
    shinsegaelive: 'SHINSEGAE Live',
    lfmall: 'LF Mall',
    yanolja: 'yanolja',
    momq: 'MOMQ',
    hanatour: 'HanaTour',
    hanssem: 'HANSSEM',
    coway: 'COWAY',
    ellotte: 'Lotte 100',
    lotteon: 'Lotte On',
    livart: 'Livart',
    dongwonmall: 'Dongwon Mall',
    'lg-uplus': 'LG Uplus',
    abcmart: 'ABC Mart',
    posa: 'ePost Mall',
    innisfree: 'Inisfree',
    happyprince: 'Happy Prince',
    pastelmall: 'Pastel Mall',
    thebanchan: 'The Banchan',
  },
  main_page: {
    event: '特別キュレーション',
    recent: '最新のLIVE',
    next_live: '次のライブ',
    top_10_live: 'トップ10ライブ',
    brand: 'ブランド',
    more: 'つづく',
    policy_context:
      'Mobidoo Inc.は通販ブローカーであり、通信販売の当事者ではありません。 そのため、商品、取引、配送、返金などの通信販売については一切責任を負わず、通信販売仲介を依頼した第三者等に責任があります。',
    close_title: 'SauceTVを終了する?',
    close_cancel: 'バック',
    close_confirm: 'SauceTVを終了する',
    info_version: 'バージョン',
    info_device: 'ディバイス',
    info_language: 'げんご',
    info_platform: 'プラットフォ',
    info_region: 'ちいき',
    info_contact: 'れんらくさき',
    info_close: '閉じる',
  },
  schedule_page: {
    today_live: 'ライブ',
    tomorrow_live: '明日のライブ',
    no_regist_live: '内容なし',
  },
  partner_page: {
    contents_tab: '目次',
    schedule_tab: '予定表',
    no_regist_contents: '内容なし',
    no_regist_live: '内容なし',
  },
  player_page: {
    entered_with_nickname: '{{nickname}}様が入場しました。',
    entered_with_nickname_other: '{{nickname}}とその他{{other}}名が参加しました',
    preset_1: 'お世話になっております',
    preset_2: '好きな',
    preset_3: 'スマイル',
    preset_4: '失望しました',
    preset_5: '退屈な',
    preset_6: '拍手',
    preset_7: '驚きですね',
    icon_notice: 'お知らせ',
    icon_chat: 'チャット',
    modal_qr_scan_info: 'QRスキャンミー',
    modal_live_intro_tab: '紹介',
    modal_live_intro_no_cont: '内容なし',
    modal_live_notice_tab: 'お知らせ',
    modal_live_notice_no_cont: '内容なし',
    modal_live_reply_tab: '答文',
    modal_live_reply_no_cont: '内容なし',
    modal_close: '閉じる',
    banner_default_text: '더 많은 방송 보기',

    product_title: '販売商品',
    product_info_text: 'ライブ終了時、価格および特典が変更される場合があります。',
    product_soldout: '品切れ',
    before_chat_text: 'ライブチャットが始まるまでお待ちください。',
    before_notice: 'お知らせ',
  },
}

export default ja
