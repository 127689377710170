import { KinesisApiParams, KinesisApiRequest, KinesisApiResponse } from 'libs/@types/api'
import { getPartitionNumber } from 'libs/utils'
import ENV from '../config'
import kinesisApiClient from './kinesis.client'
import proxyApiClient from './proxy.client'

const KinesisAPI = {
  putRecord: async (params: KinesisApiParams['putRecord']) => {
    const time = 2000
    const max = 5

    let promise: Promise<unknown> = Promise.reject()

    for (let i = 0; i < max; i++) {
      promise = promise
        .catch(() =>
          kinesisApiClient
            .putRecord({
              StreamName: ENV.AWS_KINESIS_STREAM_NAME,
              PartitionKey: ENV.AWS_KINESIS_PARTITION_KEY + '_' + getPartitionNumber(),
              Data: params,
            })
            .promise(),
        )
        .then(() => {
          return
        })
        .catch(
          (err) =>
            new Promise((res, rej) => {
              setTimeout(() => rej(err), time)
            }),
        )
    }
  },
  putFailedRecord: async (params: KinesisApiParams['putFailedRecord']) => {
    kinesisApiClient
      .putRecord({
        StreamName: ENV.AWS_KINESIS_FAILED_STREAM_NAME,
        PartitionKey: ENV.AWS_KINESIS_PARTITION_KEY + '_' + getPartitionNumber(),
        Data: params,
      })
      .promise()
  },
  Enter: async ({ appsync, kinesis }: KinesisApiParams['Enter']) => {
    const body = { appsync, kinesis: btoa(JSON.stringify(kinesis)) }
    const res = await proxyApiClient.post<KinesisApiRequest['Enter'], KinesisApiResponse['Enter']>(
      `/setJoinUser`,
      body,
    )

    const data = res.data

    return data
  },
  GetRoom: async ({
    roomId,
  }: KinesisApiParams['GetRoom']): Promise<KinesisApiResponse['GetRoom']['data']['getRoom']> => {
    return await proxyApiClient
      .get<KinesisApiResponse['GetRoom']>(`/getRoom?broadcastId=${roomId}`)
      .then((result) => {
        if (result.data.data.getRoom) {
          return result.data.data.getRoom
        }
        return null
      })
      .catch(() => {
        return null
      })
  },
}

export default KinesisAPI
