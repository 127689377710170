export enum TYPE_MESSAGE {
  USER = 0,
  ADMIN = 1,

  ANSWER = 15,
  REFETCH = 12,
  INTERVAL = 21,

  INSERT_SHOW = 8,
  INSERT_HIDE = 9,
  NOTICE_SHOW = 13,
  NOTICE_HIDE = 14,

  ENTER = 2,

  PURCHASE_VERIFYING = 32,
}

export enum MEMBER_TYPE {
  NON_MEMBER = '0',
  MEMBER = '1',
  ADMIN = '2',
}

export enum PLAYER_STATE {
  BUFFERING = 'buffering',
  COMPLETE = 'complete',
  IDLE = 'idle',
  PAUSED = 'paused',
  PLAYING = 'playing',
}

export enum RATIO {
  LANDSCAPE = '16:9',
  PORTRAIT = '9:16',
}

export enum SAUCEFLEX_SDK_EVENT_NAME {
  /* 연결 이벤트 */
  INIT = 'init',
  CONNECT = 'connect',
  DISCONNECT = 'disconnect',

  /* 룸 정보 이벤트 */
  GET_ROOM = 'getRoom',
  UPDATE_ROOM = 'updateRoom',
  REFETCH_ROOM = 'refetchRoom',

  /* 메시지 이벤트 */
  GET_MESSAGES = 'getMessages',
  REJECT_MESSAGE = 'rejectMessage',
  SEND_MESSAGE = 'sendMessage',
  MY_MESSAGE = 'myMessage',

  /* 좋아요 이벤트 */
  SEND_LIKE = 'sendLike',
  MY_LIKE = 'myLike',

  /* 구매인증 이벤트 */
  PURCHASE_STATE = 'purchaseState',
  SET_PURCHASE = 'setPurchase',
  GET_PURCHASES = 'getPurchases',
  WIN_PURCHASES = 'winPurchases',

  /* 금칙어, 차단사용자 이벤트 */
  UPDATE_BANNED_WORD = 'updateBannedWord',
  UPDATE_BANNED_USER = 'updateBannedUser',
  INACTIVE_MESSAGE = 'inactiveMessage',

  /* IP 차단 이벤트 */
  SET_BAN_IP = 'setBanIp',
  REMOVE_BAN_IP = 'removeBanIp',
  GET_BAN_IP = 'getBanIp',
  REJECT_IP = 'rejectIp',

  /* 사용자 인증 이벤트 */
  TOKEN_PASS = 'tokenPass',
  INVALID_TOKEN = 'invalidToken',

  /* 채팅 보관함 이벤트 */
  CREATE_ARCHIVED_CHAT = 'createSavedMsg',
  UPDATE_ARCHIVED_CHAT = 'updateSavedMsgs',

  /* 상품 정보 업데이트 이벤트 */
  UPDATE_PRODUCT_LIST_DETAIL = 'updateProductListDetail',
}

export enum MESSAGE_PREFIX {
  CHAT = 'ME00',
  ENTER = 'ME02',
  NOTICE = 'ME13',
  REPLY = 'ME15',
  PURCHASE_VERIFYING = 'ME32',
  ETC = 'MEETC',
}

export enum ROOM_STATE {
  SCHEDULE = -1,
  LIVE = 1,
  LIVE_TO_VOD = 2,
  VOD = 3,
}

export enum PLAYER_MODE {
  LIVE = 'LIVE',
  VOD = 'VOD',
}

export enum BROADCAST_ID_PREFIX {
  LIVE = 'lk',
  CATCHUP_VOD = 'vc',
  VOD = 'vk',
}

export enum BROADCAST_STATE_CODE {
  ACCEPTED = 'accepted',
  CANCEL = 'cancel',
  CONVERTING = 'converting',
  ERROR = 'error',
  ON_AIR = 'onair',
  READY = 'ready',
  STAND_BY = 'standby',
  SWITCHING = 'switching',
  VOD = 'vod',
  WARNING = 'warning',
}

export enum BROADCAST_TYPE_CODE {
  LIVE = 'live',
  CATCHUP_VOD = 'catchup',
  VOD = 'vod',
}

export enum KEYBOARD_CODE {
  ENTER = 13,
  ESC = 27,
  LEFT_ARROW = 37,
  RIGHT_ARROW = 39,
  SPACE = 32,
  TAB = 9,
}

export enum PLAYBACK_RATE {
  x1 = '1.0',
  x125 = '1.25',
  x15 = '1.5',
  x2 = '2.0',
}

export enum CUSTOM_ICON_COLOR {
  NONE = 'none',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  GRAY_900 = 'gray-900',
  GRAY_700 = 'gray-700',
  GRAY_500 = 'gray-500',
  GRAY_300 = 'gray-300',
  GRAY_200 = 'gray-200',
  GRAY_100 = 'gray-100',
  BLACK = 'black',
  WHITE = 'white',
  SERVICE_1 = 'service-1',
  SERVICE_2 = 'service-2',
  DANGER = 'danger',
  GRADIENT = 'gradient',
}
export enum CUSTOM_ICON_NAME {
  ADMIN = 'admin',
  ARROW_BOTTOM = 'arrow-bottom',
  ARROW_DOWN = 'arrow-down',
  ARROW_LEFT = 'arrow-left',
  ATTESTATION = 'attestation',
  CHECK = 'check',
  CHEVRON_DOWN = 'chevron-down',
  CLOSE = 'close',
  COUPON = 'coupon',
  FACEBOOK = 'facebook',
  FULLSCREEN = 'fullscreen',
  FULLSCREEN_EXIT = 'fullscreen-exit',
  HEART = 'heart',
  HEART_EMPTY = 'heart-empty',
  HEART_FILL = 'heart-fill',
  HOURGLASS = 'hourglass',
  KAKAO = 'kakao',
  LIKE_HEART = 'like-heart',
  LIVE = 'live',
  MESSAGE = 'message',
  MESSAGE_SIMPLE = 'message-simple',
  MORE = 'more',
  MORE_WIDE = 'more-wide',
  NEXT = 'next',
  NOTICE = 'notice',
  PEN = 'pen',
  PIN = 'pin',
  PIP = 'pip',
  PLAY = 'play',
  PLUS = 'plus',
  POWERED_BY = 'powered-by',
  PREV = 'prev',
  PURCHASE_AUTH = 'purchase_auth',
  QUESTION = 'question',
  SEND = 'send',
  SEND_FILL = 'send-fill',
  SHARE = 'share',
  SHARE_URL = 'share-url',
  STOP = 'stop',
  TEL = 'tel',
  TEST = 'test',
  TIME = 'time',
  TRASH = 'trash',
  USER = 'user',
  VIDEO = 'video',
  VOD = 'vod',
  VOLUME_MUTE = 'volume-mute',
  VOLUME_OFF = 'volume-off',
  VOLUME_ON = 'volume-on',
}
export enum ICON_SIZE {
  BADGE = 'badge',
  BADGE_LARGE = 'badge-large',
  MINIMUM = 'minimum',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  MAXIMUM = 'maximum',
}

export enum TEXT_INPUT_SIZE {
  THIN = 'thin',
  THICK = 'thick',
}

export enum BUTTON_SIZE {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}
export enum BUTTON_THEME {
  PRIMARY = 'primary',
  PRIMARY_FILL = 'primary-fill',
  SELECT = 'select',
  NO_SELECT = 'no-select',
}

export enum GENDER_VALUE {
  ETC = 'e',
  FEMALE = 'w',
  MALE = 'm',
}

export enum LINK_MOVE_TYPE {
  REDIRECT = 'redirect',
  NEW_WINDOW = 'newWindow',
}

export enum PRODUCT_PRICE_TYPE_CODE {
  BASIC = 'basic',
  NONE = 'hide',
  TEXT = 'text',
}
