import React from 'react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import {
  FocusableComponentLayout,
  FocusDetails,
  KeyPressDetails,
  useFocusable,
} from '@noriginmedia/norigin-spatial-navigation'
import { useIsImageLoaded } from 'libs/hooks/useIsImageLoaded'

interface Props {
  broadcastStateCode: string
  broadcastName: string
  partnerProfileImageUrl?: string
  shopName: string
  thumbnail: string
  partnerId: string
  broadcastId: string
  sectionName: 'event' | 'recent'
  isShowPartnerInfo: boolean
  onEnterPress?: (props: object, details: KeyPressDetails) => void
  onFocus?: (layout: FocusableComponentLayout, props: object, details: FocusDetails) => void
  onArrowPress?: (direction: string) => boolean
  index: number
}

const SliderCard: React.FC<Props> = ({
  broadcastStateCode,
  broadcastName,
  thumbnail,
  shopName,
  partnerProfileImageUrl,
  partnerId,
  broadcastId,
  sectionName,
  isShowPartnerInfo,
  onEnterPress,
  onArrowPress,
  onFocus,
  index,
}) => {
  const { ref, focused } = useFocusable({
    onEnterPress,
    onFocus,
    onArrowPress,
    focusKey: `${sectionName}-${partnerId}-${index}`,
    extraProps: {
      section: `${sectionName}`,
      partnerId,
      broadcastId,
      index,
    },
  })

  const content = broadcastStateCode === 'onair' ? 'LIVE' : 'VOD'
  const tagClass = broadcastStateCode === 'onair' ? 'tag_wrapper live' : 'tag_wrapper'

  const { elementRef, isLoaded } = useIsImageLoaded()

  return (
    <div ref={ref}>
      <StyledSliderCard isFocused={focused} isShow={isLoaded} ref={elementRef} id={`abc-${index}`}>
        {index <= 5 && (
          <div
            className="background_img"
            style={{ backgroundImage: `url(${thumbnail}?q=50&w=280&h=380&f=webp)` }}
          />
        )}
        {index > 5 && isLoaded ? (
          <div
            className="background_img"
            style={{ backgroundImage: `url(${thumbnail}?q=50&w=280&h=380&f=webp)` }}
          />
        ) : (
          <StyledSkeletonCard isFocused={focused} />
        )}
        <div className="card_header_wrapper">
          <div className={tagClass}>{content}</div>
        </div>
        <div className="card_content_wrapper">
          <div className="broadcast_name_wrapper">{broadcastName}</div>
          {isShowPartnerInfo && (
            <div className="shop_info_wrapper">
              <img src={`${partnerProfileImageUrl}`} alt="logo" />
              <div className="shop_name">{shopName}</div>
            </div>
          )}
        </div>
      </StyledSliderCard>
    </div>
  )
}

const StyledSliderCard = styled.div<{ isFocused: boolean; isShow: boolean }>`
  border-radius: 6px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  width: 300px;
  height: 400px;
  margin: 10px;
  border: 10px solid #16161a;

  ${({ isFocused }) =>
    isFocused &&
    css`
      border: 10px solid #fff;
    `}

  ${({ isShow }) =>
    isShow &&
    css`
      display: block;
    `}

  ::after {
    content: '';
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    bottom: 0;
    height: 50%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
  }

  .card_header_wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    padding: 16px 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .tag_wrapper {
      padding: 0 10px;
      width: 95px;
      height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 22px;
      background-color: rgba(0, 0, 0, 0.2);
      color: ${(props) => props.theme.white};
      font-size: 24px;
      text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
      font-weight: bold;

      &.live {
        background-color: ${(props) => props.theme.orange};
      }
    }
  }

  .background_img {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .card_content_wrapper {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 12px;
    padding: 0 15px;
    z-index: 3;

    .broadcast_name_wrapper {
      width: 100%;
      font-size: 23px;
      line-height: 28px;
      color: ${(props) => props.theme.white};
      font-weight: 600;
      word-break: keep-all;
      word-wrap: break-word;
    }

    .shop_info_wrapper {
      width: fit-content;
      display: flex;
      align-items: center;
      padding: 5px 5px;
      border-radius: 20px;
      margin-top: 20px;

      img {
        width: 44px;
        height: 44px;
        border-radius: 50%;
      }

      .shop_name {
        font-size: 23px;
        font-weight: 500;
        color: ${(props) => props.theme.white};
        margin-left: 17px;
      }
    }
  }
`

const StyledSkeletonCard = styled.div<{ isFocused: boolean }>`
  border-radius: 6px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  width: 360px;
  height: 480px;
  margin: 20px;
  border: 10px solid #16161a;
  background-color: #16161a;
`

export default SliderCard
