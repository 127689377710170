import React, { useCallback, useEffect, useRef } from 'react'
import styled from '@emotion/styled'
import KEYCODE from 'libs/constants/keycode'
import i from 'libs/i18n'

interface Props {
  isOpenCloseModal?: boolean
  setIsOpenCloseModal: React.Dispatch<React.SetStateAction<boolean>>
}

const CloseConfirm: React.FC<Props> = ({ isOpenCloseModal, setIsOpenCloseModal }) => {
  const closeConfirmRef = useRef<HTMLButtonElement>(null)
  const closeCancelRef = useRef<HTMLButtonElement>(null)

  // Tizen 앱 종료 함수
  const exitApp = () => {
    // window.postMessage('Tizen App Close', '*')
    try {
      if (window.tizen) {
        window.tizen.application.getCurrentApplication().exit() // 웹뷰에서는 작동불가
        window.postMessage('Tizen App Close1')
      } else {
        console.error('Tizen API is not available.')
      }
    } catch (error) {
      console.error('Error exiting the Tizen app:', error)
    }
  }

  useEffect(() => {
    if (!isOpenCloseModal) return
    closeConfirmRef.current?.focus()
    closeConfirmRef.current?.classList.add('active')
    closeCancelRef.current?.blur()
    closeCancelRef.current?.classList.remove('active')
  }, [isOpenCloseModal])

  const handleKeydownCloseConfirm = useCallback(
    (e: KeyboardEvent) => {
      if (isOpenCloseModal) {
        if (e.keyCode === KEYCODE.LEFT) {
          //
          closeConfirmRef.current?.focus()
          closeConfirmRef.current?.classList.add('active')
          closeCancelRef.current?.blur()
          closeCancelRef.current?.classList.remove('active')
        } else if (e.keyCode === KEYCODE.RIGHT) {
          //
          closeConfirmRef.current?.blur()
          closeConfirmRef.current?.classList.remove('active')
          closeCancelRef.current?.focus()
          closeCancelRef.current?.classList.add('active')
        } else if (e.keyCode === KEYCODE.OK) {
          const curBtnTarget = document.querySelector('.closeBtn.active')
          const curBtnTargetAttr = curBtnTarget?.getAttribute('data-custom-name')
          if (curBtnTargetAttr === 'exit') {
            // 확인 버튼을 누른 경우
            exitApp()
          } else {
            // 취소 버튼을 누른 경우
            setIsOpenCloseModal(false)
          }
        } else if (e.keyCode === KEYCODE.BACK || e.keyCode === KEYCODE.BACK_SPACE) {
          //
          setIsOpenCloseModal(false)
        }
      }
    },
    [isOpenCloseModal, setIsOpenCloseModal],
  )

  useEffect(() => {
    window.addEventListener('keydown', handleKeydownCloseConfirm)
    return () => {
      window.removeEventListener('keydown', handleKeydownCloseConfirm)
    }
  }, [handleKeydownCloseConfirm])

  return (
    <>
      <CloseConfirmBackground className={isOpenCloseModal ? 'active' : ''}></CloseConfirmBackground>
      <CloseConfirmWrapper className={isOpenCloseModal ? 'active' : ''}>
        <div className="closeConfirmCont">
          <h3 className="closeConfirmTit">{i.t('main_page.close_title')}</h3>
          <div className="closeConfirmBtnContainer">
            <button
              className="closeBtn confirmBtn active"
              ref={closeConfirmRef}
              data-custom-name="exit"
            >
              {i.t('main_page.close_confirm')}
            </button>
            <button className="closeBtn cancelBtn" ref={closeCancelRef} data-custom-name="cancel">
              {i.t('main_page.close_cancel')}
            </button>
          </div>
        </div>
      </CloseConfirmWrapper>
    </>
  )
}

const CloseConfirmBackground = styled.div`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
  opacity: 0;
  &.active {
    z-index: 25;
    opacity: 1;
    transition: opacity 0.5s;
  }
`
const CloseConfirmWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 50%;
  height: 50%;
  display: block;
  padding: 20px;
  border-radius: 10px;
  transform: translate(-50%, -50%);
  background-color: #fff;
  z-index: -1;
  opacity: 0;

  &.active {
    z-index: 25;
    opacity: 1;
    transition: opacity 0.5s;
  }

  .closeConfirmCont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    .closeConfirmTit {
      padding-bottom: 100px;
      color: #555;
      font-size: 40px;
      font-weight: 600;
    }

    .closeConfirmBtnContainer {
      display: flex;

      .closeBtn {
        border: 1px solid #0c4da2;
        border-radius: 30px;
        margin: 0 15px;
        width: 400px;
        height: 60px;
        font-size: 34px;
        font-weight: 600;
        color: #0c4da2;
        line-height: 1;
        background-color: #fff;

        &.active {
          background-color: #0c4da2;
          color: #fff;
        }
      }
    }
  }
`

export default CloseConfirm
