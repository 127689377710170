import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface InitialState {
  isOnline: boolean
  isSessionLoading: boolean
  isVisible: boolean
  memberId: Token['memberId'] | null
  nickname: Token['nickName'] | null
}

const initialState: InitialState = {
  isOnline: true,
  isSessionLoading: false,
  isVisible: true,
  memberId: null,
  nickname: null,
}

const sessionStore = createSlice({
  name: 'session',
  initialState,
  reducers: {
    REQ_GENERATE_USER_TOKEN() {},
    REQ_INIT_USER_SESSION() {},
    REQ_RESET_USER_SESSION() {},
    RES_INIT_USER_SESSION(
      state,
      {
        payload,
      }: PayloadAction<
        Token & {
          tokenType?: string
        }
      >,
    ) {
      state.isSessionLoading = true

      state.memberId = payload.memberId
      state.nickname = payload.nickName
    },
    SET_IS_VISIBLE(state, { payload }: PayloadAction<boolean>) {
      state.isVisible = payload
    },
    SET_IS_ONLINE(state, { payload }: PayloadAction<boolean>) {
      state.isOnline = payload
    },
  },
})

export const session = sessionStore.reducer
export const {
  REQ_GENERATE_USER_TOKEN,
  REQ_INIT_USER_SESSION,
  REQ_RESET_USER_SESSION,
  RES_INIT_USER_SESSION,
  SET_IS_VISIBLE,
  SET_IS_ONLINE,
} = sessionStore.actions
