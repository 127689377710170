import React from 'react'
import styled from '@emotion/styled'
import i from 'libs/i18n'
import { dateParser } from 'libs/utils'

interface Props {
  broadcastInfo: Broadcast | null
}

const ImageSpace: React.FC<Props> = ({ broadcastInfo }) => {
  return (
    <ImageSpaceWrapper>
      {broadcastInfo ? (
        <>
          <div
            className="previewImg"
            style={{
              backgroundImage: `url(${broadcastInfo?.scheduleThumbnailList[0]?.thumbnailUrl})`,
            }}
          ></div>
          <div className="previewInfo">
            <p className="previewStartDt">{dateParser(broadcastInfo.programmingStartDt)}</p>
            <h3 className="previewTitle">{broadcastInfo.broadcastName}</h3>
            <div className="previewBrand">
              <img
                className="previewBrandImg"
                src={`${broadcastInfo.shopImageUrl}?h=200&w=200&q=60&f=webp`}
                alt={broadcastInfo.shopName}
              />
              <span className="previewBrandName">
                {i.t(`common_brand.${broadcastInfo.partnerId}`)}
              </span>
            </div>
          </div>
          {broadcastInfo.explanation !== '' ? (
            <div className="previewNotice">
              <div className="previewNoticeWrap">
                <div className="previewNoticeTitle">
                  <h4>{i.t('player_page.before_notice')}</h4>
                </div>
                <div className="previewNoticeCont">
                  <div className="previewNoticeCover">
                    <p className="previewNoticeText">{broadcastInfo.explanation}</p>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </>
      ) : null}
    </ImageSpaceWrapper>
  )
}

const ImageSpaceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 100%;
  .previewImg {
    width: 100%;
    height: 440px;
    background-color: #fff;
    background-size: cover;
    background-position: 50% 50%;
    border-radius: 20px;
  }
  .previewInfo {
    padding: 32px 24px;
    color: #000;
    .previewStartDt {
      font-size: 30px;
      line-height: 44px;
      font-weight: 400;
    }
    .previewTitle {
      font-size: 32px;
      line-height: 46px;
      font-weight: 600;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      word-break: break-word;
      word-wrap: break-word;
    }
    .previewBrand {
      display: inline-flex;
      align-items: center;
      padding: 9px 12px;
      height: 60px;
      border-radius: 30px;
      background-color: rgba(0, 0, 0, 0.05);
      margin-top: 10px;
      .previewBrandImg {
        width: 42px;
        height: 42px;
        border-radius: 50%;
      }
      .previewBrandName {
        display: block;
        color: #555;
        font-size: 28px;
        line-height: 32px;
        font-weight: 400;
        padding: 0 12px;
      }
    }
  }
  .previewNotice {
    height: 100%;
    max-height: 600px;
    min-height: 180px;
    background-color: #fff;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    .previewNoticeWrap {
      .previewNoticeTitle {
        padding: 20px 24px 10px;
        h4 {
          font-size: 28px;
          font-weight: 600;
          color: #000;
        }
      }
      .previewNoticeCont {
        height: calc(100% - 40px);
        padding: 0px 24px;
        overflow: hidden;
        .previewNoticeCover {
          height: auto;
          animation: 30s liveContentMoveVertical 5 3s linear;
          .previewNoticeText {
            font-size: 20px;
            white-space: pre-wrap;
            word-break: break-all;
            line-break: anywhere;
          }
        }
      }
    }
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 20px;
      background-color: #fff;
    }
    @keyframes liveContentMoveVertical {
      0% {
        transform: translateY(0px);
      }
      75% {
        transform: translateY(calc(-100% + 40px));
      }
      100% {
        transform: translateY(calc(-100% + 40px));
      }
    }
  }
`

export default ImageSpace
