import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { css, keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import {
  FocusContext,
  KeyPressDetails,
  useFocusable,
} from '@noriginmedia/norigin-spatial-navigation'
import ENV from 'libs/config'
import i from 'libs/i18n'
import { RootState } from 'libs/store'

interface Props {
  isShowChat: boolean
  onPressEnter: (props: object, details: KeyPressDetails) => void
}

const ChatInfo: React.FC<Props> = ({ isShowChat, onPressEnter }) => {
  const likeCount = useSelector(
    (state: RootState) => state.player.selectedBroadcast?.likeCount ?? 0,
  )

  const { ref, focusKey } = useFocusable({
    onEnterPress: onPressEnter,
    focusKey: 'chat-info',
  })

  /**
   * like reaction 을 위한 임시 로직
   */
  const [showAnimation, setShowAnimation] = useState<boolean>(false)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onPressLike = useCallback(
    (props: object, details: KeyPressDetails) => {
      setShowAnimation(true)
      onPressEnter(props, details)
      setTimeout(() => {
        setShowAnimation(false)
      }, 1000)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [onPressEnter],
  )

  return (
    <ChatInfoWrapper isFocused={false}>
      <FocusContext.Provider value={focusKey}>
        <div className="chatInfoIconBox" ref={ref}>
          <LikeWrapper>
            <MovingHeart isMoving={showAnimation}>❤️</MovingHeart>
            <ChatInfoIcon
              infoText={likeCount.toString()}
              onEnterPress={onPressLike}
              type={'like'}
              src={`${ENV.RESOURCE_URL}/icons/Icon_ favorite_border.svg?h=200&w=200&q=60&f=webp`}
            />
          </LikeWrapper>
          <ChatInfoIcon
            infoText={i.t('player_page.icon_notice')}
            onEnterPress={onPressEnter}
            type={'notice'}
            src={`${ENV.RESOURCE_URL}/icons/Icon_notice.svg?h=200&w=200&q=60&f=webp`}
          />
          {isShowChat && (
            <ChatInfoIcon
              infoText={i.t('player_page.icon_chat')}
              onEnterPress={onPressEnter}
              type={'chat'}
              src={`${ENV.RESOURCE_URL}/icons/Icon_chat.svg?h=200&w=200&q=60&f=webp`}
            />
          )}
        </div>
      </FocusContext.Provider>
    </ChatInfoWrapper>
  )
}

interface ChatInfoIconProps {
  type: 'like' | 'notice' | 'chat'
  src: string
  infoText: string
  onEnterPress: (props: object, details: KeyPressDetails) => void
}

const ChatInfoIcon = ({ type, src, infoText, onEnterPress }: ChatInfoIconProps) => {
  const { ref, focused } = useFocusable({
    onEnterPress,
    focusKey: `chat-info-${type}`,
    extraProps: {
      section: `${type}`,
      url: '',
    },
  })

  return (
    <ChatInfoIconWrapper ref={ref} isFocused={focused}>
      <div className="infoIcon">
        <img className="svgIcon" src={src} alt="" />
        {type === 'like' && <span className="lottie">{}</span>}
      </div>
      <div className="infoTextWrapper">
        <div className="infoText">{infoText}</div>
      </div>
    </ChatInfoIconWrapper>
  )
}

const ChatInfoWrapper = styled.div<{ isFocused: boolean }>`
  margin: 20px 0;

  .chatInfoIconBox {
    display: flex;
  }
`

const ChatInfoIconWrapper = styled.div<{ isFocused: boolean }>`
  margin-right: 30px;

  ${({ isFocused }) =>
    isFocused &&
    css`
      .infoIcon {
        box-shadow: 0 0 12px rgba(0, 0, 0, 0.4);
      }
    `}

  .infoIcon {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 4px solid #b2b2b2;
    width: 90px;
    height: 90px;

    .svgIcon {
      filter: invert(38%);
    }

    span.lottie {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100px;
      height: 100px;
      transform: translate(-50%, -50%);
    }
  }

  .infoTextWrapper {
    height: 55px;
    border: 1px solid white;
    .infoText {
      display: none;
      width: 100%;
      margin-top: 10px;
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      color: #555;
      ${({ isFocused }) =>
        isFocused &&
        css`
          display: block;
        `}
    }
  }
`

const LikeWrapper = styled.div`
  position: relative;
  display: flex;
`

const up = keyframes`
  0%{
    	top: 30%;
        left: 30%;
        opacity: 1;
    }
    50% {
      top: 15%;
        left: 45%;
        opacity: 0.8;
    }
    100%{
    	top: 0px;
      left: 30%;
        opacity: 0;
    }
  `

const MovingHeart = styled.div<{ isMoving: boolean }>`
  position: absolute;
  display: none;
  z-index: 20;
  justify-content: center;
  ${({ isMoving }) =>
    isMoving &&
    css`
      display: block;
      animation: ${up} 1s infinite;
    `}
`

export default ChatInfo
