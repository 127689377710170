/*-------------------------- 싱글톤 함수 ----------------------------
 * ‼️ 직접 전역변수 접근금지 만들어진 함수로 접근할것
 * ‼️ 싱크 오류 발생할수있으니 절대적으로 주의해서 사용할것 동기적 문제 발생할 경우
 *-------------------------- 싱글톤 함수 ----------------------------
 */

/**
 * 플레이어에서 사용하는 유저용 인증 토큰
 * 파트너가 변경될 때마다 값을 변경시켜야 함
 */
let _userToken: string | undefined

export function getUserToken() {
  return _userToken
}

export function setUserToken(userToken?: string) {
  _userToken = userToken
}

/**
 * 쿠키
 */
const _cookies = _initCookie()

function _initCookie() {
  const cookies = document.cookie.split('; ')
  return cookies.reduce((pre, cur) => {
    const [key, value] = cur.split('=')
    pre[key] = value
    return pre
  }, {} as { [key: string]: string | undefined })
}

export function getCookie(key: string): string | undefined
export function getCookie(): { [key: string]: string }
export function getCookie(key?: string) {
  return key ? _cookies[key] : _cookies
}

export function setCookie(key: string, value: string, expire?: Date) {
  document.cookie = `${key}=${value};path=/${expire ? `;expires=${expire}` : ''}`
  _cookies[key] = value
}
export function setCookieMaxAge(key: string, value: string, maxAge: number) {
  document.cookie = `${key}=${value};path=/${maxAge ? `;max-age=${maxAge}` : ''}`
  _cookies[key] = value
}

export function deleteCookie(key: string) {
  const cookie = getCookie(key)
  if (!cookie) return
  setCookie(key, cookie, new Date(1))
  _cookies[key] = undefined
}
