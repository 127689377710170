import React from 'react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import {
  FocusContext,
  KeyPressDetails,
  useFocusable,
} from '@noriginmedia/norigin-spatial-navigation'
import ENV from 'libs/config'
import i from 'libs/i18n'

interface Props {
  isShowChat: boolean
  onPressEnter: (props: object, details: KeyPressDetails) => void
}

const ChatPreset: React.FC<Props> = ({ isShowChat, onPressEnter }) => {
  const { ref, focusKey } = useFocusable({
    extraProps: {
      section: 'chat-preset',
    },
    focusable: isShowChat,
    focusKey: 'chat-preset',
    onEnterPress: onPressEnter,
  })

  return (
    <>
      <FocusContext.Provider value={focusKey}>
        <ChatPresetWrapper ref={ref}>
          {isShowChat && (
            <>
              <PresetIcon
                index={0}
                text={i.t('player_page.preset_1')}
                type={'hello'}
                onEnterPress={onPressEnter}
              />
              <PresetIcon
                index={1}
                src={`${ENV.RESOURCE_URL}/icons/emoji_happy.svg?h=200&w=200&q=60&f=webp`}
                altMessage={'좋아요 이미지'}
                text={i.t('player_page.preset_2')}
                type={'love'}
                onEnterPress={onPressEnter}
              />
              <PresetIcon
                index={2}
                src={`${ENV.RESOURCE_URL}/icons/emoji_smile.svg?h=200&w=200&q=60&f=webp`}
                altMessage={'웃겨요 이미지'}
                text={i.t('player_page.preset_3')}
                type={'happy'}
                onEnterPress={onPressEnter}
              />
              <PresetIcon
                index={3}
                src={`${ENV.RESOURCE_URL}/icons/emoji_crying.svg?h=200&w=200&q=60&f=webp`}
                altMessage={'슬퍼요 이미지'}
                text={i.t('player_page.preset_4')}
                type={'sad'}
                onEnterPress={onPressEnter}
              />
              <PresetIcon
                index={4}
                src={`${ENV.RESOURCE_URL}/icons/emoji_yawning.svg?h=200&w=200&q=60&f=webp`}
                altMessage={'지루해요 이미지'}
                text={i.t('player_page.preset_5')}
                type={'boring'}
                onEnterPress={onPressEnter}
              />
              <PresetIcon
                index={5}
                src={`${ENV.RESOURCE_URL}/icons/emoji_hand.svg?h=200&w=200&q=60&f=webp`}
                altMessage={'박수 이미지'}
                text={i.t('player_page.preset_6')}
                type={'clap'}
                onEnterPress={onPressEnter}
              />
              <PresetIcon
                index={6}
                src={`${ENV.RESOURCE_URL}/icons/emoji_fear.svg?h=200&w=200&q=60&f=webp`}
                altMessage={'놀라움 이미지'}
                text={i.t('player_page.preset_7')}
                type={'horror'}
                onEnterPress={onPressEnter}
              />
            </>
          )}
        </ChatPresetWrapper>
      </FocusContext.Provider>
    </>
  )
}

interface PrestIconProps {
  index: number
  src?: string
  altMessage?: string
  text: string
  type: 'boring' | 'clap' | 'happy' | 'hello' | 'horror' | 'love' | 'sad'
  onEnterPress: (props: object, details: KeyPressDetails) => void
}

const PresetIcon = ({ index, src, altMessage, text, type, onEnterPress }: PrestIconProps) => {
  const { ref, focused } = useFocusable({
    extraProps: {
      section: `preset`,
      type,
    },
    focusKey: `${type}-preset`,
    onEnterPress,
  })
  return (
    <StyledChatPresetIcon
      ref={ref}
      isFocused={focused}
      isEmoji={!!(src && altMessage)}
      data-index={index}
    >
      {src && altMessage ? (
        <img src={src} alt={altMessage} />
      ) : (
        <div className="chatPresetText">
          <span>{text}</span>
        </div>
      )}
    </StyledChatPresetIcon>
  )
}

const ChatPresetWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  width: 100%;
  height: 80px;
`

interface PresetIconProps {
  isFocused: boolean
  isEmoji: boolean
}

const StyledChatPresetIcon = styled.div<PresetIconProps>`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  padding: 5px 5px 5px 0;

  ${({ isEmoji }) =>
    isEmoji &&
    css`
      border-radius: 10px;
      padding: 5px;
    `}

  ${({ isEmoji, isFocused }) =>
    isEmoji &&
    isFocused &&
    css`
      box-shadow: 0 0 12px rgba(0, 0, 0, 0.5);
      img {
        transform: scale(1.3) rotate(15deg);
        transform-origin: center center;
        transition: transform 0.3s;
      }
    `}

  .chatPresetText {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    border: 1px solid #0c4da2;
    border-radius: 20px;
    background-color: #fff;

    span {
      font-size: 18px;
      font-weight: 700;
      line-height: 40px;
      color: #0c4da2;
    }

    ${({ isFocused }) =>
      isFocused &&
      css`
        box-shadow: 0 0 12px rgba(0, 0, 0, 0.5);
        background-color: #0c4da2;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        transition: transform 0.3s;
        span {
          color: white;
        }
      `}
  }
`

export default ChatPreset
