import React from 'react'
import { useSearchParams } from 'react-router-dom'
import PartnerContainer from 'containers/partner/Partner.container'

const PartnerPage: React.FC = () => {
  const [searchParams] = useSearchParams()
  const partnerId = searchParams.get('partnerId')
  if (!partnerId) return null
  return <PartnerContainer partnerId={partnerId} />
}

export default PartnerPage
