import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface InitialState {
  isShowPolicy: boolean
  nowPosition: {
    page: string
    section: string
    partnerId: string
    broadcastId: string
    index: number
  }
  fromPosition: {
    page: string
    section: string
    partnerId: string
    broadcastId: string
    index: number
  }
  nowHeight: number
  nowWidth: number
}

const initialState: InitialState = {
  isShowPolicy: false,
  nowPosition: {
    page: '',
    section: 'recent',
    broadcastId: '',
    partnerId: '',
    index: 0,
  },
  fromPosition: {
    page: '',
    section: '',
    broadcastId: '',
    partnerId: '',
    index: 0,
  },
  nowHeight: 0,
  nowWidth: 0,
}

const interactionStore = createSlice({
  name: 'interaction',
  initialState,
  reducers: {
    SET_IS_SHOW_POLICY(state, { payload }: PayloadAction<InitialState['isShowPolicy']>) {
      state.isShowPolicy = payload
    },
    SET_NOW_POSITION(state, { payload }: PayloadAction<InitialState['nowPosition']>) {
      state.nowPosition = payload
    },
    SET_FROM_POSITION(state, { payload }: PayloadAction<InitialState['fromPosition']>) {
      state.fromPosition = payload
    },
    SET_NOW_HEIGHT(state, { payload }: PayloadAction<InitialState['nowHeight']>) {
      state.nowHeight = payload
    },
    SET_NOW_WIDTH(state, { payload }: PayloadAction<InitialState['nowWidth']>) {
      state.nowWidth = payload
    },
  },
})

export const interaction = interactionStore.reducer
export const {
  SET_IS_SHOW_POLICY,
  SET_NOW_POSITION,
  SET_FROM_POSITION,
  SET_NOW_HEIGHT,
  SET_NOW_WIDTH,
} = interactionStore.actions
