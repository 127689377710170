import React, { useLayoutEffect } from 'react'
import ScheduleContainer from 'containers/schedule/Schedule.container'

const SchedulePage: React.FC = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return <ScheduleContainer />
}

export default SchedulePage
