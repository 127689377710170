type EnvConfig = {
  BROADCAST_API_URL: string
  CACHED_BROADCAST_API_URL: string
  S3_URL: string
  RESOURCE_URL: string
  BASE_CHAT_URL: string
  PROXY_URL: string
  IS_LOCAL: boolean
  USE_VIDEO_LOG: boolean
  VERSION_COMMON?: string
  VERSION_EBAY?: string
  AWS_KINESIS_CREDENTIAL_ACCESSKEY_ID: string
  AWS_KINESIS_CREDENTIAL_SECRET_ACCESSKEY: string
  AWS_KINESIS_STREAM_NAME: string
  AWS_KINESIS_PARTITION_KEY: string
  AWS_KINESIS_FAILED_STREAM_NAME: string
}

const ENV: EnvConfig = {
  BROADCAST_API_URL: process.env.REACT_APP_BROADCAST_API_URL as string,
  CACHED_BROADCAST_API_URL: process.env.REACT_APP_CACHED_BROADCAST_API_URL as string,
  S3_URL: process.env.REACT_APP_S3_URL as string,
  RESOURCE_URL: process.env.REACT_APP_RESOURCE_URL as string,
  BASE_CHAT_URL: process.env.REACT_APP_BASE_CHAT_URL as string,
  PROXY_URL: process.env.REACT_APP_PROXY_API_URL as string,
  IS_LOCAL:
    process.env.REACT_APP_ENV === 'production'
      ? /https:\/\/localhost:\d{4}/.test(window.location.origin)
      : /(https:\/\/localhost:\d{4})|(https:\/\/(\d+\.?){4}:\d{4})/.test(window.location.origin),
  USE_VIDEO_LOG: false,
  VERSION_COMMON: process.env.REACT_APP_VERSION_COMMON as string,
  VERSION_EBAY: process.env.REACT_APP_VERSION_EBAY as string,
  AWS_KINESIS_CREDENTIAL_ACCESSKEY_ID: process.env
    .REACT_APP_AWS_KINESIS_CREDENTIAL_ACCESSKEY_ID as string,
  AWS_KINESIS_CREDENTIAL_SECRET_ACCESSKEY: process.env
    .REACT_APP_AWS_KINESIS_CREDENTIAL_SECRET_ACCESSKEY as string,
  AWS_KINESIS_STREAM_NAME: process.env.REACT_APP_AWS_KINESIS_STREAM_NAME as string,
  AWS_KINESIS_PARTITION_KEY: process.env.REACT_APP_AWS_KINESIS_PARTITION_KEY as string,
  AWS_KINESIS_FAILED_STREAM_NAME: process.env.REACT_APP_AWS_KINESIS_FAILED_STREAM_NAME as string,
}

export default ENV
