import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation'
import Video from 'components/video/Video'
import useBroadcastStatus from 'libs/hooks/useBroadcastStatus'
import { RootState } from 'libs/store'
import ImageSpace from './ImageSpace'

const PlayerLayout: React.FC = () => {
  const broadcastInfo = useSelector((state: RootState) => state.player.selectedBroadcast)
  const { isBeforeBroadcast } = useBroadcastStatus()

  const [vodTotalDuration, setVodTotalDuration] = useState<string>('')
  const [isLockPlayer, setIsLockPlayer] = useState<boolean>(false) // playerLayout 내부 UI로 접근시 좌우 방향 이동 불가(PlayerLayout 포커스시 확인 버튼을 누르면 하단에 컨트롤바가 나오면서 일시 정지되고 좌우 버튼을 통해 -10초 | +10초 영상의 위치를 변경할 수 있다.)

  const { ref, focused, focusKey } = useFocusable({
    focusKey: 'player',
  })

  useEffect(() => {
    if (!broadcastInfo?.broadcastStartDt || !broadcastInfo.broadcastEndDt) return
    const vodStartDate = new Date(broadcastInfo.broadcastStartDt ?? '').getTime()
    const vodEndDate = new Date(broadcastInfo.broadcastEndDt ?? '').getTime()
    const vodTotalTime = String((vodEndDate - vodStartDate) / 1000)
    setVodTotalDuration(vodTotalTime)
  }, [broadcastInfo?.broadcastStartDt, broadcastInfo?.broadcastEndDt, vodTotalDuration])

  return (
    <FocusContext.Provider value={focusKey}>
      <PlayerLayoutWrapper ref={ref}>
        <PlayerGapWrapper isFocused={focused}>
          {isBeforeBroadcast ? (
            <ImageSpace broadcastInfo={broadcastInfo} />
          ) : (
            <Video
              isFocused={focused}
              isLockPlayer={isLockPlayer}
              setIsLockPlayer={setIsLockPlayer}
              broadcastInfoData={broadcastInfo}
            />
          )}
        </PlayerGapWrapper>
      </PlayerLayoutWrapper>
    </FocusContext.Provider>
  )
}

const PlayerLayoutWrapper = styled.div`
  display: flex;
  width: calc(100vw / 3);
  height: 100%;
`
const PlayerGapWrapper = styled.div<{ isFocused: boolean }>`
  display: flex;
  width: calc(100vw / 3);
  height: calc(100% - 40px);
  margin: 20px 0;
  ${({ isFocused }) =>
    isFocused &&
    css`
      transition: box-shadow 0.5s;
      box-shadow: 0 0 12px rgba(0, 0, 0, 0.5);
    `}
`

export default PlayerLayout
