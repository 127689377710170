import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Loading from 'components/loading/Loading'
import Layout from 'wrappers/Layout'
import KEYCODE from 'libs/constants/keycode'
import { RootState } from 'libs/store'
import {
  REQ_GET_BROADCAST_LIST_OF_PARTNER,
  REQ_GET_PARTNER_LIST,
  REQ_GET_SCHEDULE_LIST_OF_PARTNER,
  RESET_SELECTED_PARTNER_BROADCAST_LIST,
  RESET_SELECTED_PARTNER_SCHEDULE_LIST,
} from 'libs/store/app/app.store'
import { SET_NOW_POSITION } from 'libs/store/interaction/interaction.store'
import PartnerContentsContainer from './PartnerContents.container'
import PartnerHeaderContainer from './PartnerHeader.container'

interface Props {
  partnerId: string
}

const PartnerContainer: React.FC<Props> = ({ partnerId }) => {
  const isLoading = useSelector(
    (state: RootState) => state.app.isPartnerBroadcastLoading || state.app.isPartnerScheduleLoading,
  )
  const partnerList = useSelector((state: RootState) => state.app.partnerList)
  const renderPartnerId = useSelector((state: RootState) => state.app.renderPartnerId)
  const fromPositionInfo = useSelector((state: RootState) => state.interaction.fromPosition)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  /**
   * 선택된 파트너의 방송 가져오기 useEffect
   */
  useEffect(() => {
    if (!partnerId) return

    if (!partnerList) {
      dispatch(REQ_GET_PARTNER_LIST())
    }
    dispatch(REQ_GET_BROADCAST_LIST_OF_PARTNER({ partnerId }))
    dispatch(REQ_GET_SCHEDULE_LIST_OF_PARTNER({ partnerId }))
  }, [partnerId, dispatch, partnerList])

  /**
   * 키다운 이벤트 매핑 useEffect
   */
  useEffect(() => {
    const handleKeyDownPartnerContent = (e: KeyboardEvent) => {
      e.preventDefault()
      e.stopPropagation()

      let lastPositionInfo
      const { section } = fromPositionInfo
      if (section !== '') {
        lastPositionInfo = fromPositionInfo
      } else {
        lastPositionInfo = {
          page: 'home',
          section: 'brand',
          broadcastId: '',
          partnerId,
          index: partnerList?.findIndex((partner) => partner.partnerId === partnerId) || 0,
        }
      }
      switch (e.keyCode) {
        case KEYCODE.BACK:
        case KEYCODE.BACK_SPACE:
          dispatch(SET_NOW_POSITION(lastPositionInfo))

          dispatch(RESET_SELECTED_PARTNER_BROADCAST_LIST())
          dispatch(RESET_SELECTED_PARTNER_SCHEDULE_LIST())
          navigate({
            pathname: '/',
            search: renderPartnerId ? `?renderPartnerId=${renderPartnerId}` : '',
          })
          break
      }
    }

    window.addEventListener('keydown', handleKeyDownPartnerContent)
    return () => {
      window.removeEventListener('keydown', handleKeyDownPartnerContent)
    }
  }, [dispatch, fromPositionInfo, navigate, partnerId, partnerList, renderPartnerId])

  if (isLoading) return <Loading />
  return (
    <Layout>
      <PartnerHeaderContainer />
      <PartnerContentsContainer />
    </Layout>
  )
}

export default PartnerContainer
