import React from 'react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { KeyPressDetails, useFocusable } from '@noriginmedia/norigin-spatial-navigation'

interface ItemProps {
  onEnterPress: (props: object, details: KeyPressDetails) => void
}

const CustomerCenter: React.FC<ItemProps> = ({ onEnterPress }) => {
  const { ref, focused } = useFocusable({
    onEnterPress,
    focusKey: 'setting-customer',
    extraProps: {
      section: 'setting-customer',
      partnerId: '',
      broadcastId: '',
    },
  })

  return (
    <SelectCustomWrap ref={ref} isFocused={focused}>
      <div className="customCenterIcon" />
    </SelectCustomWrap>
  )
}

export default CustomerCenter

const SelectCustomWrap = styled.div<{ isFocused: boolean }>`
  display: block;
  width: 55px;
  height: 55px;
  margin-left: 20px;
  border: 7px solid transparent;
  box-sizing: border-box;

  .customCenterIcon {
    display: block;
    width: 100%;
    height: 100%;
    background-image: url('https://d2kkk8cefusmqi.cloudfront.net/icons/icon_info.svg');
    background-size: cover;
    position: relative;
  }

  ${({ isFocused }) =>
    isFocused &&
    css`
      .customCenterIcon {
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: 100%;
          border: 7px solid rgb(255, 255, 255);
          border-radius: 50%;
        }
      }
    `}
`
