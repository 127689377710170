import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import {
  FocusContext,
  KeyPressDetails,
  useFocusable,
} from '@noriginmedia/norigin-spatial-navigation'
import CustomerCenter from 'components/setting/CustomerCenter'
import LanguageSelector from 'components/setting/LanguageSelector'
import getIpAddress from 'libs/api/ip'
import i from 'libs/i18n'
import { RootState } from 'libs/store'

const languages = [
  { originIpValue: 'KR', langValue: 'ko', langText: '한국어' },
  { originIpValue: 'US', langValue: 'en', langText: 'English' },
  { originIpValue: 'JP', langValue: 'ja', langText: '日本語' },
  { originIpValue: 'VN', langValue: 'vi', langText: 'Tiếng Việt' },
  { originIpValue: 'ID', langValue: 'id', langText: 'Indonésia' },
]

interface SettingContainerProps {
  isLanguageSelectorOpen: boolean
  setIsLanguageSelectorOpen: React.Dispatch<React.SetStateAction<boolean>>
  onPressEnter: (props: object, details: KeyPressDetails) => void
}

const SettingContainer: React.FC<SettingContainerProps> = (props) => {
  const { isLanguageSelectorOpen, setIsLanguageSelectorOpen, onPressEnter } = props

  const isShowPolicy = useSelector((state: RootState) => state.interaction.isShowPolicy)

  const { ref, focusKey } = useFocusable({
    focusKey: 'setting',
  })

  const [selectedLanguage, setSelectedLanguage] = useState<{
    originIpValue: string
    langValue: string
    langText: string
  }>({ originIpValue: '', langValue: '', langText: 'Language' }) // 선택된 언어 상태

  // 최초 진입시 언어가 설정되어있지 않다면 api를 통해 설정한다.
  const [ipInfoState, setIpInfoState] = useState({
    ip: '',
    country: '',
  })

  useEffect(() => {
    getIpAddress().then((response) => {
      setIpInfoState(response.data)
    })

    window.localStorage.setItem('regionValue', ipInfoState.country)
    // eslint-disable-next-line array-callback-return
    let initCountryData: {
      originIpValue: string
      langValue: string
      langText: string
    } = languages.find((v) => v.originIpValue === ipInfoState.country)!

    const localStorageLanguage: string | null = window.localStorage.getItem('userSelectedLanguage')

    if (initCountryData?.langText) {
      if (!localStorageLanguage) {
        setSelectedLanguage({
          originIpValue: '',
          langValue: '',
          langText: initCountryData.langText,
        })
      } else {
        initCountryData = languages.find((v) => v.langValue === localStorageLanguage)!
        setSelectedLanguage({
          originIpValue: '',
          langValue: '',
          langText: initCountryData.langText,
        })
        i.changeLanguage(initCountryData.langValue)
      }
    } else {
      setSelectedLanguage({ originIpValue: 'US', langValue: 'en', langText: 'English' })
    }
  }, [ipInfoState.country])

  return (
    <FocusContext.Provider value={focusKey}>
      <SettingContainerWrap ref={ref} needPaddingTop={isShowPolicy}>
        <LanguageSelector
          languages={languages}
          nowLanguage={selectedLanguage!.langText}
          isSelectorOpen={isLanguageSelectorOpen}
          onEnterPress={onPressEnter}
          setLanguage={setSelectedLanguage}
          setIsSelectorOpen={setIsLanguageSelectorOpen}
        />
        <CustomerCenter onEnterPress={onPressEnter} />
      </SettingContainerWrap>
    </FocusContext.Provider>
  )
}
const SettingContainerWrap = styled.div<{ needPaddingTop: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  color: #fff;
  top: 24px;
  right: 44px;
  z-index: 3;
  ${({ needPaddingTop }) =>
    needPaddingTop &&
    css`
      top: 110px;
    `}
`
export default SettingContainer
