import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'

abstract class HttpClient {
  protected readonly instance: AxiosInstance

  public constructor(baseURL: string) {
    this.instance = axios.create({ baseURL })

    this.initializeResponseInterceptor()
  }

  private initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(this.handleResponse, this.handleError)
  }

  private handleResponse = (response: AxiosResponse) => {
    return response
  }

  protected handleError = (error: unknown) => {
    return Promise.reject(error)
  }

  /**
   * HTTP GET method
   *
   * @access public
   * @template TRequest - request body 객체 타입
   * @template TResponse - axios response에 포함할 response data 객체 타입
   * @param {string} path - 엔드 포인트 경로
   * @param {import("axios").AxiosRequestConfig} [config] - axios request config
   * @returns {Promise<AxiosResponse<TResponse>>} HTTP `axios` response payload
   * @memberof Api
   */
  public get = <TResponse, TRequest = unknown>(
    path: string,
    config?: AxiosRequestConfig<TRequest>,
  ): Promise<AxiosResponse<TResponse, TRequest>> => {
    return this.instance.get<TResponse, AxiosResponse<TResponse, TRequest>, TRequest>(path, config)
  }

  /**
   * HTTP POST method
   *
   * @access public
   * @template TRequest - request body 객체 타입
   * @template TResponse - axios response에 포함할 response data 객체 타입
   * @param {string} path - 엔드 포인트 경로
   * @param {TRequest} data - request body 객체
   * @param {import("axios").AxiosRequestConfig} [config] - axios request config
   * @returns {Promise<AxiosResponse<TResponse, TRequest>>} - HTTP `axios` response payload
   * @memberof Api
   */
  public post<TRequest, TResponse = unknown>(
    path: string,
    data?: TRequest,
    config?: AxiosRequestConfig<TRequest>,
  ): Promise<AxiosResponse<TResponse, TRequest>> {
    return this.instance.post<TResponse, AxiosResponse<TResponse, TRequest>, TRequest>(
      path,
      data,
      config,
    )
  }

  /**
   * HTTP PUT method
   *
   * @access public
   * @template TRequest - request body 객체 타입
   * @template TResponse - axios response에 포함할 response data 객체 타입
   * @param {string} path - 엔드 포인트 경로
   * @param {TRequest} data - request body 객체
   * @param {import("axios").AxiosRequestConfig} [config] - axios request config
   * @returns {Promise<AxiosResponse<TResponse>>} - HTTP `axios` response payload
   * @memberof Api
   */
  public put<TRequest, TResponse = unknown>(
    path: string,
    data?: TRequest,
    config?: AxiosRequestConfig<TRequest>,
  ): Promise<AxiosResponse<TResponse, TRequest>> {
    return this.instance.put<TResponse, AxiosResponse<TResponse, TRequest>, TRequest>(
      path,
      data,
      config,
    )
  }

  /**
   * HTTP PATCH method
   *
   * @access public
   * @template TRequest - request body 객체 타입
   * @template TResponse - axios response에 포함할 response data 객체 타입
   * @param {string} path - 엔드 포인트 경로
   * @param {TRequest} data - request body 객체
   * @param {import("axios").AxiosRequestConfig} [config] - axios request config
   * @returns {Promise<AxiosResponse<TResponse>>} - HTTP `axios` response payload
   * @memberof Api
   */
  public patch<TRequest, TResponse = unknown>(
    path: string,
    data?: TRequest,
    config?: AxiosRequestConfig<TRequest>,
  ): Promise<AxiosResponse<TResponse, TRequest>> {
    return this.instance.patch<TResponse, AxiosResponse<TResponse, TRequest>, TRequest>(
      path,
      data,
      config,
    )
  }

  /**
   * HTTP DELETE method
   *
   * @access public
   * @template TRequest - request body 객체 타입
   * @template TResponse - axios response에 포함할 response data 객체 타입
   * @param {string} path - 엔드 포인트 경로
   * @param {import("axios").AxiosRequestConfig} [config] - axios request config
   * @returns {Promise<AxiosResponse<TResponse>>} - HTTP [axios] response payload
   * @memberof Api
   */
  public delete<TRequest, TResponse = unknown>(
    path: string,
    config?: AxiosRequestConfig<TRequest>,
  ): Promise<AxiosResponse<TResponse, TRequest>> {
    return this.instance.delete<TResponse, AxiosResponse<TResponse, TRequest>, TRequest>(
      path,
      config,
    )
  }
}

export default HttpClient
