import { PayloadAction } from '@reduxjs/toolkit'
import { call, put, takeEvery } from 'redux-saga/effects'
import { ApiBroadcastInternalResponse } from 'libs/@types/api'
import { convertInternalResponseToBroadcast } from 'libs/utils'
import API from '../../api'
import { REQ_GET_SELECTED_BROADCAST, RES_GET_SELECTED_BROADCAST } from './player.store'

function* getBroadcast({ payload }: PayloadAction<{ broadcastId: string }>) {
  const res: { items: ApiBroadcastInternalResponse[] } = yield call(API.getBroadcast, payload)

  yield put(RES_GET_SELECTED_BROADCAST(convertInternalResponseToBroadcast(res.items[0])))
}

export default function* playerSaga() {
  yield takeEvery(REQ_GET_SELECTED_BROADCAST, getBroadcast)
}
