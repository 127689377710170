import { call, delay, put, select, takeEvery } from '@redux-saga/core/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { BROADCAST_ID_PREFIX, BROADCAST_STATE_CODE, BROADCAST_TYPE_CODE } from 'libs/@types/enums'
import KinesisAPI from 'libs/api/kinesis'
import { getKinesis, updatePlayStart } from 'libs/kinesis/kinesis'
import { UPDATE_ROOM } from 'libs/store/player/player.store'
import { getTimeDiff } from 'libs/utils'
import { errorGuard, RootState } from '..'
import {
  REQ_KINESIS_CHAT,
  REQ_KINESIS_CLICK_BANNER,
  REQ_KINESIS_CLICK_QR_POPUP,
  REQ_KINESIS_END,
  REQ_KINESIS_ENTER,
  REQ_KINESIS_GET_ROOM,
  REQ_KINESIS_REACTION,
  REQ_KINESIS_REFETCH,
  REQ_KINESIS_RESTART,
  UPDATE_KINESIS_DATA,
  UPDATE_KINESIS_LAST_SENT_TIME,
  UPDATE_KINESIS_LOOP_COUNT,
  UPDATE_KINESIS_LOOP_STATUS,
} from './kinesis.store'

const playTimeSec = 30

export function* getRecord() {
  const selectedBroadcast = yield select((state: RootState) => state.player.selectedBroadcast)
  const { player } = yield select((state: RootState) => state.player)

  /* lk & vod인 경우 kinesis도 캐치업VOD 데이터로 변환해서 전송해야됨 */
  let convertedBroadcastId = selectedBroadcast.broadcastId
  let convertedBroadcastTypeCode = selectedBroadcast.broadcastType
  if (
    selectedBroadcast.broadcastStateCode === BROADCAST_STATE_CODE.VOD &&
    /^lk/.test(selectedBroadcast.broadcastId)
  ) {
    convertedBroadcastId = convertedBroadcastId.replace(
      BROADCAST_ID_PREFIX.LIVE,
      BROADCAST_ID_PREFIX.CATCHUP_VOD,
    )
    convertedBroadcastTypeCode = BROADCAST_TYPE_CODE.CATCHUP_VOD
  }
  const defaultData = getKinesis()
  const record: IKinesisRecord = {
    ...defaultData,
    dt: new Date().toISOString(),
    broadcastCategoryId1: selectedBroadcast.majorCategory,
    broadcastCategoryId2: selectedBroadcast.minorCategory,
    broadcastStateCode: selectedBroadcast.broadcastStateCode,
    broadcastTypeCode: convertedBroadcastTypeCode,
    broadcastId: convertedBroadcastId,
    partnerId: selectedBroadcast.partnerId,
    roomId: selectedBroadcast.roomId,
    seekTime: selectedBroadcast.isSchedule
      ? -1
      : player?.getCurrentTime()
      ? Math.floor(player.getCurrentTime())
      : 0,
    playingState: player?.getState() ?? 'idle',
  }

  return record
}

function* kinesisEnter({ payload }: PayloadAction<AppSync>) {
  const record: Kinesis = yield getRecord()
  record.viewCount = 1
  record.playTimeV2 = playTimeSec
  record.timeSinceStart = getTimeDiff(record.playStart)

  const appSyncData = {
    isAdmin: false,
    roomId: payload.roomId,
    input: {
      isLive: record.broadcastStateCode === 'onair',
      message: '',
      roomId: payload.roomId,
      messageType: 2,
      partnerId: record.partnerId,
      userId: record.memberId,
      userName: record.memberId,
      // userNick: record.nickname,
    },
  }

  let res: KinesisEnterResponse

  try {
    res = yield call(KinesisAPI.Enter, {
      appsync: appSyncData,
      kinesis: record,
    })
  } catch (e) {
    if (!axios.isAxiosError(e)) throw e

    record.errorLog = e.response?.data ?? e.toString()
    record.statusCode = e.response?.status
    record.headers = e.response?.headers.toString()
    const _record = JSON.stringify(record)
    KinesisAPI.putFailedRecord(_record)
    throw e
  }

  res?.data?.currentDt && updatePlayStart(res.data.currentDt)

  yield put(UPDATE_KINESIS_DATA(record))
  yield put(UPDATE_KINESIS_LAST_SENT_TIME(''))
  yield put(UPDATE_KINESIS_LOOP_COUNT(0))
  yield kinesisLoop()
}

function* kinesisLoop() {
  let loopCount = 0
  let isPlayTimeRecordEnd = false

  while (true) {
    yield delay(playTimeSec * 1000)
    loopCount++

    const record: IKinesisRecord = yield getRecord()

    if (validateSkipPlayTimeRecord(record.playingState)) {
      yield put(UPDATE_KINESIS_LOOP_STATUS(false))
      break
    }

    recordPlayTime(record)
    yield put(UPDATE_KINESIS_LAST_SENT_TIME(record.dt))
  }

  function validateSkipPlayTimeRecord(playingState: string | undefined) {
    /* 첫 complete 플레이 상태에서는 플레이타임을 기록해야 하기 때문에 true값 대입은 실행하지 않음 */
    if (playingState !== 'complete') {
      isPlayTimeRecordEnd = false
    }

    return (
      !playingState || playingState === 'paused' || playingState === 'error' || isPlayTimeRecordEnd
    )
  }

  function recordPlayTime(record: IKinesisRecord) {
    record.playTime = playTimeSec
    record.playTimeV2 = loopCount === 1 ? 0 : playTimeSec
    record.timeSinceStart = getTimeDiff(record.playStart)

    const _record = JSON.stringify(record)
    KinesisAPI.putRecord(_record)

    isPlayTimeRecordEnd = record.playingState === 'complete' ? true : isPlayTimeRecordEnd
  }
}

/**
 * 플레이어를 이탈하거나, stop, pause, error 등의 상황일 때 마지막 상태 & playTimeV2 보내는 로직.
 */
function* kinesisEnd() {
  const { lastKinesisSentTime } = yield select((state: RootState) => state.kinesis)
  const record: IKinesisRecord = yield getRecord()
  record.timeSinceStart = getTimeDiff(record.playStart)
  if (lastKinesisSentTime) {
    record.playTimeV2 = getTimeDiff(lastKinesisSentTime)
  } else {
    record.playTimeV2 = 0
  }

  const _record = JSON.stringify(record)
  KinesisAPI.putRecord(_record)
}

/**
 * 플레이어가 pause -> play 일 때 playTimeV2 보내고 loop 재시작
 */
function* kinesisRestart() {
  const record: IKinesisRecord = yield getRecord()
  record.timeSinceStart = getTimeDiff(record.playStart)
  record.playTimeV2 = 0
  const _record = JSON.stringify(record)
  KinesisAPI.putRecord(_record)
  yield kinesisLoop()
}

function* kinesisReaction({ payload }: PayloadAction<number>) {
  const record: IKinesisRecord = yield getRecord()
  record.likeCount = payload
  record.timeSinceStart = getTimeDiff(record.dt)
  const _record = JSON.stringify(record)
  KinesisAPI.putRecord(_record)
}

function* kinesisChat({ payload }: PayloadAction<ChatCountPayload>) {
  const record: IKinesisRecord = yield getRecord()
  record.chatCount = payload.chatCount
  record.chatMessage = payload.chatMessage
  record.chatMessageId = payload.chatMessageId
  record.chatAnalysisState = false // 무조껀 false 로 입력해야함.
  record.timeSinceStart = getTimeDiff(record.dt)
  const _record = JSON.stringify(record)
  KinesisAPI.putRecord(_record)
}

function* kinesisRefetch() {
  const record: IKinesisRecord = yield getRecord()
  record.timeSinceStart = getTimeDiff(record.dt)
  const _record = JSON.stringify(record)
  KinesisAPI.putRecord(_record)
}

function* kinesisGetRoom({ payload }: PayloadAction<KinesisGetRoom>) {
  const { roomId } = payload
  const roomData: Room = yield call(KinesisAPI.GetRoom, {
    roomId: roomId,
  })
  if (roomData) {
    yield put(UPDATE_ROOM({ roomData }))
  }
}

function* kinesisClickQrPopup({ payload }: PayloadAction<{ qrType: 'chatButton' }>) {
  const record: IKinesisRecord = yield getRecord()
  record.qrPopupButtonType = payload.qrType
  const _record = JSON.stringify(record)
  KinesisAPI.putRecord(_record)
}

function* kinesisClickBanner({
  payload,
}: PayloadAction<{ bannerId: string; clickTime: undefined }>) {
  const record: IKinesisRecord = yield getRecord()

  record.bannerId = payload.bannerId
  record.clickTime = payload.clickTime

  const _record = JSON.stringify(record)
  KinesisAPI.putRecord(_record)
}

export default function* kinesisSaga() {
  yield takeEvery(REQ_KINESIS_ENTER, errorGuard(kinesisEnter))
  yield takeEvery(REQ_KINESIS_REACTION, errorGuard(kinesisReaction))
  yield takeEvery(REQ_KINESIS_CHAT, errorGuard(kinesisChat))
  yield takeEvery(REQ_KINESIS_REFETCH, errorGuard(kinesisRefetch))
  yield takeEvery(REQ_KINESIS_GET_ROOM, errorGuard(kinesisGetRoom))
  yield takeEvery(REQ_KINESIS_CLICK_QR_POPUP, errorGuard(kinesisClickQrPopup))
  yield takeEvery(REQ_KINESIS_CLICK_BANNER, errorGuard(kinesisClickBanner))
  yield takeEvery(REQ_KINESIS_END, errorGuard(kinesisEnd))
  yield takeEvery(REQ_KINESIS_RESTART, errorGuard(kinesisRestart))
}
