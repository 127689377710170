import { RefObject, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Hls, { type ErrorData, type Events, HlsConfig, type LevelUpdatedData } from 'hls.js'
// import { SET_DURATION } from 'modules/redux/player/player.store'
import { RootState } from 'libs/store'

const RETRY_DELAY_SEC = 1

const useHls = (videoRef: RefObject<HTMLVideoElement>, isLowResolution?: boolean) => {
  const videoUrl = useSelector((state: RootState) => state.player.selectedBroadcast?.videoUrl)
  const [isHlsLoaded, setIsHlsLoaded] = useState<boolean>(false)

  const [hls, setHls] = useState<Hls | null>(null)

  const dispatch = useDispatch()

  /**
   * Hls 객체 초기화 useEffect
   */
  useEffect(() => {
    if (hls) return

    /**
     * Hls 컨피그
     * https://github.com/video-dev/hls.js/blob/master/docs/API.md
     */
    const hlsConfig = {
      maxBufferLength: 60,
      nudgeMaxRetry: 10,
      fragLoadPolicy: {
        default: {
          maxTimeToFirstByteMs: 9000,
          maxLoadTimeMs: 100000,
          timeoutRetry: {
            maxNumRetry: 10,
            retryDelayMs: 0,
            maxRetryDelayMs: 0,
          },
          errorRetry: {
            maxNumRetry: 10,
            retryDelayMs: 3000,
            maxRetryDelayMs: 15000,
            backoff: 'linear',
          },
        },
      },
      startLevel: 1,
      liveSyncDurationCount: 8,
      liveMaxLatencyDurationCount: Infinity,
    } as HlsConfig

    const newHls = new Hls(hlsConfig)

    setHls(newHls)
  }, [hls])

  /**
   * Hls 영상 로드 useEffect
   */
  useEffect(() => {
    if (!hls || !videoRef.current || !videoUrl || isHlsLoaded) return

    hls.loadLevel = 0
    if (isLowResolution) {
      hls.autoLevelCapping = 0
    }
    hls.stopLoad()
    hls.loadSource(videoUrl)
    hls.startLoad()
    setIsHlsLoaded(true)

    if (!Hls.isSupported()) {
      videoRef.current.src = videoUrl
      return
    }
    hls.attachMedia(videoRef.current)
  }, [hls, isHlsLoaded, videoRef, videoUrl, isLowResolution])

  /**
   * 에러 이벤트 핸들러 설정 useEffect
   */
  useEffect(() => {
    if (!hls) return

    const handleError = (_e: Events.ERROR, data: ErrorData) => {
      if (data?.details === 'manifestIncompatibleCodecsError') return

      if (data?.details === 'manifestLoadError') {
        hls.destroy()
        setTimeout(() => {
          setHls(null)
          setIsHlsLoaded(false)
        }, RETRY_DELAY_SEC * 1000)
        return
      }

      videoRef.current?.pause()
    }
    hls.on(Hls.Events.ERROR, handleError)

    return () => {
      hls.off(Hls.Events.ERROR, handleError)
    }
  }, [hls, videoRef])

  /**
   * VOD 전환 시 처리를 위한 이벤트 핸들러 설정 useEffect
   */
  useEffect(() => {
    if (!hls) return

    const handleLevelUpdated = (_event: Events.LEVEL_UPDATED, data: LevelUpdatedData) => {
      const isLiveStreaming = data.details.live
      if (isLiveStreaming) return
      //   dispatch(SET_DURATION({ duration: data.details.totalduration, type: 'total' }))
    }
    hls.on(Hls.Events.LEVEL_UPDATED, handleLevelUpdated)

    return () => {
      hls.off(Hls.Events.LEVEL_UPDATED, handleLevelUpdated)
    }
  }, [hls, dispatch])

  /**
   * hls 및 비디오 초기화 useEffect
   */
  const enteredTime = new Date().getTime()
  useEffect(() => {
    return () => {
      const nowTime = new Date().getTime()
      if (nowTime - enteredTime < 100) return
      if (!hls) return
      hls.stopLoad()
      hls.detachMedia()
      hls.destroy()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hls])

  return hls
}

export default useHls
