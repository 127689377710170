import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from '@emotion/styled'
import QRCode from 'qrcode.react'
import ENV from 'libs/config'
import KEYCODE from 'libs/constants/keycode'
import i from 'libs/i18n'
import { RootState } from 'libs/store'

interface ApplicationInformation {
  iconPath: string
  id: string
  name: string
  version: string
}

interface SystemInfoBuild {
  buildVersion: string
  manufacturer: string
  model: string
}

interface SystemInfoDeviceCapability {
  camera: boolean
  location: boolean
  locationGps: boolean
  microphone: boolean
  nativeApiVersion: string
  platformName: string
  platformVersion: string
  speechRecognition: boolean
  wifi: boolean
  webApiVersion: string
}

interface Props {
  isOpenCustomCenterModal: boolean
  setIsOpenCustomCenterModal: React.Dispatch<React.SetStateAction<boolean>>
}

const CustomCenter: React.FC<Props> = ({ isOpenCustomCenterModal, setIsOpenCustomCenterModal }) => {
  const renderPartnerId = useSelector((state: RootState) => state.app.renderPartnerId)
  const [appVersion, setAppVersion] = useState<string>()
  const [platformVersion, setPlatformVersion] = useState<string>()
  const [systemModel, setSystemModel] = useState<string>()
  const closeModalButtonRef = useRef<HTMLButtonElement>(null)

  const webVersion = useMemo(() => {
    switch (renderPartnerId) {
      case 'ebay':
        return ENV.VERSION_EBAY
      case null:
      default:
        return ENV.VERSION_COMMON
    }
  }, [renderPartnerId])

  /**
   * 앱 버전과 모델 정보 초기화 useEffect
   */
  useEffect(() => {
    if (!window.tizen) return

    const { version }: ApplicationInformation = window.tizen.application.getAppInfo()
    setAppVersion(version)

    const productinfo = webapis.productinfo
    const modelCode =
      productinfo && productinfo.getModelCode() ? productinfo.getModelCode() : undefined

    if (modelCode) {
      setSystemModel(modelCode)
    } else {
      const handleSuccess = ({ model }: SystemInfoBuild) => setSystemModel(model)
      const handleFail = () => setSystemModel('fail to get info')
      window.tizen.systeminfo.getPropertyValue('BUILD', handleSuccess, handleFail)
    }

    const { platformVersion }: SystemInfoDeviceCapability =
      window.tizen.systeminfo.getCapabilities()
    setPlatformVersion(platformVersion)
  }, [])

  /**
   * 모달 열기 시 닫기 버튼 포커싱 useEffect
   */
  useEffect(() => {
    if (!isOpenCustomCenterModal) return
    closeModalButtonRef.current?.focus()
    closeModalButtonRef.current?.classList.add('active')
  }, [isOpenCustomCenterModal])

  /**
   * 'OK' 키다운 이벤트 핸들러 매핑 useEffect
   */
  const handleKeydownCustomCenter = useCallback(
    (e: KeyboardEvent) => {
      if (!isOpenCustomCenterModal || e.keyCode !== KEYCODE.OK) return
      setIsOpenCustomCenterModal(false)
    },
    [isOpenCustomCenterModal, setIsOpenCustomCenterModal],
  )
  useEffect(() => {
    window.addEventListener('keydown', handleKeydownCustomCenter)
    return () => {
      window.removeEventListener('keydown', handleKeydownCustomCenter)
    }
  }, [handleKeydownCustomCenter])

  return (
    <>
      <CustomCenterBg className={isOpenCustomCenterModal ? 'active' : ''}></CustomCenterBg>
      <CustomCenterWrap className={isOpenCustomCenterModal ? 'active' : ''}>
        <div className="customCenterCont">
          <div className="customCenterTable">
            <ul className="customCenterList">
              <li>
                <div className="listTit">{i.t('main_page.info_version')}</div>
                <div className="listCont">{`${appVersion ?? 'Unknown'} 
                  / Web: ${webVersion ?? 'Unknown'}`}</div>
              </li>
              <li>
                <div className="listTit">{i.t('main_page.info_device')}</div>
                <div className="listCont">{systemModel ?? 'Unknown'}</div>
              </li>
              <li>
                <div className="listTit">{i.t('main_page.info_platform')}</div>
                <div className="listCont">
                  {platformVersion ? `Tizen ${platformVersion}` : 'Unknown'}
                </div>
              </li>
              <li>
                <div className="listTit">{i.t('main_page.info_language')}</div>
                <div className="listCont">
                  {window.localStorage.getItem('userSelectedLanguage') !== null
                    ? window.localStorage.getItem('userSelectedLanguage')
                    : 'ko'}
                </div>
              </li>
              <li>
                <div className="listTit">{i.t('main_page.info_region')}</div>
                <div className="listCont">{window.localStorage.getItem('regionValue')}</div>
              </li>
              <li>
                <div className="listTit">{i.t('main_page.info_contact')}</div>
                <div className="listCont">
                  <QRCode
                    className="popup_qr_code"
                    size={360}
                    style={{
                      width: '360px',
                      height: '360px',
                      filter: 'grayscale(0.5)',
                      color: '#888',
                    }}
                    value={'https://sauce.im/forms/contact'}
                    level={'L'}
                  />
                  <span className="add_detail_info">{'https://sauce.im/forms/contact'}</span>
                  <span className="add_detail_info">{'TEL: +82 2-6425-0153'}</span>
                </div>
              </li>
            </ul>
          </div>
          <div className="customCenterBtns">
            <button className="closeBtn confirmBtn active" ref={closeModalButtonRef}>
              {i.t('main_page.info_close')}
            </button>
          </div>
        </div>
      </CustomCenterWrap>
    </>
  )
}

const CustomCenterBg = styled.div`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  // z-index: -1;
  // opacity: 0;
  z-index: 25;
  opacity: 1;
  &.active {
    z-index: 25;
    opacity: 1;
    transition: opacity 0.5s;
  }
`
const CustomCenterWrap = styled.div`
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 50%;
  height: auto;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 10px;
  padding: 40px;
  z-index: 25;
  opacity: 1;
  &.active {
    z-index: 25;
    opacity: 1;
    transition: opacity 0.5s;
  }
  .customCenterCont {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .customCenterTable {
      padding-bottom: 40px;
      .customCenterList {
        display: flex;
        flex-direction: column;
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          display: flex;
          align-items: center;
          padding-bottom: 25px;
          &:last-of-type {
            align-items: flex-start;
            padding-bottom: 0;
          }
          .listTit {
            width: 200px;
            font-size: 34px;
            font-weight: 600;
            color: #555;
          }
          .listCont {
            font-size: 28px;
            font-weight: 400;
            color: #555;
            &:last-of-type {
              display: flex;
              flex-direction: column;
            }
            .add_detail_info {
              display: block;
              padding-top: 20px;
            }
          }
        }
      }
    }
    .customCenterBtns {
      display: flex;

      .closeBtn {
        font-size: 34px;
        font-weight: 600;
        color: #0c4da2;
        width: 400px;
        height: 60px;
        border-radius: 30px;
        margin: 0 15px;
        border: 1px solid #0c4da2;
        line-height: 1;
        background-color: #fff;
        &.active {
          background-color: #0c4da2;
          color: #fff;
        }
      }
    }
  }
`

export default CustomCenter
