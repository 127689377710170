import React, { useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import QRCode from 'qrcode.react'
import i from 'libs/i18n'
import { addFcQueryToUrl } from 'libs/utils'

const UrlQrLevel = 'L' //QRCode의 Level Option : 'L' | 'M' | 'Q' | 'H'

interface Props {
  brandName?: string
  isModalOpen?: boolean
  modalState?: string
  productName?: string
  redirectUrl?: string
  thumbnailUrl?: string
}

const Modal: React.FC<Props> = ({
  brandName,
  isModalOpen,
  modalState,
  productName,
  redirectUrl,
  thumbnailUrl,
}) => {
  const [isOpenModalBg, setIsOpenModalBg] = useState<boolean>(false)
  const [urlQr, setUrlQr] = useState<string>('')
  const modalCloseRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    if (redirectUrl) {
      const urlQR = productName ? addFcQueryToUrl(redirectUrl) : redirectUrl
      setUrlQr(urlQR)
    }
    setIsOpenModalBg(!!isModalOpen)
    modalCloseRef.current?.focus()
  }, [isModalOpen, isOpenModalBg, modalState, productName, redirectUrl])

  return (
    <ModalWrapper className={isOpenModalBg ? 'popup_bg active' : 'popup_bg'}>
      {modalState === 'single' && (
        <div className="popup_bx single">
          <div className="popup_tit">
            <h3>
              <span className="singleTitle">{i.t('player_page.modal_qr_scan_info')}</span>
            </h3>
          </div>
          <div className="popup_cont">
            <div className="popup_qr">
              <QRCode
                className="popup_qr_code"
                size={360}
                style={{
                  width: '360px',
                  height: '360px',
                  filter: 'grayscale(0.5)',
                  color: '#888',
                }}
                value={urlQr}
                level={UrlQrLevel}
              />
            </div>
          </div>
          <div className="popup_bottom">
            <button
              className={isOpenModalBg ? 'popup_close focused' : 'popup_close'}
              ref={modalCloseRef}
            >
              {i.t('player_page.modal_close')}
            </button>
          </div>
        </div>
      )}

      {modalState === 'double' && (
        <div className="popup_bx propduct">
          <div className="popup_tit">
            <h3>
              <span className="productBrand">{brandName}</span>
              <span className="productTitleCover">
                <span className="productTitle">{productName}</span>
              </span>
            </h3>
          </div>
          <div className="popup_cont">
            {thumbnailUrl ? (
              <div className="popup_thumbnail">
                <img src={`${thumbnailUrl}?h=200&w=200&f=webp&q=90`} alt={productName} />
              </div>
            ) : null}
            <div className="popup_qr">
              <QRCode
                className="popup_qr_code"
                size={360}
                style={{
                  width: '360px',
                  height: '360px',
                  filter: 'grayscale(0.5)',
                  color: '#888',
                }}
                value={urlQr}
                level={UrlQrLevel}
              />
            </div>
          </div>
          <div className="popup_bottom">
            <button
              className={isOpenModalBg ? 'popup_close focused' : 'popup_close'}
              ref={modalCloseRef}
            >
              {/* 닫기 */}
              {i.t('player_page.modal_close')}
            </button>
          </div>
        </div>
      )}
    </ModalWrapper>
  )
}

const ModalWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: -1;
  opacity: 0;

  &.active {
    z-index: 22;
    opacity: 1;
    transition: opacity 0.5s;

    .popup_bx {
      position: fixed;
      top: 50%;
      left: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 8px;
      padding: 0px;
      width: 960px;
      height: auto;
      background-color: #fff;
      transform: translate(-50%, -50%);

      .popup_tit {
        padding: 60px 30px;
        border-bottom: 0px solid #efefef;
        width: 100%;

        h3 {
          display: flex;
          width: 100%;
          text-align: center;
          font-weight: 400;
          font-size: 34px;

          span.productBrand {
            display: inline-block;
            margin-right: 20px;
            white-space: pre;
            font-weight: 600;
          }

          span.productTitleCover {
            overflow: hidden;

            .productTitle {
              display: inline-block;
              white-space: pre;
              animation: 25s adminTextMove infinite linear;
            }
          }

          span.singleTitle {
            display: block;
            width: 100%;
            text-align: center;
          }
        }
      }

      &.single {
        .popup_qr_code {
        }
      }

      .popup_cont {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        padding: 30px 0px;
        width: 100%;

        .popup_thumbnail {
          border: 1px solid #efefef;
          border-radius: 8px;
          margin: 0 10px;
          width: 360px;
          height: 360px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .popup_qr {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 10px;
          width: 360px;
          height: 360px;

          .popup_qr_code {
            width: 150px;
            height: 150px;
          }
        }
      }

      .popup_bottom {
        display: flex;
        justify-content: center;
        padding: 60px 20px;
        width: 100%;

        button {
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #0c4da2;
          border-radius: 30px;
          width: 250px;
          height: 60px;
          color: #0c4da2;
          background-color: #fff;
          object-fit: contain;
          cursor: pointer;
          font-size: 34px;
          font-weight: 600;

          &.focused {
            background-color: #0c4da2;
            color: #fff;
            transition: background-color 0.5s, color 0.5s;
            transition-delay: 1s;
          }
        }
      }
    }

    @keyframes adminTextMove {
      0% {
        transform: translate(0%);
      }
      10% {
        transform: translate(0%);
      }
      95% {
        transform: translate(-100%);
      }
      100% {
        transform: translate(-100%);
      }
    }
  }
`

export default Modal
