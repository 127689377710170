import React, { useCallback, useRef } from 'react'
import { useSelector } from 'react-redux'
import styled from '@emotion/styled'
import {
  FocusableComponentLayout,
  FocusContext,
  FocusDetails,
  KeyPressDetails,
  useFocusable,
} from '@noriginmedia/norigin-spatial-navigation'
import SliderCard from 'components/card/SliderCard'
import i from 'libs/i18n'
import { RootState } from 'libs/store'
interface RecentBroadcastContainerProps {
  onPressEnter?: (props: object, details: KeyPressDetails) => void
  onFocus?: (layout: FocusableComponentLayout, props: object, details: FocusDetails) => void
}

const RecentBroadcastContainer: React.FC<RecentBroadcastContainerProps> = (props) => {
  const { onPressEnter, onFocus } = props
  const recentBroadcastList = useSelector((state: RootState) => state.app.recentBroadcastList)
  const showEventSection = useSelector(
    (state: RootState) => state.app.eventSectionSetting.showEventSection,
  )
  const partnerList = useSelector((state: RootState) => state.app.partnerList)
  const isShowPartnerInfo = useSelector((state: RootState) => !state.app.renderPartnerId)

  const { ref, focusKey } = useFocusable({
    focusKey: 'recent',
    onFocus,
  })

  const scrollingRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null)

  const onAssetFocus = useCallback(
    (layout, extraProps, details) => {
      const { x: left } = layout
      const { event } = details
      if (scrollingRef.current) {
        scrollingRef.current.scrollTo({ left, behavior: !event ? 'instant' : 'smooth' })
      }
    },
    [scrollingRef],
  )

  return (
    <FocusContext.Provider value={focusKey}>
      <RecentSectionWrapper ref={ref}>
        {showEventSection && <RecentSectionTitle>{i.t('main_page.recent')}</RecentSectionTitle>}
        <RecentSectionScrollingWrapper ref={scrollingRef}>
          <RecentSectionScrollingContent className="slider_box">
            {recentBroadcastList?.map((broadcast, index) => {
              const {
                broadcastId,
                broadcastName,
                broadcastStateCode,
                broadcastThumbnailList,
                partnerId,
              } = broadcast
              const representImage = broadcastThumbnailList.filter(
                ({ isRepresentative }) => !!isRepresentative,
              )
              const partnerInfo = partnerList?.find(
                (partnerInfo) => partnerInfo.partnerId === partnerId,
              )

              const thumbnailUrl = broadcast?.sauceTvPosterImageUrl
                ? broadcast?.sauceTvPosterImageUrl
                : representImage[0].thumbnailUrl

              return (
                <SliderCard
                  key={`recent-card-${broadcastId}`}
                  partnerId={partnerId}
                  broadcastStateCode={broadcastStateCode}
                  thumbnail={thumbnailUrl}
                  broadcastName={broadcastName}
                  broadcastId={broadcastId}
                  shopName={i.t(`common_brand.${partnerId}`)}
                  sectionName="recent"
                  isShowPartnerInfo={isShowPartnerInfo}
                  partnerProfileImageUrl={partnerInfo?.thumbnail}
                  onEnterPress={onPressEnter}
                  onFocus={onAssetFocus}
                  index={index}
                />
              )
            })}
          </RecentSectionScrollingContent>
        </RecentSectionScrollingWrapper>
      </RecentSectionWrapper>
    </FocusContext.Provider>
  )
}

const RecentSectionWrapper = styled.div`
  width: 100%;
`
const RecentSectionTitle = styled.div`
  padding: 30px 0 0 20px;
  font-size: 48px;
  line-height: 64px;
  color: #fff;
  font-weight: 600;
`

const RecentSectionScrollingContent = styled.div`
  display: flex;
  flex-direction: row;
`

const RecentSectionScrollingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  overflow-x: auto;
  overflow-y: hidden;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
`
export default RecentBroadcastContainer
