import React, { useEffect } from 'react'
import styled from '@emotion/styled'

interface Props {
  currentTime?: string
  isShowControl: boolean
  totalTime?: string
  videoBarDistance: number
  setIsShowControl: React.Dispatch<React.SetStateAction<boolean>>
}

const VideoControl: React.FC<Props> = ({
  currentTime,
  isShowControl,
  totalTime,
  videoBarDistance,
  setIsShowControl,
}) => {
  useEffect(() => {
    setTimeout(() => {
      setIsShowControl(false)
    }, 5000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <VideoControlWrapper className={isShowControl ? 'active' : ''}>
      <div className="videoControlSpace">
        <div className="videoControlBar" style={{ width: `${videoBarDistance}%` }}>
          <div className="videoControlRoundOuter">
            <div className="videoControlRoundInner"></div>
          </div>
        </div>
        <div className="videoControlTime">
          <div className="videoControlCurrentTime">
            <p>{currentTime}</p>
          </div>
          <div className="videoControlTotalTime">
            <p>{totalTime}</p>
          </div>
        </div>
      </div>
    </VideoControlWrapper>
  )
}
const VideoControlWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 40px 20px 50px;
  width: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
  opacity: 0;
  transition: opacity 0.5s;
  z-index: 15;

  &.active {
    opacity: 1;
  }

  .videoControlSpace {
    position: relative;
    border-radius: 3px;
    width: 100%;
    height: 6px;
    background-color: rgba(255, 255, 255, 0.25);

    .videoControlBar {
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 3px;
      width: 0%;
      height: 100%;
      background-color: #f00;
      border-radius: 3px;

      .videoControlRoundOuter {
        position: absolute;
        right: 0;
        top: 50%;
        border-radius: 50%;
        width: 18px;
        height: 18px;
        background-color: rgba(255, 255, 255, 0.75);
        transform: translate(50%, -50%);

        .videoControlRoundInner {
          position: absolute;
          top: 50%;
          left: 50%;
          border-radius: 50%;
          width: 12px;
          height: 12px;
          background-color: #f00;
          transform: translate(-50%, -50%);
        }
      }
    }

    .videoControlTime {
      & > div {
        position: absolute;
        bottom: -28px;

        &.videoControlCurrentTime {
          left: 0;
        }

        &.videoControlTotalTime {
          right: 0;
        }

        p {
          color: #fff;
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
  }
`

export default VideoControl
