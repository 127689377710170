import Kinesis from 'aws-sdk/clients/kinesis'
import ENV from 'libs/config'

const kinesisApiClient = new Kinesis({
  apiVersion: '2013-12-02',
  region: 'ap-northeast-2',
  credentials: {
    accessKeyId: ENV.AWS_KINESIS_CREDENTIAL_ACCESSKEY_ID,
    secretAccessKey: ENV.AWS_KINESIS_CREDENTIAL_SECRET_ACCESSKEY,
  },
})

export default kinesisApiClient
