const zhCn = {
  date_format: {
    date: '{{year}}年 {{month}}月 {{day}}日',
    schedule_time: '{{time,schedule_time}}',
  },

  time: {
    am: '午前',
    pm: '午後',
    today: '今天',
    tomorrow: '明天',
    hour: '{{hour}}时',
    hour_minute: '{{hour}}:{{minute}}',
  },

  unit: {
    hundred_million: '亿',
    issue_count: '{{count}}张',
    ten_thousand: '万',
    thousand: '千',
    won: '₩{{price}}',
  },

  add: '添加',
  add_banned_terms: '请输入欲设定的敏感词汇。',
  administrator_commented: '管理员已评论了您的聊天信息。',
  administrator_froze_chat: '管理员已冻结该对话框。仅限管理员参与该聊天。',
  administrator_not_issue_coupons: '管理员无法发送优惠券。',
  administrator_unfroze_chat: '管理员已恢复该对话框。您可以参与该聊天。',
  age: '年龄',
  agree_personal_info: '同意收集与使用个人信息',
  alarm_notifications: '接收提醒',
  all_coupon_issued: 'All coupon has been issued.',
  already_added_banned_term: '该敏感词汇已存在。',
  auto_report_due_to_spam: '因刷屏而被系统举报',
  available_until_period: '截至{{period}}可用',
  banned_terms: '敏感词汇',
  banned_terms_added: '已添加至实时敏感词汇列表。',
  banned_terms_deleted: '已从实时敏感词汇列表中删除。',
  banned_terms_included: '聊天内容里包含了敏感词汇。',
  banned_terms_only_applicable_to_live: '* 额外设定的敏感词汇仅适用于该直播。',
  broadcast_alarm_notified: '直播开始时将为您发送提醒。',
  broadcast_alarm_success: '已设置直播提醒。',
  broadcast_disconnected: '当前直播信号已断开。请稍等。',
  broadcast_ended: '直播已结束。',
  broadcast_notice: '直播通知',
  broadcast_restored: '直播信号已恢复正常。',
  broadcast_schedule_can_be_changed: '直播时间可能因公司内部的实际情况而发生变化。',
  broadcast_started: '直播已开始。',
  broadcast_unstable: '啊！直播状态卡顿不流畅。',
  buttons_at_bottom_right: '※ 右侧最下方按钮 > 直播评论标签',
  cancel: '取消',
  cancel_notifications: '您要取消直播提醒吗？',
  cancel_to_register_purchase_auth_winner: '确定要取消添加中奖者信息吗？',
  canceled_broadcast: 'This broadcast has been cancelled.\nSee you in the next LIVE.',
  cannot_add_sold_out_to_wish_list: '无法收藏已售罄的商品。',
  cannot_be_replayed: '该直播不提供重播服务。',
  cannot_edit_after_sign_in: '登录后不可对上述信息进行修改。',
  cannot_edited_after_sent: '无法删除、修改已发送的回复，请仔细确认后进行发送。',
  change_nickname: '更改聊天昵称',
  change_to_portrait: `Sorry, We doesn't support landscape mode.`,
  chat_report: 'Report',
  chat_restrict: '禁言',
  chat_restrict_included: '包括禁言',
  chat_restricted_by_administrator: '您已被管理员禁言。',
  chat_restriction: '禁言',
  chat_warning: '使用不文明语言时将无法正常使用服务。',
  chatroom_frozen: '该聊天室已被冻结。',
  check_broadcast_alarm_notifications: '您要接收直播提醒吗？',
  check_code: '请确认入场代码。',
  click_to_play_info_video: 'Click here to play the instructional video.',
  close: '关闭',
  confirm: '确认',
  congratulations_on_winning: '恭喜您中奖了。',
  connection_unstable: '啊！连接卡顿不流畅。请稍等。',
  contact_exclude_dashes: '联系方式（不填写-）',
  converting_to_vod: '直播已结束，正在转换为VOD模式。',
  copy: '复制',
  coupon: '优惠劵',
  coupon_issued: '已发送优惠券。',
  coupon_received: '已领取优惠券',
  current_nickname: '当前昵称',
  days_ago: '天前',
  delete: '删除',
  delete_comment: 'Delete comment',
  delete_report: '从举报列表中删除',
  deleted_comment_no_revert: 'Unable to revert deleted comments.',
  discount: '优惠',
  do_cancel: '取消',
  do_copy: '复制',
  do_delete: '删除',
  do_pin: 'Pin',
  do_report: '举报',
  download: '下载',
  edit: '修改',
  edit_comment: '修改我的评论',
  email: '电子邮箱',
  enter_at_lease_one: '请输入至少1个字。',
  enter_at_least_3_purchase_number: '请输入超过3位数的购买号码',
  enter_banned_terms: '请输入要设定的敏感词汇。',
  enter_chat: '参与聊天',
  enter_code: '请输入{{codeLength}}位的入场代码。',
  enter_contact: '请输入联系方式。',
  enter_contact_number_or_email: '输入联系方式或电子邮箱地址（未输入时不显示）',
  enter_email: '请输入电子邮箱地址。',
  enter_max_15_chars: 'Enter up to 15 characters',
  enter_max_40_chars: '最多可输入40个字符',
  enter_name: '请输入姓名。',
  enter_nickname: '请输入昵称。',
  enter_purchase_authentication_winner: '输入购买认证的中奖者',
  enter_purchase_number: '请输入购买（订单）号码。',
  enter_reply: '请输入回复。',
  enter_same_message_consecutively: '无法连续发送相同的聊天内容。',
  enter_short_time_consecutively: '无法在短时间内连续发送聊天内容。',
  enter_too_many_message: '因聊天内容发送过于频繁，{{sec}}秒内无法输入内容。',
  enter_without_space: '请勿输入空格。',
  entered_with_nickname: '{{nickname}}成功入场。',
  entered_with_nickname_other: '{{nickname}}与其他{{other}}位成功入场。',
  event_auto_exit_on_vod: '转换为VOD模式时，活动将自动结束。',
  event_can_start_anytime_on_live: 'LIVE中可以随时开始活动。',
  event_management: '活动管理',
  event_start: '活动开始',
  exit: '离开',
  expose_with_masking_and_max: '(最多50名，部分信息将通过（*）进行保密处理。)',
  failed_issue_coupon: '优惠券发送失败。',
  failed_to_enter_message: '聊天内容输入失败。',
  failed_to_enter_message_with_error: '聊天内容输入失败。错误：[无法找到该登录信息，请重新登录。]',
  female: '女性',
  fill_out_form_to_chat: '参与聊天前请输入以下信息。',
  freeze_chat: '冻结聊天',
  full: '观看视频',
  harassment_abuse: '嘲弄或是欺凌',
  hide_comments: '隐藏评论',
  highlight: '精彩片段',
  hours_ago: '小时前',
  info_area: '通知事项内容',
  info_image: '图片说明',
  info_image_displayed: '上传的通知图片将对观众可见。',
  information_message_to_winner: '中奖相关信息',
  intro: '소개',
  invalid_email_format: '电子邮件的格式错误。',
  invalid_phone_format: '联系方式格式错误。请以01012345678的格式输入。',
  is_where_see_all_comments: '可在{位置}查看管理员的全部评论。',
  issue_during_live: '仅可在直播时发放',
  join_broadcast: '参与直播',
  join_chat_real_time: '请尝试实时聊天。',
  join_secret: '进入Secret直播',
  leave_broadcast: '确定要离开直播吗？',
  live_comment: 'LIVE评论',
  live_comments_tab: 'LIVE评论标签',
  live_intro: 'LIVE介绍',
  live_notice: 'LIVE通知',
  male: '男性',
  mins_ago: '分钟前',
  more_comments: '查看其他{{count}}个评论',
  mr: '<bold>{{name}}<bold>',
  must_agree_collect_use_info: '必须同意收集与使用您的个人信息。',
  must_select_all_option: '必须选择所有可选项。',
  name: '姓名',
  new_nickname: '新昵称',
  nickname: '昵称',
  no: '否',
  no_content: '没有内容。',
  no_coupon: '没有可以使用的优惠券。请等待下期优惠券！',
  no_intro: '没有内容。',
  no_notice: '没有内容。',
  no_products_in_wish_list: '没有已收藏的商品。请在直播中收藏所需商品。',
  no_reply: '没有内容。',
  no_shortcut: '没有已添加的快捷短语。',
  none: '无',
  note: 'Attention',
  now: '不久前',
  obscene_message: '淫秽信息',
  obscenity_abuse: 'Sexual or bullying',
  only_possible_during_live: '仅在直播时可用。',
  optional: '可选项',
  other_inappropriate_language: '其他不得体发言',
  participate: 'Join',
  participate_success: '购买认证參與成功！',
  phone_call_only_mobile: 'Calls are only available on mobile.',
  phone_number: 'Call',
  pin_chat: '聊天置顶',
  pin_chat_to_top: 'Pin chat to the top?',
  please_enter_nickname: '请输入昵称。',
  please_input_accurately: '在有奖直播中获奖时，将通过上述个人信息与您联系，请准确填写。',
  price_differ_after_live: '直播结束后，价格与优惠可能会有所变化。',
  products: '出售商品',
  profanity_aggressive_expression: '侮辱或是歧视言论',
  purchase: '购买',
  purchase_auth_icon_exposed_if_event_on: '活动开始变为ON时，播放器内将显示购买认证图标。',
  purchase_auth_result_icon_exposed_if_announcement_on:
    '公布中奖者变为ON时，播放器内将显示购买认证图标。',
  purchase_authentication: '购买认证',
  purchase_authentication_event: '购买认证活动',
  purchase_authentication_event_winner: '购买认证活动中奖者',
  purchase_authentication_participant_info: '购买认证参与信息',
  push_notifications: 'PUSH推送通知',
  push_notifications_turned_off_in_my_lotte:
    'PUSH推送通知尚未开启。\n请在“我的Lotte>设置”中开启PUSH推送通知。',
  real_time_chat: '实时聊天',
  reason_for_reporting: '举报原因',
  receive_coupon: '领取优惠券',
  receive_notifications: '接收提醒',
  receive_notifications_of_broadcast: '接收直播提醒',
  receiving_notifications: '正在接收提醒',
  recent_shortcut: '最近添加的快捷短语',
  register: '上传',
  register_broadcast_alarm: '申请直播提醒',
  register_notice_too: 'It is also added to the LIVE notice.',
  register_success: '당첨자 등록이 완료되었습니다.',
  report_count: '举报 {{count}}',
  report_list: '举报列表',
  report_submitted: '已受理举报。',
  required: '必选',
  select_reason_for_reporting: '举报原因',
  send: '发送',
  send_report: '受理举报',
  service_restricted_with_false_report: '一经发现恶意举报，将无法正常使用服务。',
  set_nickname: '设置昵称',
  share_broadcast: '转发直播',
  share_livestream: '转发直播',
  shortcut: '快捷短语',
  shortcut_can_use_in_liveconsole: 'The shortcut feature is available in the Live console.',
  show_nickname_on_chat: '输入聊天内容时将显示昵称。',
  sign_in: '登录',
  sign_in_chat: '登陆聊天',
  sign_in_info_timed_out: '已完成登录信息。 请重新登录。',
  signed_in: '已成功以{{nickname}}登录。',
  sold_out: '售罄',
  spam: '垃圾信息',
  speed: '倍速',
  stop: '停止导出',
  subscribe: '订阅',
  subscribe_shop: `您要订阅{{shopName}}的直播吗？`,
  subscriber_with_count: '订阅者 {{count}}',
  subscribing: '订阅中',
  touch_turn_on_sound: '按此处提高音量。',
  transmit: '导出',
  turn_on_notifications: '开启提醒',
  unable_find_sign_in_path: '无法找到登录路径。',
  unfreeze_chat: '恢复聊天',
  unpin: '解除置顶',
  unpin_chat: 'Confirm',
  unpin_chat_to_top: 'Are you sure to unpin the chat?',
  unsubscribe_shop: `您要取消订阅{{shopName}}的直播吗？`,
  up_to_discount: '最多可获得{{discount}}优惠',
  up_to_discount_upon_purchasing: '购买超过{{threshold}}时最多可获得{{discount}}优惠',
  upon_purchasing: '购买超过{{threshold}}时',
  url_copied: '已成功复制URL地址。',
  verify: 'Apply',
  view_more: '查看更多',
  view_on_app: '用应用程序观看',
  view_on_web: '用网页观看',
  view_terms: '查看条款',
  viewable_full_horizontal_video: '全屏模式在横屏观看横屏视频时可用。',
  wait_until_live_start: '直播开始后可以参与聊天。直播开始前请耐心等待。',
  winner_add: '输入中奖者',
  winner_announcement: '公布中奖者',
  winner_announcement_can_control_after_add: '输入中奖者信息后，可以ON或OFF公布中奖者功能。',
  winner_info_can_modify_after_vod: '转换为VOD模式时也可以输入或修改中奖者的信息。',
  winner_information: '中奖者信息',
  winner_management: '管理中奖者',
  wish_list: '收藏',
  wish_to_delete: '确定要删除吗？',
  wish_to_receive_notifications: '您要接收直播提醒吗？',
  wish_to_sign_in: '确定要登录吗？',
  write_comments: '写评论',
  writing_comments: '正在向{{nickname}}写评论',
  yes: '是',
}

export default zhCn
