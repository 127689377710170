import { initReactI18next } from 'react-i18next'
import i from 'i18next'
import en from './en'
import id from './id'
import ja from './ja'
import ko from './ko'
import th from './th'
import vi from './vi'
import zhCn from './zhCn'
import zhTw from './zhTw'

const resources = {
  // 영어
  en: {
    translation: en,
  },
  // 인도네시아어
  id: {
    translation: id,
  },
  // 일본어
  ja: {
    translation: ja,
  },
  // 한국어
  ko: {
    translation: ko,
  },
  // 태국어
  th: {
    translation: th,
  },
  // 베트남어
  vi: {
    translation: vi,
  },
  // 중국어 간체
  'zh-CN': {
    translation: zhCn,
  },
  // 중국어 번체
  'zh-TW': {
    translation: zhTw,
  },
}

i.use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss
      format: function (value, format) {
        if (format === 'schedule_time') {
          const lang = window.navigator.language.toLowerCase()
          if (lang === 'ko' || lang === 'ko-kr') {
            return value
          }
          return new Date(value).toLocaleString('en').replace(/\/\d{4},\s|:\d{2}\s/g, ' ')
        }
        return value
      },
    },
    keySeparator: undefined, // we do not use keys in form messages.welcome
    lng: window.navigator.language,
    resources,
  })

export default i
