import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import {
  FocusContext,
  init,
  pause,
  resume,
  setFocus,
  useFocusable,
} from '@noriginmedia/norigin-spatial-navigation'
import Modal from 'components/modal/Modal'
import KEYCODE from 'libs/constants/keycode'
import useSauceflexSocketSDK from 'libs/hooks/useSauceflexSocketSDK'
import i from 'libs/i18n'
import { RootState } from 'libs/store'
import { SET_FROM_POSITION } from 'libs/store/interaction/interaction.store'
import {
  REQ_KINESIS_CLICK_BANNER,
  REQ_KINESIS_CLICK_QR_POPUP,
  REQ_KINESIS_REACTION,
} from 'libs/store/kinesis/kinesis.store'
import {
  REQ_GET_SELECTED_BROADCAST,
  RESET_SELECTED_BROADCAST,
} from 'libs/store/player/player.store'
import { REQ_INIT_USER_SESSION } from 'libs/store/session/session.store'
import ChatLayout from './ChatLayout'
import ChatLive from './ChatLive'
import PlayerLayout from './PlayerLayout'
import ProductLayout from './ProductLayout'

interface Props {
  broadcastId: string
  isModal: boolean
  playerModalStatus?: boolean
  setPlayerModalStatus?: () => void
}

const PlayerContainer: React.FC<Props> = ({ broadcastId, isModal, playerModalStatus }) => {
  const isSessionLoading = useSelector((state: RootState) => state.session.isSessionLoading)
  const broadcastInfo = useSelector((state: RootState) => state.player.selectedBroadcast)
  const lastPositionInfo = useSelector((state: RootState) => state.interaction.nowPosition)
  const sauceflexSocketSDK = useSelector((state: RootState) => state.player.sauceflexSocketSDK)
  const renderPartnerId = useSelector((state: RootState) => state.app.renderPartnerId)

  const [modalStatus, setModalStatus] = useState<ModalStatus>({
    isShow: false,
    type: '', // single or double
    info: {
      productName: '',
      redirectUrl: '',
      brandName: '',
      thumbnailUrl: '',
    },
  })

  useSauceflexSocketSDK()

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { ref, focusKey } = useFocusable({
    focusKey: 'playerContainer',
    autoRestoreFocus: true,
    isFocusBoundary: true,
  })

  useEffect(() => {
    if (!isModal) {
      init({
        throttle: 200,
        throttleKeypresses: true,
      })
    }
    setFocus('playContainer')
  }, [playerModalStatus, isModal])

  const sendPresetMessage = useCallback(
    (type: 'boring' | 'clap' | 'happy' | 'hello' | 'horror' | 'love' | 'sad') => {
      const preset = {
        boring: '🥱',
        clap: '👏',
        happy: '😀',
        hello: i.t('player_page.preset_1'),
        horror: '😱',
        love: '😍',
        sad: '😭',
      }

      sauceflexSocketSDK?.emitSendMessage(preset[type])
    },
    [sauceflexSocketSDK],
  )

  /**
   * focus 초기화 useEffect
   */
  useEffect(() => {
    setFocus('player')
  }, [])

  /**
   * preview 진입시 이전 방송 정보 클린업 useEffect
   */
  useEffect(() => {
    const tizen = window.tizen
    if (!tizen) return
    const reqAppControl = tizen.application.getCurrentApplication().getRequestedAppControl()
    const data = reqAppControl.appControl.data as ApplicationControlData
    const filteredData = data.filter((d) => d.key === 'PAYLOAD')

    if (filteredData.length) {
      dispatch(RESET_SELECTED_BROADCAST())
    }
  }, [dispatch])

  /**
   * 방송 정보 초기화 useEffect
   */
  useEffect(() => {
    if (!broadcastId) return
    dispatch(REQ_GET_SELECTED_BROADCAST({ broadcastId }))
  }, [broadcastId, dispatch])

  /**
   * 세션 초기화 useEffect
   */
  useEffect(() => {
    if (!broadcastInfo?.broadcastId) return
    dispatch(REQ_INIT_USER_SESSION())
  }, [broadcastInfo?.broadcastId, dispatch])

  /**
   * 키다운 이벤트 매핑 useEffect
   */
  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      e.preventDefault()
      let customKeyCode: number | null = 0

      /**
       * player 내에서 stop 버튼 눌렀을 떄 focus 위치와 관계없이 back 버튼 누른 것 처럼 이동해야함.
       */
      if (window.tizen !== undefined) {
        try {
          window.tizen.tvinputdevice.registerKeyBatch(['MediaStop'])
          if (e.keyCode === window.tizen.tvinputdevice.getKey('MediaStop').code) {
            customKeyCode = KEYCODE.STOP
          }
        } catch (error) {
          console.error('Error exiting the Tizen app:', error)
        }
      }

      /**
       * 플레이어 내의 모달 (ex. QR code) focus 이슈 대응
       */
      if (e.keyCode === KEYCODE.OK && modalStatus.isShow) {
        if (modalStatus.type === 'notice') {
          const noticeCloseBtn = document.getElementById('chatLiveCloseBtn')
          const isCloseBtnFocused = noticeCloseBtn?.classList.contains('focused')
          if (noticeCloseBtn && !isCloseBtnFocused) return
        }
        resume()
        setModalStatus({ isShow: false, type: '', info: undefined })
        return
      }
      if (
        e.keyCode === KEYCODE.BACK ||
        e.keyCode === KEYCODE.BACK_SPACE ||
        customKeyCode === KEYCODE.STOP
      ) {
        if (modalStatus.isShow) {
          setModalStatus({
            isShow: false,
            type: '',
            info: {
              productName: '',
              redirectUrl: '',
              brandName: '',
              thumbnailUrl: '',
            },
          })
          return
        }
        dispatch(RESET_SELECTED_BROADCAST())
        /**
         * player (page) 에서 나갈 때
         */
        const { section } = lastPositionInfo
        switch (section) {
          case 'tomorrow':
          case 'today':
            navigate({
              pathname: '/schedule',
            })
            break
          case 'contents':
          case 'schedule':
            navigate({
              pathname: '/partners',
              search: `?partnerId=${broadcastInfo?.partnerId}`,
            })

            break
          case 'home':
          default:
            navigate({
              pathname: '/',
              search: renderPartnerId ? `?renderPartnerId=${renderPartnerId}` : '',
            })

            break
        }
      }
    }
    window.addEventListener('keydown', handleKeyPress)

    return () => window.removeEventListener('keydown', handleKeyPress)
  }, [dispatch, navigate, modalStatus, lastPositionInfo, broadcastInfo, renderPartnerId])

  /**
   * 좋아요 수 전송 쓰로틀링 useEffect
   */
  const [clickedLikeCount, setClickedLikeCount] = useState(0)

  useEffect(() => {
    if (!clickedLikeCount) return

    const timeoutId = setTimeout(() => {
      sauceflexSocketSDK?.emitSendLike(clickedLikeCount)
      dispatch(REQ_KINESIS_REACTION(clickedLikeCount))
      setClickedLikeCount(0)
    }, 500)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [clickedLikeCount, dispatch, sauceflexSocketSDK])

  /**
   * 리모컨 조작
   * section: 'banner' | 'preset' | 'like' | 'notice' | 'chat' | 'player' | 'product'
   *  url: 이동해야할 곳
   */
  const onPressEnter = useCallback(
    (info) => {
      if (modalStatus.isShow) {
        resume()
        setModalStatus({
          isShow: false,
          type: '',
          info: undefined,
        })
        return
      }
      const { url, thumbnailUrl, section, recentBroadcastIndex, partnerIndex, type } = info
      switch (section) {
        case 'banner':
          if (info.url) {
            if (info.bannerId) {
              dispatch(
                REQ_KINESIS_CLICK_BANNER({
                  bannerId: info.bannerId,
                  clickTime: new Date().toISOString(),
                }),
              )
            }
            setModalStatus({
              isShow: true,
              type: 'single',
              info: {
                productName: '',
                redirectUrl: url,
                brandName: '',
                thumbnailUrl: '',
              },
            })
            return
          } else if (!url && thumbnailUrl) {
            return
          }
          dispatch(
            SET_FROM_POSITION({
              page: 'home',
              section: recentBroadcastIndex === -1 ? 'brand' : 'recent',
              broadcastId: broadcastInfo?.broadcastId || '',
              partnerId: broadcastInfo?.partnerId || '',
              index: recentBroadcastIndex === -1 ? partnerIndex : recentBroadcastIndex,
            }),
          )
          navigate({
            pathname: '/partners',
            search: `?partnerId=${broadcastInfo?.partnerId}`,
          })
          break
        case 'preset':
          sendPresetMessage(type)
          break
        case 'like':
          setClickedLikeCount((prev) => ++prev)
          break
        case 'notice':
          setModalStatus({
            isShow: true,
            type: 'notice',
            info: undefined,
          })
          break
        case 'chat':
          dispatch(REQ_KINESIS_CLICK_QR_POPUP({ qrType: 'chatButton' }))
          setModalStatus({
            isShow: true,
            type: 'double',
            info: {
              productName: (broadcastInfo && broadcastInfo.broadcastName) ?? '',
              redirectUrl: (broadcastInfo && broadcastInfo.shortUrl) ?? '',
              brandName: '',
              thumbnailUrl:
                (broadcastInfo &&
                  broadcastInfo.productDetailList[0]?.productThumbnailUrlList[0].thumbnailUrl) ??
                '',
            },
          })
          break
        case 'product':
          // TODO: modal 에서 임의의 다른 frontend page 로 랜딩시켜 eElk 적용 후 해당 상품 페이지로 redirect
          setModalStatus({
            isShow: true,
            type: 'double',
            info: info.modalData,
          })
          break
      }
    },
    [broadcastInfo, dispatch, modalStatus, navigate, sendPresetMessage],
  )

  /**
   * 모달 표시 여부에 따라 포커싱 관련 키 이벤트 활성화 여부 처리 useEffect
   */
  useEffect(() => {
    if (modalStatus.isShow) {
      pause()
    } else {
      resume()
    }
  }, [modalStatus])

  if (!isSessionLoading) return null
  return (
    <>
      <PlayerWrapper>
        <FocusContext.Provider value={focusKey}>
          {broadcastInfo?.broadcastId && (
            <LayoutRootWrapper ref={ref}>
              <ChatLayout onPressEnter={onPressEnter} />
              <PlayerLayout />
              <ProductLayout onPressEnter={onPressEnter} />
            </LayoutRootWrapper>
          )}
        </FocusContext.Provider>
      </PlayerWrapper>
      <Modal
        isModalOpen={
          modalStatus.isShow && (modalStatus.type === 'single' || modalStatus.type === 'double')
        }
        brandName={modalStatus.info?.brandName}
        modalState={modalStatus.type}
        productName={modalStatus.info?.productName}
        redirectUrl={modalStatus.info?.redirectUrl}
        thumbnailUrl={modalStatus.info?.thumbnailUrl}
      />
      <ChatLive
        broadcastInfo={broadcastInfo}
        isShow={modalStatus.isShow && modalStatus.type === 'notice'}
      />
    </>
  )
}

const PlayerWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
`

const LayoutRootWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100%;
  background-color: #fff;

  .videoSpaceWrap {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    transition: box-shadow 0.5s;
  }

  .imageSpaceWrap {
    .previewImg {
      position: relative;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
    }
  }
`

export default PlayerContainer
