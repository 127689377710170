const th = {
  date_format: {
    date: '{{day}}/{{month}}/{{year}}',
    schedule_time: '{{time,schedule_time}}',
  },

  unit: {
    hundred_million: '00M',
    issue_count: '{{count}}',
    ten_thousand: '0K',
    thousand: 'K',
    won: '₩{{price}}',
  },

  add: 'เพิ่ม',
  add_banned_terms: 'Enter the blocked words you want to add.',
  administrator_commented: 'คุณได้รับคำตอบกลับ',
  administrator_froze_chat: 'แชทสดถูกล็อกแล้ว\nผู้ดูแลระบบเท่านั้นที่สามารถเข้าร่วมแชทได้ในขณะนี้',
  administrator_not_issue_coupons: `Admins can't get coupons.`,
  administrator_unfroze_chat: 'ปลดล็อกแชทสดแล้ว\nคุณสามารถเข้าร่วมแชทได้แล้ว',
  age: 'อายุ',
  agree_personal_info: 'ตกลงให้เราเก็บและใช้งานข้อมูลส่วนบุคคลของคุณ',
  alarm_notifications: 'รับการแจ้งเตือน',
  all_coupon_issued: 'คุณได้รับคูปองทั้งหมดแล้ว',
  already_added_banned_term: 'The blocked word already exists.',
  auto_report_due_to_spam: 'Self reported due to spamming.',
  available_until_period: 'มีจนถึง {{period}}',
  banned_terms: 'Blocked words',
  banned_terms_added: 'Added to real-time blocked words list.',
  banned_terms_deleted: 'Removed from the real-time blocked words list.',
  banned_terms_included: 'มีคำที่ถูกบล็อกในข้อความของคุณ',
  banned_terms_only_applicable_to_live:
    '* The blocked words that have been added will only apply to the Live stream.',
  broadcast_alarm_notified: 'การแจ้งเตือนจะถูกส่งไปเมื่อการสตรีมเริ่มต้น',
  broadcast_alarm_success: 'ตั้งการแจ้งเตือนการสตรีมสด',
  broadcast_disconnected: 'เราเสียสัญญาณการสตรีม โปรดรอสักครู่',
  broadcast_ended: 'การสตรีมสดสิ้นสุดแล้ว',
  broadcast_notice: 'แจ้งเตือนการสตรีม',
  broadcast_restored: 'ได้สัญญาณการสตรีมกลับคืนแล้ว',
  broadcast_schedule_can_be_changed: 'เวลาการสตรีมอาจเปลี่ยนแปลงตามสถานการณ์ในการสตรีม',
  broadcast_started: 'เริ่มต้นสตรีมสดแล้ว',
  broadcast_unstable: 'อุ๊ย ! การสตรีมนี้ไม่เสถียร',
  buttons_at_bottom_right: '※ ปุ่มขวาล่าง >',
  cancel: 'ยกเลิก',
  cancel_notifications: 'คุณต้องการยกเลิกการแจ้งเตือนการสตรีมนี้หรือไม่',
  cancel_to_register_purchase_auth_winner: `Would you like to cancel the registration of the winner's information?`,
  canceled_broadcast: 'ขออภัย การสตรีมสดนี้ถูกยกเลิก\nเจอกันครั้งหน้านะ',
  cannot_add_sold_out_to_wish_list: 'ไม่สามารถเพิ่มสินค้าที่หมดสต็อกแล้วเข้าไปในรายการที่ชอบ',
  cannot_be_replayed: 'ขออภัย คุณไม่สามารถเล่นสตรีมนี้ย้อนหลัง',
  cannot_edit_after_sign_in:
    'ชื่อเล่นของคุณจะถูกแสดงขึ้นเมื่อคุณเข้าร่วมการแชทสด และจะไม่สามารถแก้ไขข้อมูลที่กรอกลงไปได้',
  cannot_edited_after_sent:
    'เมื่อคุณส่งคำตอบกลับแล้ว คุณจะไม่สามารถแก้ไขหรือลบคำตอบนั้นได้ โปรดตรวจสอบก่อนจะส่ง',
  change_nickname: 'Change nickname',
  change_to_portrait: 'ใช้ในโหมดแนวตั้ง',
  chat_report: 'Report this message',
  chat_restrict: 'Disable',
  chat_restrict_included: 'Chat-disabled',
  chat_restricted_by_administrator: 'ปิดใช้งานโดยผู้ดูแลระบบ',
  chat_restriction: 'ปิดใช้งานแชทแล้ว',
  chat_warning: 'การใช้ภาษาที่ไม่เหมาะสมอาจทำให้การใช้บริการนี้ถูกจำกัด',
  chatroom_frozen: 'ล็อกห้องแชทแล้ว',
  check_broadcast_alarm_notifications: 'ท่านต้องการรับการเแจ้งเตือนการสตรีมสดหรือไม่',
  check_code: 'ตรวจสอบรหัสเข้าระบบของคุณ',
  click_to_play_info_video: 'Click here to play the instructional video.',
  close: 'ปิด',
  confirm: 'ยืนยัน',
  congratulations_on_winning: 'ยินดีด้วย คุณได้รับรางวัลนี้',
  connection_unstable: 'อุ๊ย ! การเชื่อมต่อสัญญาณไม่เสถียร\nโปรดรอสักครู่',
  contact_exclude_dashes: 'เบอร์โทรติดต่อ (ไม่ใช้ “-”)',
  converting_to_vod: 'การสตรีมสดสิ้นสุดลงแล้ว และกำลังแปลงเป็น VOD ',
  copy: 'ทำสำเนา',
  coupon: 'คูปอง',
  coupon_issued: 'คุณได้รับคูปองนี้แล้ว',
  coupon_received: 'คูปองที่ได้รับ',
  current_nickname: 'Current nickname',
  days_ago: 'วันที่แล้ว',
  delete: 'ลบ',
  delete_comment: 'ลบความคิดเห็น',
  delete_report: 'Delete',
  deleted_comment_no_revert: 'ไม่สามารถกู้คืนความคิดเห็นที่ถูกลบได้',
  discount: 'ส่วนลด',
  do_cancel: 'ยกเลิก',
  do_copy: 'ทำสำเนา',
  do_delete: 'ลบ',
  do_pin: 'Pin',
  do_report: 'รายงาน',
  download: 'ดาวน์โหลด',
  edit: 'แก้ไข',
  edit_comment: 'แก้ไขความคิดเห็นของฉัน',
  email: 'อีเมล',
  enter_at_lease_one: 'กรอกอย่างน้อย 1 อักขระ',
  enter_at_least_3_purchase_number: 'Enter at least 3 digits.',
  enter_banned_terms: 'Enter a blocked word.',
  enter_chat: 'เข้าร่วมการแชทสด',
  enter_code: 'กรอกรหัสเข้าระบบเป็น {{codeLength}} หน่วย',
  enter_contact: 'กรอกข้อมูลติดต่อของคุณ',
  enter_contact_number_or_email:
    'Enter contact information or email address. (not displayed, if not entered)',
  enter_email: 'กรอกอีเมลของคุณ',
  enter_max_15_chars: 'Enter up to 15 characters',
  enter_max_40_chars: 'Enter up to 40 characters',
  enter_name: 'กรอกชื่อของคุณ',
  enter_nickname: 'กรอกชื่อเล่นของคุณ',
  enter_purchase_authentication_winner: 'Enter purchase event winners',
  enter_purchase_number: 'กรอกหมายเลขการสั่งซื้อของคุณ',
  enter_reply: 'กรอกคำตอบกลับของคุณ',
  enter_same_message_consecutively: 'คุณไม่สามารถกรอกข้อความเดิมติดต่อกัน',
  enter_short_time_consecutively: 'คุณไม่สามารถกรอกข้อความเดิมติดต่อกันภายช่วงเวลาสั้นๆ',
  enter_too_many_message:
    'เนื่องจากมีการพิมพ์มากเกินไป คุณถูกปิดใช้งานการส่งข้อความเป็นเวลา {{sec}} วินาที',
  enter_without_space: 'กรอกโดยไม่เว้นช่องไฟ',
  entered_with_nickname: '{{nickname}} ได้เข้ามาแล้ว',
  entered_with_nickname_other: '{{nickname}} ได้ {{other}} เข้ามาแล้ว',
  event_auto_exit_on_vod: 'After converting to VOD, the event automatically ends.',
  event_can_start_anytime_on_live: 'You can start an event at any time during live.',
  event_management: 'Manage events',
  event_start: 'Start event',
  exit: 'ออก',
  expose_with_masking_and_max:
    '(Up to 50 persons, some parts of the information will be displayed as masked(*))',
  failed_issue_coupon: 'การรับคูปองล้มเหลว',
  failed_to_enter_message: 'การแชทล้มเหลว',
  failed_to_enter_message_with_error:
    'การแชทล้มเหลว \nข้อผิดพลาด: [การค้นหาข้อมูลการเข้าสู่ระบบล้มเหลว \nโปรดเข้าสู่ระบบอีกครัา',
  female: 'ผู้หญิง',
  fill_out_form_to_chat: 'กรอกข้อมูลด้านล่างนี้เพื่อเข้าร่วมการแชท',
  freeze_chat: 'ล็อกแชท',
  full: 'ดูวิดีโอเต็ม',
  harassment_abuse: 'Harassing or bullying',
  hide_comments: 'ซ่อนความคิดเห็น',
  highlight: 'ดูไฮไลท์',
  hours_ago: ' ชั่วโมงที่แล้ว',
  info_area: 'พื้นที่ข้อมูล',
  info_image: 'ภาพ',
  info_image_displayed: 'กำลังแสดงภาพนี้ต่อผู้ชม',
  information_message_to_winner: 'Announcement to the winners',
  intro: 'แนะนำ',
  invalid_email_format: 'ที่อยู่อีเมลนี้มีรูปแบบที่ไม่ถูกต้อง',
  invalid_phone_format: 'รายชื่อติดต่อนี้มีรูปแบบที่ไม่ถูกต้อง',
  is_where_see_all_comments:
    'ใน <bold>{{location}}</bold>คุณจะสามารถดูคำตอบกลับทั้งหมดจากผู้ดูแลระบบได้',
  issue_during_live: 'คุณสามารถรับคูปองได้ในขณะไลฟ์สดเท่านั้น',
  join_broadcast: 'เข้าร่วมสตรีมสดนี้',
  join_chat_real_time: 'เข้าร่วมแชทสดนี้',
  join_secret: 'เข้าร่วมสตรีมสดลับนี้',
  leave_broadcast: 'คุณต้องการออกจากการสตรีมนี้หรือไม่',
  live_comment: 'ตอบกลับสด',
  live_comments_tab: 'แท็บการตอบกลับสด',
  live_intro: 'แนะนำสด',
  live_notice: 'ประกาศสด',
  male: 'ผู้ชาย',
  mins_ago: 'นาทีที่แล้ว',
  more_comments: 'ดูความคิดเห็นอีก {{count}} รายการ',
  mr: '<bold>{{name}}<bold>',
  must_agree_collect_use_info: 'คุณต้องตกลงให้เราเก็บและใช้งานข้อมูลส่วนบุคคลของคุณ',
  must_select_all_option: 'ตัวเลือกทั้งหมดจะถูกเลือก',
  name: 'ชื่อ',
  new_nickname: 'New nickname',
  nickname: 'ชื่อเล่น',
  no: 'ไม่',
  no_content: 'กรอกคำตอบกลับเพื่อส่ง',
  no_coupon: 'ยังไม่มีคูปอง\nโปรดรอครั้งต่อไป',
  no_intro: 'ไม่มีการแนะนำสำหรับการสตรีมนี้',
  no_notice: 'ไม่มีการประกาศสำหรับการสตรีมนี้',
  no_products_in_wish_list: 'ไม่มีสินค้าในรายการที่ชอบ\nเพิ่มรายการที่คุณชอบ',
  no_reply: 'ไม่มีการตอบกลับสำหรับการสตรีมนี้',
  no_shortcut: 'ไม่มีปุ่มลัดที่ลงทะเบียนไว้',
  none: 'ไม่มี',
  note: 'หมายเหตุ',
  now: 'ก่อนหน้า',
  obscene_message: 'Obscene messages',
  obscenity_abuse: 'Obscene or bullying',
  only_possible_during_live: 'ทำได้ระหว่างไลฟ์สดเท่านั้น',
  optional: 'ตัวเลือก',
  other_inappropriate_language: 'Other inappropriate remarks',
  participate: 'เข้าร่วม',
  participate_success: 'เข้าได้เข้าร่วมกิจกรรมการซื้อนี้แล้ว',
  phone_call_only_mobile: 'สามารถโทรศัพท์ได้จากอุปกรณ์ไมไบล์',
  phone_number: 'โทร',
  pin_chat: 'Pin this message',
  pin_chat_to_top: 'Do you want to pin this message?',
  please_enter_nickname: 'กรอกชื่อเล่นของคุณ',
  please_input_accurately: 'กรอกข้อมูลในช่องต่างๆ อย่างถูกต้อง เพราะว่าจะถูกนำไปใช้ในการจัดกิจกรรม',
  price_differ_after_live: 'ราคาและสิทธิประโยชน์อาจเปลี่ยนแปลงเมื่อการสตรีมสดสิ้นสุดลง',
  products: 'สินค้าที่ขาย',
  profanity_aggressive_expression: 'Use of profanity or expressions of hatred',
  purchase: 'ซื้อ',
  purchase_auth_icon_exposed_if_event_on:
    'When the Start Event is ON, the purchase event icon will be displayed in the player.',
  purchase_auth_result_icon_exposed_if_announcement_on:
    'When the Winner Announcement is ON, the purchase event icon is displayed in the player.',
  purchase_authentication: 'กิจกรรมการซื้อ',
  purchase_authentication_event: 'กิจกรรมการซื้อ',
  purchase_authentication_event_winner: 'ผู้ชนะในกิจกรรมการซื้อ',
  purchase_authentication_participant_info: 'Purchase Event participants info"',
  push_notifications: 'การแจ้งเตือนแบบพุช',
  push_notifications_turned_off_in_my_lotte:
    'การแจ้งเตือนแบบพุชปิดอยู่\nเปิดการแจ้งเตือนแบบพุชใน Lotte ของฉัน > การตั้งค่า',
  real_time_chat: 'แชทสด',
  reason_for_reporting: 'Reason for report',
  recent_shortcut: 'Shortcuts registered lately',
  receive_coupon: 'รับคูปอง',
  receive_notifications: 'รับแจ้งเตือน',
  receive_notifications_of_broadcast: 'กำลังรับการแจ้งเตือนการสตรีม',
  receiving_notifications: 'กำลังรับการแจ้งเตือน',
  register: 'การลงทะเบียน',
  register_broadcast_alarm: 'แจ้งขอรับการแจ้งเตือนการสตรีมสด',
  register_notice_too: 'It will be also added to Live Announcements.',
  register_success: 'Winners info has been stored.',
  report_count: 'Report {{count}}',
  report_list: 'Chat-reported',
  report_submitted: 'Your report has been received.',
  required: 'จำเป็น',
  select_reason_for_reporting: 'Choose a reason for reporting this message.',
  send: 'ส่ง',
  send_report: 'Report accepted',
  service_restricted_with_false_report: 'False reports may restrict service usage.',
  set_nickname: 'กรอกชื่อเล่น',
  share_broadcast: 'แชร์สตรีมนี้',
  share_livestream: 'แชร์สตรีมนี้',
  shortcut: 'Shortcut',
  shortcut_can_use_in_liveconsole: 'The shortcut feature is available in the Live console.',
  show_nickname_on_chat:
    'ชื่อเล่นของคุณจะถูกแสดงขึ้นเมื่อคุณเข้าร่วมการแชทสด และจะไม่สามารถแก้ไขข้อมูลที่กรอกลงไปได้',
  sign_in: 'ลงชื่อเข้าสู่ระบบ',
  sign_in_chat: 'เข้าร่วมการแชทสด',
  sign_in_info_timed_out: 'ข้อมูลการลงชื่อเข้าสู่ระบบของคุณหมดอายุ \nโปรดลงชื่อเข้าสู่ระบบอีกครั้ง',
  signed_in: 'คุณได้เข้าสู่ระบบเป็น {{nickname}}',
  sold_out: 'ขายหมดแล้ว',
  spam: 'ข้อความสแปม',
  speed: 'ความเร็ว X',
  stop: 'Stop sharing',
  subscribe: 'สมัครสมาชิก',
  subscribe_shop: 'คุณต้องการสมัครสมาชิกการสตรีมสดของ {{shopName}} หรือไม่',
  subscriber_with_count: 'สมาชิก {{count}}',
  subscribing: 'ยกเลิกการเป็นสมาชิก',
  touch_turn_on_sound: 'แตะเพื่อเปิดเสียง',
  transmit: 'แชร์สิ่งนี้',
  turn_on_notifications: 'เปิดการแจ้งเตือน',
  unable_find_sign_in_path: 'การระบุเส้นทางการเข้าสู่ระบบล้มเหลว',
  unfreeze_chat: 'Unfreeze chat',
  unpin: 'Unpin',
  unpin_chat: 'Unpin this message',
  unpin_chat_to_top: 'Do you want to unpin this message?',
  unsubscribe_shop: 'คุณต้องการยกเลิกการเป็นสมาชิกการสตรีมสดของ {{shopName}} หรือไม่',
  up_to_discount: 'ลดราคาได้ถึง {{discount}}',
  up_to_discount_upon_purchasing: 'ลดราคาได้ถึง {{discount}} เมื่อมียอดซื้อขึ้นต่ำ {{threshold}}',
  upon_purchasing: 'สำหรับการซื้อขั้นต่ำ {{threshold}}',
  url_copied: 'ทำสำเนา URL นี้แล้ว',
  verify: 'เข้าร่วม',
  view_more: 'ดูเพิ่มเติม',
  view_on_app: 'ดูในแอป',
  view_on_web: 'ดูในเว็บ',
  view_terms: 'ดูข้อตกลงและเงื่อนไข',
  viewable_full_horizontal_video: 'วิดีโอแนวนอนจะแสดงเป็นแบบเต็มจอเมื่อดูในแนวนอน',
  wait_until_live_start:
    'คุณสามารถเข้าร่วมการแชทสดได้เมื่อการสตรีมสดเริ่มต้นขึ้น\nโปรดรอให้ถึงเวลานั้นก่อน',
  winner_add: 'Enter the winners',
  winner_announcement: 'Announce the winners',
  winner_announcement_can_control_after_add:
    'After entering the winners information, you can turn on/off the winners announcement.',
  winner_info_can_modify_after_vod:
    'You can enter/edit winner information even after VOD converted.',
  winner_information: 'Winners information',
  winner_management: 'Manage winners',
  wish_list: 'รายการที่ชอบ',
  wish_to_delete: 'คุณต้องการลบหรือไม่',
  wish_to_receive_notifications: 'คุณต้องการรับการแจ้งเตือนการสตรีมสดหรือไม่',
  wish_to_sign_in: 'ท่านต้องการลงชื่อเข้าสู่ระบบหรือไม่',
  write_comments: 'ให้ความคิดเห็น',
  writing_comments: 'ให้ความคิดเห็นแก่ {{nickname}}',
  yes: 'ใช่',
}

export default th
