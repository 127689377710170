import React from 'react'
import styled from '@emotion/styled'
import {
  FocusableComponentLayout,
  FocusDetails,
  useFocusable,
} from '@noriginmedia/norigin-spatial-navigation'

interface Props {
  children: React.ReactNode
  tabName: string
  pageName: string
  index: number
  isSelected: boolean
  onFocus?: (layout: FocusableComponentLayout, props: object, details: FocusDetails) => void
}

const Tab: React.FC<Props> = ({ children, tabName, pageName, onFocus, index, isSelected }) => {
  const { ref, focused } = useFocusable({ focusKey: tabName, onFocus })
  return (
    <TabWrapper key={tabName} ref={ref} className={`${focused ? 'focusedTabBtn' : ''}`}>
      <input
        type="radio"
        id={`${tabName}_tab`}
        name={`${pageName}_tab`}
        value={tabName}
        checked={isSelected}
        readOnly
      />
      <label htmlFor={`${tabName}_tab`} data-idx={index}>
        {children}
      </label>
    </TabWrapper>
  )
}

const TabWrapper = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.black2};

  label {
    display: flex;
    position: relative;
    font-size: 48px;
    font-weight: 500;
  }

  input {
    display: none;
  }

  input:checked + label > span,
  input:checked + label > svg {
    color: ${(props) => props.theme.white};
  }
  input:checked + label::before {
    content: '';
    position: absolute;
    bottom: 0px;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 10px;
    background-color: ${(props) => props.theme.white};
  }
`

export default Tab
