import React from 'react'
import { useSearchParams } from 'react-router-dom'
import HomeContainer from 'containers/home/Home.container'

const HomePage: React.FC = () => {
  const [searchParams] = useSearchParams()
  const renderPartnerId = searchParams.get('renderPartnerId')

  return <HomeContainer renderPartnerId={renderPartnerId} />
}

export default HomePage
