import { io, Socket } from 'socket.io-client'
import { SAUCEFLEX_SDK_EVENT_NAME as EVENT_NAME, TYPE_MESSAGE } from 'libs/@types/enums'
import ENV from 'libs/config'

/* Sauceflex Web Socket SDK */
export default class SauceflexSocketSDK {
  partnerId = ''
  roomId = ''
  socket: Socket
  token = ''
  userId = ''
  userNickname = ''

  constructor(partnerId: string, roomId: string) {
    this.partnerId = partnerId
    this.roomId = roomId
    this.socket = io(ENV.BASE_CHAT_URL, {
      autoConnect: false,
      reconnection: true,
      transports: ['websocket'],
    })
    this.token = ''
    this.userId = ''
    this.userNickname = ''
  }
  setUserInfo(token: string, userId: string, userNickname: string) {
    this.token = token
    this.userId = userId
    this.userNickname = userNickname
  }
  initSocket() {
    this.socket = io(ENV.BASE_CHAT_URL, {
      autoConnect: false,
      reconnection: true,
      transports: ['websocket'],
    })
  }

  onEventRegister(eventName: string, callbackFunc: Function) {
    this.socket.removeListener(eventName)
    this.socket.on(eventName, (data) => {
      // updateRoom 이벤트만 이름을 바꿔줌
      if (eventName.indexOf(`${EVENT_NAME.UPDATE_ROOM}:`) === 0) {
        eventName = EVENT_NAME.UPDATE_ROOM
      }
      callbackFunc(eventName, data)
    })
  }

  isConnect() {
    return this.socket.connected
  }

  connect() {
    this.socket.connect()
  }

  disconnect() {
    this.socket.disconnect()
  }

  init() {
    this.emit(EVENT_NAME.INIT, {
      auth: {
        isAdmin: false,
        token: this.token,
        userId: this.userId,
        userNick: this.userNickname,
      },
      roomId: this.roomId,
    })
  }

  emit(eventName: string, params: object) {
    this.socket.emit(eventName, params)
  }

  emitGetRoom() {
    this.socket.emit(EVENT_NAME.GET_ROOM, { roomId: this.roomId })
  }
  emitGetMessages(prefix: string, sort = false) {
    this.emit(EVENT_NAME.GET_MESSAGES, {
      prefix,
      roomId: this.roomId,
      sort,
    })
  }

  emitSendLike(count: number) {
    this.socket.emit(EVENT_NAME.SEND_LIKE, {
      roomId: this.roomId,
      value: count,
    })
  }

  emitSendMessage(message: string) {
    this.emit(EVENT_NAME.SEND_MESSAGE, {
      input: {
        data: DATA_NONE,
        isLive: true,
        message,
        messageType: TYPE_MESSAGE.USER,
        partnerId: this.partnerId,
        roomId: this.roomId,
        userId: this.userId,
        userName: this.userId,
        userNick: this.userNickname,
      },
      isAdmin: false,
      roomId: this.roomId,
    })
  }
}

export const DATA_NONE = 'none'
