import React from 'react'
import { IoIosArrowRoundForward } from 'react-icons/io'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import {
  FocusableComponentLayout,
  FocusDetails,
  KeyPressDetails,
  useFocusable,
} from '@noriginmedia/norigin-spatial-navigation'
import i from 'libs/i18n'
import { getFocusKey } from 'libs/utils'

interface MoreButtonProps {
  size: {
    w: string
    h: string
    margin: string
  }
  path: { section: string; partnerId: string; broadcastId: string; index: number }
  onEnterPress: (props: object, details: KeyPressDetails) => void
  onFocus: (layout: FocusableComponentLayout, props: object, details: FocusDetails) => void
}

const MoreCard: React.FC<MoreButtonProps> = ({ size, path, onEnterPress, onFocus }) => {
  const focusKey = getFocusKey(path)
  const { ref, focused } = useFocusable({
    onEnterPress,
    onFocus,
    focusKey,
    extraProps: path,
  })
  return (
    <MoreButtonWrapper ref={ref} isFocused={focused} size={size}>
      <div>{i.t('main_page.more')}</div>
      <IoIosArrowRoundForward className="ionIcons" size={60} />
    </MoreButtonWrapper>
  )
}

interface MoreButtonStyle {
  isFocused: boolean
  size: {
    w: string
    h: string
    margin: string
  }
}

const MoreButtonWrapper = styled.div<MoreButtonStyle>`
  box-sizing: border-box;
  padding-top: 10px;
  min-width: 248px;
  min-height: 254px;
  width: 248px;
  height: 254px;
  margin: 0 40px 0 20px;
  border-radius: 5px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  color: #aaa;
  font-size: 40px;
  font-weight: 600;
  padding-bottom: 10px;
  border: 10px solid #16161a;

  ${({ size }) =>
    size.h &&
    size.w &&
    size.margin &&
    css`
      min-width: ${size.w};
      min-height: ${size.h};
      width: ${size.w};
      height: ${size.h};
      margin: ${size.margin};
    `}

  ${({ isFocused }) =>
    isFocused &&
    css`
      &::after {
        content: '';
        position: absolute;
        display: block;
        width: calc(100%);
        height: calc(100%);
        border: 10px solid #fff;
        border-radius: 20px;
        z-index: 10;
      }
    `}
`

export default MoreCard
