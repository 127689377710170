import { broadcastAPI } from './axios'

const NEW_API = {
  getPartnerListAPI: async () => {
    const url = '/internal/partners/tv'
    const res = await broadcastAPI.get(url)
    return res.data.response
  },
  getBroadcastListAPI: async ({
    order = 'desc',
    partnerId,
    returnLimit = 10,
    isScheduled = false,
  }: {
    order?: 'desc' | 'asc'
    partnerId?: string | null
    returnLimit?: number
    isScheduled?: boolean
  }) => {
    const url = `/internal/broadcasts/tv?${
      partnerId ? `partnerId=${partnerId}` : ''
    }&returnLimit=${returnLimit}&scheduled=${isScheduled}&orderBy=${order}`
    const res = await broadcastAPI.get(url)
    return res.data.response
  },
}

export default NEW_API
