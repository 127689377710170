import React from 'react'
import { useSelector } from 'react-redux'
import styled from '@emotion/styled'
import i from 'libs/i18n'
import { RootState } from 'libs/store'

const PolicyContainer: React.FC = () => {
  const isShowPolicy = useSelector((state: RootState) => state.interaction.isShowPolicy)

  if (!isShowPolicy) return null
  return (
    <PolicyContainerWrap>
      <PolicyText>{i.t('main_page.policy_context')}</PolicyText>
    </PolicyContainerWrap>
  )
}

const PolicyContainerWrap = styled.div`
  display: block;
  padding: 9px 20px;
  background-color: #777;
  max-height: 300px;
  transition: max-height 0.3s;
`

const PolicyText = styled.p`
  font-size: 22px;
  line-height: 36px;
  color: #fff;
  word-break: keep-all;
  text-align: center;
  letter-spacing: -1.85px;
`

export default PolicyContainer
