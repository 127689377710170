import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TYPE_MESSAGE } from 'libs/@types/enums'
import type HlsPlayer from 'libs/hls/player'
import i from 'libs/i18n'
import SauceflexSocketSDK from 'libs/sauceflexSocketSDK'
import { convertSystemMsgToGlobalLang } from 'libs/utils'

interface InitialState {
  chatList: Message[]
  currentNotice: Message | null
  incomingMessage: string | null
  insertId: string | null
  isPlay: boolean | null
  isReady: boolean
  isSeekPlayer: boolean
  isShowPoster: boolean
  noticeList: Message[]
  replyList: Reply[]
  player: HlsPlayer | null
  sauceflexSocketSDK: SauceflexSocketSDK | null
  selectedBroadcast: Broadcast | null
}

const initialState: InitialState = {
  chatList: [],
  currentNotice: null,
  incomingMessage: null,
  insertId: null,
  isPlay: null,
  isReady: false,
  isSeekPlayer: false,
  isShowPoster: false,
  noticeList: [],
  replyList: [],
  player: null,
  sauceflexSocketSDK: null,
  selectedBroadcast: null,
}

const playerStore = createSlice({
  name: 'player',
  initialState,
  reducers: {
    INCREASE_LIKE_COUNT(state, { payload }: PayloadAction<number>) {
      if (!state.selectedBroadcast) return
      if (!state.selectedBroadcast.likeCount) {
        state.selectedBroadcast.likeCount = 0
      }
      state.selectedBroadcast.likeCount += payload
    },
    INIT_NOTICE(
      state,
      {
        payload: { messages },
      }: PayloadAction<{
        messages: Message[]
      }>,
    ) {
      state.noticeList = messages.filter(
        ({ messageType }) => messageType === TYPE_MESSAGE.NOTICE_SHOW,
      )

      const currentNotice = messages[0]
      if (currentNotice?.messageType === TYPE_MESSAGE.NOTICE_SHOW) {
        state.currentNotice = currentNotice
      }
      if (currentNotice?.messageType === TYPE_MESSAGE.NOTICE_HIDE) {
        state.currentNotice = null
      }
    },
    INIT_REPLY(
      state,
      {
        payload: { messages },
      }: PayloadAction<{
        messages: Message[]
      }>,
    ) {
      state.replyList = messages.reduce((replyList: Reply[], message) => {
        const { data, messageId, regDate, userNick } = message
        if (!data || data === 'none') return replyList

        let reply
        try {
          reply = JSON.parse(data.replaceAll('\n', '\\n'))
          reply.regDate = regDate
          reply.messageId = messageId
          reply.adminNickName = userNick
          return [...replyList, reply]
        } catch (e) {
          return replyList
        }
      }, [])
    },
    REQ_GET_SELECTED_BROADCAST(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { payload: { broadcastId: _broadcastId } }: PayloadAction<{ broadcastId: string }>,
    ) {
      state.selectedBroadcast = null
    },
    RES_GET_SELECTED_BROADCAST(state, { payload }: PayloadAction<Broadcast>) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      state.selectedBroadcast = payload as any
    },
    RESET_SELECTED_BROADCAST(state) {
      state.chatList = []
      state.currentNotice = null
      state.incomingMessage = null
      state.insertId = null
      state.noticeList = []
      state.replyList = []
      state.sauceflexSocketSDK?.disconnect()
      state.sauceflexSocketSDK = null
      state.selectedBroadcast = null
    },
    SET_IS_PLAY(state, { payload }: PayloadAction<boolean>) {
      state.isPlay = payload
    },
    SET_IS_READY(state, { payload }: PayloadAction<boolean>) {
      state.isReady = payload
    },
    SET_IS_SEEK_PLAYER(state, { payload }: PayloadAction<boolean>) {
      state.isSeekPlayer = payload
    },
    SET_PLAYER(state, { payload }: PayloadAction<InitialState['player']>) {
      state.player = payload
    },
    SET_SOCKET(state, { payload }) {
      state.sauceflexSocketSDK = payload
    },
    SET_IS_SHOW_POSTER(state, { payload }: PayloadAction<boolean>) {
      state.isShowPoster = payload
    },
    UPDATE_CHAT(state, { payload }: PayloadAction<Message>) {
      payload =
        payload.messageType === TYPE_MESSAGE.ADMIN ? convertSystemMsgToGlobalLang(payload) : payload

      state.chatList?.push(payload)
      if (state.chatList.length > 20) {
        state.chatList = state.chatList.slice(-15)
      }
    },
    UPDATE_CHAT_LIST(
      state,
      {
        payload: { messages, noReverse = false },
      }: PayloadAction<{
        messages: Message[]
        noReverse?: boolean
      }>,
    ) {
      const orderedMessages = !noReverse ? [...messages].reverse() : [...messages]
      state.chatList = orderedMessages.length > 20 ? orderedMessages.slice(-15) : orderedMessages
      state.chatList = state.chatList.map((message) =>
        message.messageType === TYPE_MESSAGE.ADMIN
          ? convertSystemMsgToGlobalLang(message)
          : message,
      )
    },
    UPDATE_INSERT(state, { payload }: PayloadAction<string | null>) {
      state.insertId = payload
    },
    UPDATE_NOTICE(
      state,
      {
        payload: { message },
      }: PayloadAction<{
        message: Message
      }>,
    ) {
      const { data, messageType } = message

      if (messageType === TYPE_MESSAGE.NOTICE_SHOW) {
        if (!data || data === 'none') return
        state.noticeList = [message, ...state.noticeList]
        state.currentNotice = message
      }
      if (messageType === TYPE_MESSAGE.NOTICE_HIDE) {
        state.currentNotice = null
      }
    },
    UPDATE_REPLY(
      state,
      {
        payload: {
          message: { data, messageId, regDate, userNick },
        },
      }: PayloadAction<{
        message: Message
      }>,
    ) {
      if (!data || data === 'none') return
      try {
        const recentReply = JSON.parse(data.replaceAll('\n', '\\n'))
        recentReply.regDate = regDate
        recentReply.messageId = messageId
        recentReply.adminNickName = userNick
        state.replyList = [recentReply, ...state.replyList]
      } catch (e) {
        return
      }
    },
    UPDATE_ROOM(
      state,
      { payload: { roomData, isVod } }: PayloadAction<{ roomData: Room; isVod?: boolean }>,
    ) {
      if (!state.selectedBroadcast) return

      state.selectedBroadcast['canChat'] = !isVod ? roomData.canChat : true
      state.selectedBroadcast['canChat'] = !isVod ? roomData.canChat : true
      state.selectedBroadcast['viewCount'] = roomData.incomingCounterInfo
      state.selectedBroadcast['likeCount'] = roomData.reactionCounterInfo
    },
    UPDATE_ROOM_INTERVAL(
      state,
      {
        payload: { room },
      }: PayloadAction<{
        room: RoomInterval
      }>,
    ) {
      if (!state.selectedBroadcast) return

      const diffCount = room.incomingCounterInfo - (state.selectedBroadcast.viewCount ?? 0)
      if (diffCount > 1) {
        state.incomingMessage = i.t('entered_with_nickname_other', {
          nickname: room.userNick,
          other: diffCount - 1,
        })
      } else if (diffCount === 1) {
        const nickname = room.userNick
        state.incomingMessage = i.t('entered_with_nickname', {
          nickname,
        })
      }

      if (room?.banUserInfo?.userId) {
        state.selectedBroadcast['bannedUserIdList'] = room.banUserInfo.userId
      }
      state.selectedBroadcast['canChat'] = room.canChat
      state.selectedBroadcast['chatCount'] = room.chatCounterInfo
      state.selectedBroadcast['likeCount'] = room.reactionCounterInfo
      state.selectedBroadcast['viewCount'] = room.incomingCounterInfo
    },
  },
})

export const player = playerStore.reducer
export const {
  INCREASE_LIKE_COUNT,
  INIT_NOTICE,
  INIT_REPLY,
  REQ_GET_SELECTED_BROADCAST,
  RES_GET_SELECTED_BROADCAST,
  RESET_SELECTED_BROADCAST,
  SET_PLAYER,
  SET_SOCKET,
  SET_IS_PLAY,
  SET_IS_READY,
  SET_IS_SEEK_PLAYER,
  SET_IS_SHOW_POSTER,
  UPDATE_CHAT,
  UPDATE_CHAT_LIST,
  UPDATE_INSERT,
  UPDATE_NOTICE,
  UPDATE_REPLY,
  UPDATE_ROOM,
  UPDATE_ROOM_INTERVAL,
} = playerStore.actions
