import React from 'react'
import styled from '@emotion/styled'
import useBroadcastStatus from 'libs/hooks/useBroadcastStatus'
import i from 'libs/i18n'

interface Props {
  broadcastName?: string
  partnerId: string | null
}
const ChatTopTitle: React.FC<Props> = ({ broadcastName, partnerId }) => {
  const { isLive } = useBroadcastStatus()
  return (
    <ChatTopTitleWrapper>
      <div className="videoInfo">
        {isLive ? <span className="videoLive">LIVE</span> : <span className="videoVod">VOD</span>}
        <span className="videoBrandName">{i.t(`common_brand.${partnerId}`)}</span>
        <span className="videoName">{broadcastName}</span>
      </div>
    </ChatTopTitleWrapper>
  )
}

const ChatTopTitleWrapper = styled.div`
  display: inline;
  flex-direction: column;
  padding-left: 20px;
  height: 100%;
  width: calc(100% - 108px);

  .videoInfo {
    display: inline;
    flex-direction: row;
    align-items: center;

    span {
      display: inline-block;
      align-items: center;
      padding: 5px 12px;
      border-radius: 18px;
      margin-right: 4px;
      font-size: 18px;
      color: #fff;
      letter-spacing: 5%;
      font-weight: 900;

      &.videoLive {
        background-color: #ff0000;
      }

      &.videoVod {
        background-color: #777;
      }
    }

    .videoBrandName {
      display: inline;
      padding: 0 10px;
      font-size: 27px;
      font-weight: 500;
      color: #555555;
      line-height: 50px;
      white-space: nowrap;
    }

    .videoName {
      display: inline;
      color: #000;
      font-weight: 600;
      font-size: 30px;
      line-height: 36px;
    }
  }
`

export default ChatTopTitle
