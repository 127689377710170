import axios, { AxiosResponse } from 'axios'

interface IpData {
  country: string // 'KR'
  ip: string // '15.164.19.90'
}

const getIpAddress = async () => {
  const response: AxiosResponse<IpData, unknown> = await axios.get('https://api.country.is')
  return response
}

export default getIpAddress
