import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import {
  FocusableComponentLayout,
  FocusDetails,
  KeyPressDetails,
  setFocus,
  updateAllLayouts,
  useFocusable,
} from '@noriginmedia/norigin-spatial-navigation'
import i from 'libs/i18n'

interface Languages {
  originIpValue: string
  langValue: string
  langText: string
}

interface ItemProps {
  languages: Languages[]
  nowLanguage: string
  isSelectorOpen: boolean
  setLanguage: Dispatch<
    SetStateAction<{ originIpValue: string; langValue: string; langText: string }>
  >
  setIsSelectorOpen: Dispatch<SetStateAction<boolean>>
  onEnterPress: (props: object, details: KeyPressDetails) => void
}

const LanguageSelector: React.FC<ItemProps> = ({
  languages,
  nowLanguage,
  isSelectorOpen,
  setIsSelectorOpen,
  setLanguage,
  onEnterPress,
}) => {
  const [nowFocusLang, setNowFocusLang] = useState<string>(nowLanguage)

  const onLiFocus = ({ node }) => {
    setNowFocusLang(node.children[0].innerText)
  }

  const onArrowPress = (direction) => {
    if (direction === 'down' && nowFocusLang === languages[languages.length - 1].langText) {
      return false
    }
    return true
  }

  const { ref, focused } = useFocusable({
    onEnterPress,
    onArrowPress,
    focusKey: 'setting-language',
    extraProps: {
      section: 'setting-language',
      partnerId: '',
      broadcastId: '',
    },
  })

  const onLiEnterPress = useCallback(() => {
    const selectedLanguage = languages.filter(({ langText }) => langText === nowFocusLang)
    setLanguage(selectedLanguage[0])
    window.localStorage.setItem('userSelectedLanguage', selectedLanguage[0].langValue)
    i.changeLanguage(selectedLanguage[0].langValue)
    setIsSelectorOpen(false)
    setFocus('setting-language')
  }, [languages, nowFocusLang, setLanguage, setIsSelectorOpen])

  useEffect(() => {
    if (!isSelectorOpen) return
    updateAllLayouts()
    setFocus(`language-${languages[0].langText}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelectorOpen])

  return (
    <SelectLangWrap ref={ref} isFocused={focused}>
      <SelectLang>{nowLanguage}</SelectLang>

      {isSelectorOpen && (
        <ul>
          {languages.map((language) => (
            <ListItem
              key={language?.langText}
              langText={language?.langText}
              onFocus={onLiFocus}
              onArrowPress={onArrowPress}
              onEnterPress={onLiEnterPress}
            />
          ))}
        </ul>
      )}
    </SelectLangWrap>
  )
}

export default LanguageSelector

interface ListItem {
  langText: string
  onEnterPress: (props: object, details: KeyPressDetails) => void
  onFocus: (layout: FocusableComponentLayout, props: object, details: FocusDetails) => void
  onArrowPress?: (direction: string) => boolean
}

const ListItem: React.FC<ListItem> = ({ langText, onEnterPress, onFocus, onArrowPress }) => {
  const { ref, focused } = useFocusable({
    focusKey: `language-${langText}`,
    onEnterPress,
    onFocus,
    onArrowPress,
  })

  return (
    <SelectLangLi ref={ref} isFocused={focused}>
      <span>{langText}</span>
    </SelectLangLi>
  )
}

const SelectLangWrap = styled.div<{ isFocused: boolean }>`
  display: block;
  position: relative;
  border: 7px solid transparent;
  box-sizing: border-box;
  border-radius: 10px;

  ${({ isFocused }) =>
    isFocused &&
    css`
      border: 7px solid rgb(255, 255, 255);
    `}

  & ul {
    position: absolute;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 100%;
    margin-top: 4px;
    padding: 8px 0;
    background-color: #fff;
    border-radius: 4px;
    opacity: 0;
    padding: 5px;
    opacity: 1;
    transform: translateX(-50%);
  }
`

const SelectLangLi = styled.li<{ isFocused: boolean }>`
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #777;
  font-size: 22px;
  letter-spacing: -1px;
  ${({ isFocused }) =>
    isFocused &&
    css`
      background-color: #000;
      color: #fff;
    `}
`
const SelectLang = styled.div`
  position: relative;
  width: 186px;
  height: 45px;
  border-radius: 6px;
  border: 2px solid #5e5e5e;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 24px;
  line-height: 19px;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    left: 6px;
    top: 50%;
    transform: translateY(-50%);
    width: 27px;
    height: 27px;
    display: block;
    background-image: url('https://d2kkk8cefusmqi.cloudfront.net/icons/Icon_global.svg');
    background-size: contain;
  }
  &:after {
    content: '';
    position: absolute;
    right: 4px;
    top: 56%;
    transform: translateY(-50%);
    width: 36px;
    height: 36px;
    display: block;
    background-image: url('https://d2kkk8cefusmqi.cloudfront.net/icons/ri_arrow-up-s-fill.svg');
    background-size: contain;
  }
`
