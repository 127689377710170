import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ENV from 'libs/config'
import { RootState } from 'libs/store'
import { SET_IS_SHOW_POSTER, UPDATE_CHAT_LIST } from 'libs/store/player/player.store'
import useBroadcastStatus from './useBroadcastStatus'

type CustomMessageType = Message
interface CustomMessage extends CustomMessageType {
  _regDate: number
}

export function useVodChat() {
  const selectedBroadcast = useSelector((state: RootState) => state.player.selectedBroadcast)
  const broadcastStartDt = selectedBroadcast?.broadcastStartDt
  const partnerId = selectedBroadcast?.partnerId
  const roomId = selectedBroadcast?.roomId

  const { isVod } = useBroadcastStatus()

  const [messageList, setMessageList] = useState<CustomMessage[]>([])

  const dispatch = useDispatch()

  /*
   * 메세지 목록 초기화 useEffect
   */
  useEffect(() => {
    if (!isVod || !broadcastStartDt) return

    const broadcastStartTime = new Date(broadcastStartDt).getTime()

    fetch(ENV.S3_URL + `/vodChatMessage/${partnerId}/${roomId}/messages.json`, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then(async (result) => {
        const message: CustomMessage[] = await Promise.all(
          result.map((x: { key: string }) => fetch(ENV.S3_URL + '/' + x.key).then((x) => x.json())),
        )
        const parsedMessage = message.flat().map((message) => {
          const regDate = new Date(message?.regDate.split('#')[1] || 0).getTime()
          return { ...message, _regDate: regDate - broadcastStartTime } as CustomMessage
        })

        setMessageList(parsedMessage)
      })
      .catch(() => {})
  }, [broadcastStartDt, isVod, partnerId, roomId, dispatch])

  const handleUpdateMessage = useCallback(() => {
    const videoEl = document.querySelector('video') as HTMLVideoElement

    if (!videoEl) return
    const parsedPlayerTime = videoEl.currentTime * 1000
    const updateMessageList = messageList.filter(
      (messageItem) => messageItem._regDate < parsedPlayerTime,
    )

    dispatch(
      UPDATE_CHAT_LIST({
        messages: updateMessageList,
        noReverse: true,
      }),
    )
    const hasConvertMessage = updateMessageList.some(
      (messageItem) =>
        messageItem.message === '라이브가 종료되어 VOD로 전환중입니다. ' ||
        messageItem.message === '라이브가 종료되어 VOD로 전환중입니다.' ||
        messageItem.message === 'MSG_CatchUpVODConverting',
    )
    dispatch(SET_IS_SHOW_POSTER(hasConvertMessage))
  }, [messageList, dispatch])

  /*
   * 주기적으로 메시지 갱신 useEffect
   */
  useEffect(() => {
    if (!isVod) return

    const intervalId = setInterval(handleUpdateMessage, 1000)

    return () => {
      clearInterval(intervalId)
    }
  }, [isVod, handleUpdateMessage])
}

export default useVodChat
