const zhTw = {
  date_format: {
    date: '{{year}}年 {{month}}月 {{day}}日',
    schedule_time: '{{time,schedule_time}}',
  },

  time: {
    am: '午前',
    pm: '午後',
    today: '今天',
    tomorrow: '明天',
    hour: '{{hour}}时',
    hour_minute: '{{hour}}:{{minute}}',
  },

  unit: {
    hundred_million: '億',
    issue_count: '{{count}}張',
    ten_thousand: '萬',
    thousand: '千',
    won: '₩{{price}}',
  },

  add: '添加',
  add_banned_terms: '請輸入预設定的敏感辭彙。',
  administrator_commented: '管理員已評論了您的聊天信息。',
  administrator_froze_chat: '管理員已凍結該對話框。\n僅限管理員參與該聊天。',
  administrator_not_issue_coupons: '管理員無法發送優惠券。',
  administrator_unfroze_chat: '管理員已恢復該對話框。\n您可以參與該聊天。',
  age: '年齡',
  agree_personal_info: '同意收集與使用個人信息',
  alarm_notifications: '接收直播提醒',
  all_coupon_issued: 'All coupon has been issued.',
  already_added_banned_term: '該敏感辭彙已存在。',
  auto_report_due_to_spam: '因刷屏而被系統舉報',
  available_until_period: '截至{{period}}可用',
  banned_terms: '敏感辭彙',
  banned_terms_added: '已添加至實時敏感辭彙列表。',
  banned_terms_deleted: '已從實時敏感辭彙列表中刪除。',
  banned_terms_included: '聊天內容裡包含了敏感辭彙。',
  banned_terms_only_applicable_to_live: '* 額外設定的敏感辭彙僅適用於該直播。',
  broadcast_alarm_notified: '直播開始時將為您發送提醒。',
  broadcast_alarm_success: '已設置直播提醒。',
  broadcast_disconnected: '當前直播信號已斷開。請稍等。',
  broadcast_ended: '直播已結束。',
  broadcast_notice: '直播通知',
  broadcast_restored: '直播信號已恢復正常。',
  broadcast_schedule_can_be_changed: '直播時間可能因公司內部的實際情況而發生變化。',
  broadcast_started: '直播已開始。',
  broadcast_unstable: '啊！直播狀態卡頓不流暢。',
  buttons_at_bottom_right: '※ 右側最下方按鈕 > 直播評論標籤',
  cancel: '取消',
  cancel_notifications: '您要取消直播提醒嗎？',
  cancel_to_register_purchase_auth_winner: '確定要取消添加中獎者信息嗎？',
  canceled_broadcast: 'This broadcast has been cancelled.\nSee you in the next LIVE.',
  cannot_add_sold_out_to_wish_list: '無法收藏已售罄的商品。',
  cannot_be_replayed: '該直播不提供重播服務。',
  cannot_edit_after_sign_in: '登錄後不可對上述信息進行修改。',
  cannot_edited_after_sent: '無法刪除、修改已發送的回覆，請仔細確認後進行發送。',
  change_nickname: '更改聊天昵稱',
  change_to_portrait: `Sorry, We doesn't support landscape mode.`,
  chat_report: 'Report',
  chat_restrict: '禁言',
  chat_restrict_included: '包括禁言',
  chat_restricted_by_administrator: '您已被管理員禁言。',
  chat_restriction: '禁言',
  chat_warning: '使用不文明語言時將無法正常使用服務。',
  chatroom_frozen: '該聊天室已被凍結。',
  check_broadcast_alarm_notifications: '您要接收直播提醒嗎？',
  check_code: '請確認入場代碼。',
  click_to_play_info_video: 'Click here to play the instructional video.',
  close: '關閉',
  confirm: '確認',
  congratulations_on_winning: '恭喜您中獎了。',
  connection_unstable: '啊！連接卡頓不流暢。\n請稍等。',
  contact_exclude_dashes: '聯繫方式（不填寫-）',
  converting_to_vod: '直播已結束，正在轉換為VOD模式。',
  copy: '複製',
  coupon: '優惠劵',
  coupon_issued: '已發送優惠券。',
  coupon_received: '已領取優惠券',
  current_nickname: '當前昵稱',
  days_ago: '天前',
  delete: '刪除',
  delete_comment: 'Delete comment',
  delete_report: '從舉報列表中刪除',
  deleted_comment_no_revert: 'Unable to revert deleted comments.',
  discount: '優惠',
  do_cancel: '取消',
  do_copy: '複製',
  do_delete: '刪除',
  do_pin: 'Pin',
  do_report: '舉報',
  download: '下載',
  edit: '修改',
  edit_comment: '修改我的評論',
  email: '電子郵箱',
  enter_at_lease_one: '請輸入至少1個字。',
  enter_at_least_3_purchase_number: '請輸入超過3位數的購買號碼',
  enter_banned_terms: '請輸入要設定的敏感辭彙。',
  enter_chat: '參與聊天',
  enter_code: '請輸入{{codeLength}}位的入場代碼。',
  enter_contact: '請輸入聯繫方式。',
  enter_contact_number_or_email: '輸入聯繫方式或電子郵箱地址（未輸入時不顯示）',
  enter_email: '請輸入電子郵箱地址。',
  enter_max_15_chars: 'Enter up to 15 characters',
  enter_max_40_chars: '最多可輸入40個字符',
  enter_name: '請輸入姓名。',
  enter_nickname: '請輸入昵稱。',
  enter_purchase_authentication_winner: '輸入購買認證的中獎者',
  enter_purchase_number: '請輸入購買（訂單）號碼。',
  enter_reply: '請輸入回覆。',
  enter_same_message_consecutively: '無法連續發送相同的聊天內容。',
  enter_short_time_consecutively: '無法在短時間內連續發送聊天內容。',
  enter_too_many_message: '因聊天內容發送過於頻繁，{{sec}}秒內無法輸入內容。',
  enter_without_space: '請勿輸入空格。',
  entered_with_nickname: '{{nickname}}成功入場。',
  entered_with_nickname_other: '{{nickname}}與其他{{other}}位成功入場。',
  event_auto_exit_on_vod: '轉換為VOD模式時，活動將自動結束。',
  event_can_start_anytime_on_live: 'LIVE中可以隨時開始活動。',
  event_management: '活動管理',
  event_start: '活動開始',
  exit: '離開',
  expose_with_masking_and_max: '(最多50名，部分信息將通過（*）進行保密處理。)',
  failed_issue_coupon: '優惠券發送失敗。',
  failed_to_enter_message: '聊天內容輸入失敗。',
  failed_to_enter_message_with_error:
    '聊天內容輸入失敗。 \n錯誤：[無法找到該登錄信息。 \n請重新登錄。]',
  female: '女性',
  fill_out_form_to_chat: '参与聊天前請輸入以下信息。',
  freeze_chat: '凍結聊天',
  full: '觀看視頻',
  harassment_abuse: '嘲弄或是欺凌',
  hide_comments: '隱藏評論',
  highlight: '精彩片段',
  hours_ago: '小時前',
  info_area: '通知事項內容',
  info_image: '通知圖片',
  info_image_displayed: '上傳的通知圖片將對觀眾可見。',
  information_message_to_winner: '中獎相關信息',
  intro: '소개',
  invalid_email_format: '電子郵件的格式錯誤。',
  invalid_phone_format: '聯繫方式格式錯誤。請以01012345678的格式輸入。',
  is_where_see_all_comments: '可在{{Location}}查看管理員的全部評論。',
  issue_during_live: '僅可在直播時發放',
  join_broadcast: '參與直播',
  join_chat_real_time: '請嘗試實時聊天。',
  join_secret: '進入Secret直播',
  leave_broadcast: '確定要離開直播嗎？',
  live_comment: 'LIVE評論',
  live_comments_tab: 'LIVE評論標籤',
  live_intro: 'LIVE介紹',
  live_notice: 'LIVE通知',
  male: '男性',
  mins_ago: '分鐘前',
  more_comments: `查看其他{{count}}個評論`,
  mr: '<bold>{{name}}<bold>',
  must_agree_collect_use_info: '必須同意收集與使用您的個人信息。',
  must_select_all_option: '必須選擇所有可選項。',
  name: '姓名',
  new_nickname: '新昵稱',
  nickname: '昵稱',
  no: '否',
  no_content: '沒有內容。',
  no_coupon: '沒有可以使用的優惠券。\n請等待下期優惠券！',
  no_intro: '沒有內容。',
  no_notice: '沒有內容。',
  no_products_in_wish_list: '沒有已收藏的商品。\n請在直播中收藏所需商品。',
  no_reply: '沒有內容。',
  no_shortcut: '沒有已添加的快捷短語。',
  none: '無',
  note: 'Attention',
  now: '不久前',
  obscene_message: '淫穢信息',
  obscenity_abuse: 'Sexual or bullying',
  only_possible_during_live: '僅在直播時可用。',
  optional: '可選項',
  other_inappropriate_language: '其他不得體發言',
  participate: 'Join',
  participate_success: '購買認證參與成功！',
  phone_call_only_mobile: 'Calls are only available on mobile.',
  phone_number: 'Call',
  pin_chat: '聊天置頂',
  pin_chat_to_top: 'Pin chat to the top?',
  please_enter_nickname: '請輸入昵稱。',
  please_input_accurately: '在有獎直播中獲獎時，將通過上述個人信息與您聯繫，請準確填寫。',
  price_differ_after_live: '直播結束後，價格與優惠可能會有所變化。',
  products: '出售商品',
  profanity_aggressive_expression: '侮辱或是歧視言論',
  purchase: '購買',
  purchase_auth_icon_exposed_if_event_on: '活動開始變為ON時，播放器內將顯示購買認證圖標。',
  purchase_auth_result_icon_exposed_if_announcement_on:
    '公布中獎者變為ON時，播放器內將顯示購買認證圖標。',
  purchase_authentication: '購買認證',
  purchase_authentication_event: '購買認證活動',
  purchase_authentication_event_winner: '購買認證活動中獎者',
  purchase_authentication_participant_info: '購買認證參與信息',
  push_notifications: 'PUSH推送通知',
  push_notifications_turned_off_in_my_lotte:
    'PUSH推送通知尚未開啟。\n請在「我的\n請在「我的Lotte>設置」中開啟PUSH推送通知。',
  real_time_chat: '實時聊天',
  reason_for_reporting: '舉報原因',
  receive_coupon: '領取優惠券',
  receive_notifications: '接收提醒',
  receive_notifications_of_broadcast: '接收直播提醒',
  receiving_notifications: '正在接收提醒',
  recent_shortcut: '最近添加的快捷短語',
  register: '上傳',
  register_broadcast_alarm: '申請直播提醒',
  register_notice_too: 'It is also added to the LIVE notice.',
  register_success: '已添加中獎者名單。',
  report_count: '舉報{{count}}',
  report_list: '舉報列表',
  report_submitted: '已受理舉報。',
  required: '必選',
  select_reason_for_reporting: '舉報原因',
  send: '發送',
  send_report: '受理舉報',
  service_restricted_with_false_report: '一經發現惡意舉報，將無法正常使用服務。',
  set_nickname: '設置昵稱',
  share_broadcast: '轉發直播',
  share_livestream: '轉發直播',
  shortcut: '快捷短語',
  shortcut_can_use_in_liveconsole: 'The shortcut feature is available in the Live console.',
  show_nickname_on_chat: '輸入聊天內容時將顯示昵稱。',
  sign_in: '登錄',
  sign_in_chat: '登陸聊天',
  sign_in_info_timed_out: '已完成登錄信息。 \n請重新登錄。',
  signed_in: '已成功以{{nickname}}登錄。',
  sold_out: '售罄',
  spam: '垃圾信息',
  speed: '倍速',
  stop: '停止導出',
  subscribe: '訂閱',
  subscribe_shop: `您要訂閱{{shopName}}\n的直播嗎？`,
  subscriber_with_count: '訂閱者{{count}}',
  subscribing: '訂閱中',
  touch_turn_on_sound: '按此處提高音量。',
  transmit: '導出',
  turn_on_notifications: '開啟提醒',
  unable_find_sign_in_path: '無法找到登錄路徑。',
  unfreeze_chat: '恢復聊天',
  unpin: '解除置頂',
  unpin_chat: 'Confirm',
  unpin_chat_to_top: 'Are you sure to unpin the chat?',
  unsubscribe_shop: `您要取消訂閱{{shopName}}\n的直播嗎？`,
  up_to_discount: '最多可獲得{{discount}}優惠',
  up_to_discount_upon_purchasing: '購買超過{{threshold}}時最多可獲得{{discount}}優惠',
  upon_purchasing: '購買超過{{threshold}}時',
  url_copied: '已成功複製URL地址。',
  verify: 'Apply',
  view_more: '查看更多',
  view_on_app: '用應用程序觀看',
  view_on_web: '用網頁觀看',
  view_terms: '查看條款',
  viewable_full_horizontal_video: '全屏模式在橫屏觀看橫屏視頻時可用。',
  wait_until_live_start: '直播開始後可以參與聊天。\n直播開始前请耐心等待。',
  winner_add: '輸入中獎者',
  winner_announcement: '公布中獎者',
  winner_announcement_can_control_after_add: '輸入中獎者信息後，可以ON或OFF公布中獎者功能。',
  winner_info_can_modify_after_vod: '轉換為VOD模式時也可以輸入或修改中獎者的信息。',
  winner_information: '中獎者信息',
  winner_management: '管理中獎者',
  wish_list: '收藏',
  wish_to_delete: '確定要刪除嗎？',
  wish_to_receive_notifications: '您要接收直播提醒嗎？',
  wish_to_sign_in: '確定要登錄嗎？',
  write_comments: '寫評論',
  writing_comments: '正在向{{nickname}}寫評論',
  yes: '是',
}

export default zhTw
