const KEYCODE = {
  BACK_SPACE: 8, // 키보드 백스페이스
  OK: 13,
  BACK: 10009,
  LEFT: 37,
  RIGHT: 39,
  UP: 38,
  DOWN: 40,
  REWIND: 412, // 뒤로가기
  FAST_FORWARD: 417, // 앞으로가기
  PAUSE: 19, // 일시정지
  PLAY: 415, // 재생
  STOP: 413, // 멈춤
  PLAY_AND_PAUSE: 10252, // 제셍 & 일시정지
}

export default KEYCODE
