import React from 'react'
import styled from '@emotion/styled'

interface Props {
  broadcastName?: string
  partnerProfileImageUrl?: string
}

const ChatTopBrandIcon: React.FC<Props> = ({ broadcastName, partnerProfileImageUrl }) => {
  return (
    <ChatTopBrandIconWrapper>
      <img src={`${partnerProfileImageUrl}?h=200&w=200&q=60&f=webp`} alt={broadcastName} />
    </ChatTopBrandIconWrapper>
  )
}

const ChatTopBrandIconWrapper = styled.div`
  display: flex;
  border-radius: 50%;
  width: 90px;
  min-width: 90px;
  height: 90px;
  min-height: 90px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export default ChatTopBrandIcon
