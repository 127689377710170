import React from 'react'
import { Outlet } from 'react-router-dom'
import GlobalContainer from 'containers/global/Global.container'
import HeaderContainer from 'containers/header/Header.container'
import PolicyContainer from 'containers/home/Policy.container'

const RouteWrapper: React.FC = () => {
  return (
    <>
      <PolicyContainer />
      <HeaderContainer />
      <main>
        <Outlet />
      </main>
      <GlobalContainer />
    </>
  )
}

export default RouteWrapper
