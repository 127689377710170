/* eslint-disable array-callback-return */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from '@emotion/styled'
import { pause } from '@noriginmedia/norigin-spatial-navigation'
import ENV from 'libs/config'
import KEYCODE from 'libs/constants/keycode'
import i from 'libs/i18n'
import { RootState } from 'libs/store'
import { getTimeDiffToString } from 'libs/utils'

interface Props {
  broadcastInfo: Broadcast | null
  isShow: boolean
}

const ChatLive: React.FC<Props> = ({ broadcastInfo, isShow }) => {
  const noticeList = useSelector((state: RootState) => state.player.noticeList)
  const replyList = useSelector((state: RootState) => state.player.replyList)

  const TAB_CONTENTS = ['introduction', 'notice', 'reply']

  const liveTabRef = useRef<(null | HTMLElement)[]>([])
  const modalLiveCloseRef = useRef<HTMLButtonElement>(null)
  const totalTabLength = TAB_CONTENTS.length
  const [focusedTabIndex, setFocusedTabIndex] = useState<number>(0) // 현재 포커스 인덱스

  const commentIntro = useMemo(() => {
    if (broadcastInfo) {
      if (broadcastInfo?.explanation === '') {
        return (
          <div className="introNoCont">
            <img
              className="introNoContImg"
              src={`${ENV.RESOURCE_URL}/icons/Icon_chat.svg?h=200&w=200&q=60&f=webp`}
              alt="NoText"
            />
            <br />
            <p className="introNoContTitle">{i.t('player_page.modal_live_intro_no_cont')}</p>
          </div>
        )
      } else {
        return (
          <div className="liveUnit">
            <div className="liveUnitCover">
              <h4 className="introTitle">{broadcastInfo?.broadcastName ?? ''}</h4>
              <br />
              <p className="introContext">
                <span>{broadcastInfo?.explanation ?? ''}</span>
              </p>
            </div>
          </div>
        )
      }
    }
  }, [broadcastInfo])

  const commentNotice = useMemo(() => {
    if (noticeList.length === 0) {
      return (
        <div className="introNoCont">
          <img
            className="introNoContImg"
            src={`${ENV.RESOURCE_URL}/icons/Icon_chat.svg?h=200&w=200&q=60&f=webp`}
            alt="NoText"
          />
          <p className="introNoContTitle">{i.t('player_page.modal_live_notice_no_cont')}</p>
        </div>
      )
    } else {
      return (
        <div className="liveNoticeHistoryListGroup">
          <div className="liveNoticeHistoryListFlow">
            {noticeList.map((notice) => (
              <div className="liveNoticeUnit" key={notice.messageId}>
                <h4 className="liveNoticeUnitText">{notice.data}</h4>
                <div className="liveNoticeUnitInfo">
                  <span className="liveNoticeUnitInfoTime">
                    {getTimeDiffToString(notice.regDate.split('#')[1])}
                  </span>
                  <span className="liveNoticeUnitInfoDot"></span>
                  <p className="liveNoticeUnitInfoAuth">{notice.userNick}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      )
    }
  }, [noticeList])

  const commentReply = useMemo(() => {
    if (replyList.length === 0) {
      return (
        <div className="introNoCont">
          <img
            className="introNoContImg"
            src={`${ENV.RESOURCE_URL}/icons/Icon_chat.svg?h=200&w=200&q=60&f=webp`}
            alt="NoText"
          />
          <br />
          <p className="introNoContTitle">{i.t('player_page.modal_live_reply_no_cont')}</p>
        </div>
      )
    } else {
      return (
        <div className="liveReplyHistoryListGroup">
          <div className="liveReplyHistoryListFlow">
            {replyList.map((reply) => (
              <div className="liveReplyUnit" key={reply?.messageId}>
                <h4 className="liveReplyUnitText chat">{reply?.chat}</h4>
                <h4 className="liveReplyUnitText reply">{reply?.reply}</h4>
                <div className="liveReplyUnitInfo">
                  <span className="liveReplyUnitInfoTime">
                    {getTimeDiffToString(reply.regDate.split('#')[1])}
                  </span>
                  <span className="liveReplyUnitInfoDot"></span>
                  <p className="liveReplyUnitInfoAuth">{reply.nickName}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      )
    }
  }, [replyList])

  useEffect(() => {
    if (isShow) {
      pause()
    } else {
      setFocusedTabIndex(0)
    }
  }, [isShow])

  /**
   * 최초진입시 세팅
   */
  useEffect(() => {
    setFocusedTabIndex(0)
  }, [])

  const handleKeyDownChatLive = useCallback(
    (e: KeyboardEvent) => {
      if (!isShow) return

      switch (e.keyCode) {
        case KEYCODE.LEFT:
          if (focusedTabIndex !== 0) {
            setFocusedTabIndex(focusedTabIndex - 1)
            liveTabRef.current[focusedTabIndex]?.classList.remove('focused')
            liveTabRef.current[focusedTabIndex - 1]?.classList.add('focused')
          }
          break
        case KEYCODE.RIGHT:
          if (focusedTabIndex < totalTabLength - 1) {
            setFocusedTabIndex(focusedTabIndex + 1)
            liveTabRef.current[focusedTabIndex]?.classList.remove('focused')
            liveTabRef.current[focusedTabIndex + 1]?.classList.add('focused')
          }
          break
        case KEYCODE.UP:
          modalLiveCloseRef.current?.classList.remove('focused')
          liveTabRef.current[focusedTabIndex]?.classList.add('focused')
          break
        case KEYCODE.DOWN:
          liveTabRef.current[focusedTabIndex]?.classList.remove('focused')
          modalLiveCloseRef.current?.classList.add('focused')
          break
      }
    },
    [focusedTabIndex, isShow, totalTabLength, setFocusedTabIndex],
  )

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDownChatLive)
    return () => {
      window.removeEventListener('keydown', handleKeyDownChatLive)
    }
  }, [handleKeyDownChatLive])

  return (
    <>
      {isShow && (
        <ChatLiveWrapper className={'popup_bg active'}>
          <div className="popup_bx">
            <div className="popupCloseSpace">
              <button className="popup_close"></button>
            </div>
            <div className="tabBtnSpace">
              <ul>
                <li ref={(el) => (liveTabRef.current[0] = el)} className="focused">
                  {i.t('player_page.modal_live_intro_tab')}
                </li>
                <li ref={(el) => (liveTabRef.current[1] = el)}>
                  {i.t('player_page.modal_live_notice_tab')}
                </li>
                <li ref={(el) => (liveTabRef.current[2] = el)}>
                  {i.t('player_page.modal_live_reply_tab')}
                </li>
              </ul>
            </div>
            <div className="tabContSpace">
              <div className={focusedTabIndex === 0 ? 'liveTabCont active' : 'liveTabCont'}>
                {commentIntro}
              </div>
              <div className={focusedTabIndex === 1 ? 'liveTabCont active' : 'liveTabCont'}>
                {commentNotice}
              </div>
              <div className={focusedTabIndex === 2 ? 'liveTabCont active' : 'liveTabCont'}>
                {commentReply}
              </div>
            </div>
            <button className="chatLiveClose" id="chatLiveCloseBtn" ref={modalLiveCloseRef}>
              {i.t('player_page.modal_close')}
            </button>
          </div>
        </ChatLiveWrapper>
      )}
    </>
  )
}
const ChatLiveWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: -1;
  opacity: 0;

  &.active {
    z-index: 10;
    opacity: 1;
    transition: opacity 0.5s;
  }

  .popup_bx {
    position: fixed;
    top: 50%;
    left: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    border-radius: 8px;
    width: 800px;
    height: auto;
    background-color: #fff;
    transform: translate(-50%, -50%);

    .popupCloseSpace {
      position: absolute;
      top: -50px;
      left: 50%;
      width: 40px;
      height: 40px;
      transform: translateY(-50%);
      background-image: url(icon/icon_close.svg);
      background-position: 50% 50%;
      opacity: 0;

      &.focused {
        background-color: #0c4da2;
      }

      &:focus {
        background-color: #0c4da2;
      }
    }

    .tabBtnSpace {
      width: 100%;

      ul {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        border-bottom: 1px solid #efefef;
        border-radius: 8px 8px 0 0;
        margin: 0;
        width: 100%;
        list-style: none;
        overflow: hidden;

        li {
          display: flex;
          justify-content: center;
          align-items: center;
          width: calc(100% / 3);
          height: 60px;
          font-size: 26px;
          font-weight: 600;

          &.focused {
            background-color: #0c4da2;
            color: #fff;
          }

          &:focus {
            background-color: #0c4da2;
            color: #fff;
          }
        }
      }
    }

    .tabContSpace {
      padding: 40px;
      width: 100%;
      height: 480px;

      .liveTabCont {
        display: none;
        height: 100%;

        &.active {
          display: block;
        }

        .liveUnit {
          height: 100%;
          overflow-y: hidden;
          $min-height: 420px;

          .liveUnitCover {
            height: auto;
            position: relative;
            overflow-y: auto;
            animation: 30s liveContentMoveVertical 5 3s linear;
          }

          .introTitle {
            font-size: 22px;
            white-space: pre-wrap;
            word-break: break-all;
            line-break: anywhere;
          }

          .introContext {
            font-size: 20px;
            white-space: pre-wrap;
            word-break: break-all;
            line-break: anywhere;
          }
        }
      }

      .liveNoticeHistoryListGroup {
        padding: 0px;
        width: 100%;
        height: 100%;
        overflow: hidden;

        .liveNoticeHistoryListFlow {
          animation: 30s liveContentMoveVertical 5 3s linear;

          .liveNoticeUnit {
            padding-bottom: 10px;

            .liveNoticeUnitText {
              padding-bottom: 10px;
              font-size: 22px;
            }

            .liveNoticeUnitInfo {
              display: flex;
              font-size: 20px;
              color: #b2b2b2;
              align-items: center;

              .liveNoticeUnitInfoDot {
                position: relative;
                display: block;
                width: 30px;
                height: 30px;

                &:after {
                  content: '';
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  border-radius: 50%;
                  width: 2px;
                  height: 2px;
                  background-color: #b2b2b2;
                  transform: translate(-50%, -50%);
                }
              }
            }
          }
        }
      }

      .liveReplyHistoryListGroup {
        padding: 0px;
        width: 100%;
        height: 100%;
        overflow: hidden;

        .liveReplyHistoryListFlow {
          width: 100%;
          animation: 30s liveContentMoveVertical 5 3s linear;

          .liveReplyUnit {
            padding-bottom: 20px;
            width: 100%;

            .liveReplyUnitText {
              position: relative;
              padding-bottom: 5px;
              padding-left: 40px;
              width: 100%;
              font-size: 20px;
              line-height: 30px;

              &.chat {
                color: #555;

                &:before {
                  content: 'Q';
                  position: absolute;
                  top: 3px;
                  left: 0;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border-radius: 50%;
                  width: 24px;
                  height: 24px;
                  font-size: 18px;
                  background-color: #555;
                  color: #fff;
                }
              }

              &.reply {
                &:before {
                  content: 'R';
                  position: absolute;
                  top: 3px;
                  left: 0;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 24px;
                  height: 24px;
                  border-radius: 50%;
                  font-size: 18px;
                  background-color: #000;
                  color: #fff;
                }
              }
            }

            .liveReplyUnitInfo {
              display: flex;
              align-items: center;
              padding-left: 40px;
              font-size: 20px;
              color: #b2b2b2;

              .liveReplyUnitInfoDot {
                position: relative;
                display: block;
                width: 30px;
                height: 30px;

                &:after {
                  content: '';
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  border-radius: 50%;
                  width: 2px;
                  height: 2px;
                  background-color: #b2b2b2;
                  transform: translate(-50%, -50%);
                }
              }
            }
          }
        }
      }

      .introNoCont {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;

        .introNoContImg {
          display: block;
          width: 80px;
          margin: 0 auto 20px;
          filter: invert(39.4%);
        }
        .introNoContTitle {
          font-size: 24px;
          color: #aeaeae;
        }
      }
    }
  }

  @keyframes liveContentMoveVertical {
    0% {
      transform: translateY(0px);
    }
    75% {
      transform: translateY(calc(-100% + 380px));
    }
    100% {
      transform: translateY(calc(-100% + 380px));
    }
  }

  .chatLiveClose {
    position: absolute;
    bottom: -80px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    border: 1px solid #0c4da2;
    border-radius: 30px;
    width: 250px;
    height: 60px;
    z-index: 22;
    font-size: 34px;
    font-weight: 600;
    background-color: #fff;
    color: #0c4da2;
    line-height: 36px;

    &.focused {
      background-color: #0c4da2;
      color: #fff;
    }
  }
`

export default ChatLive
