import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import {
  FocusableComponentLayout,
  FocusDetails,
  KeyPressDetails,
  useFocusable,
} from '@noriginmedia/norigin-spatial-navigation'
import Image from 'components/image/Image'
import { useIsImageLoaded } from 'libs/hooks/useIsImageLoaded'
import i from 'libs/i18n'
import { originCurrencyUnitFn, sellingCurrencyUnitFn } from 'libs/utils'

interface Props {
  index: number
  brandName?: string
  currencyId?: string
  discountRate?: string
  isSoldOut?: number
  price: string | number
  productId: string
  priceTypeCode: string
  productName: string
  sellingPrice: string
  thumbnailUrl: string
  redirectUrl: string
  onEnterPress: (props: object, details: KeyPressDetails) => void
  onFocus: (layout: FocusableComponentLayout, props: object, details: FocusDetails) => void
}

const Product: React.FC<Props> = ({
  index,
  brandName,
  currencyId,
  discountRate,
  isSoldOut,
  price,
  productId,
  productName,
  priceTypeCode,
  sellingPrice,
  redirectUrl,
  thumbnailUrl,
  onEnterPress,
  onFocus,
}) => {
  const { elementRef, isLoaded } = useIsImageLoaded()
  const sellingTextOrBlankUnit = useMemo(() => {
    if (!price || price === '0') {
      return <>&nbsp;</>
    }
    return <>{price}</> // 텍스트로 표현 바로가기
  }, [price])

  const { ref, focused } = useFocusable({
    onFocus,
    onEnterPress,
    focusKey: `product-${index}`,
    extraProps: {
      section: 'product',
      productId,
      modalData: {
        productName,
        redirectUrl,
        brandName,
        thumbnailUrl,
      },
    },
  })

  return (
    <div ref={ref}>
      <ProductWrapper
        ref={elementRef}
        className={focused ? 'productListBox focused' : 'productListBox'}
        data-index={index}
      >
        {index <= 5 && (
          <Image
            productName={productName}
            thumbnailUrl={`${thumbnailUrl}?h=150&w=150&f=webp&q=40`}
          />
        )}
        {index > 5 && isLoaded ? (
          <Image
            productName={productName}
            thumbnailUrl={`${thumbnailUrl}?h=150&w=150&f=webp&q=40`}
          />
        ) : (
          <>{!isLoaded && <StyledSkeletonImage />}</>
        )}
        <ProductInfo>
          <ProductTitle>
            <strong>{brandName}</strong>
            &nbsp;
            {productName}
          </ProductTitle>
          {/* 제품이 품절일 경우 */}
          {isSoldOut !== 0 && (
            <>
              <p className="originPrice">&nbsp;</p>
              <div className="discountInfo">
                <p className="discountPrice">{i.t('player_page.product_soldout')}</p>
              </div>
            </>
          )}
          {/* 제품이 품절이 아니고 가격 타입이 basic 일 경우 */}
          {isSoldOut === 0 && priceTypeCode === 'basic' && (
            <>
              {discountRate === '0' && (
                <>
                  <p className="originPrice">&nbsp;</p>
                  <div className="discountInfo">
                    <p className="discountPrice">
                      {originCurrencyUnitFn({ currencyId, price: sellingPrice })}
                    </p>
                  </div>
                </>
              )}
              {discountRate !== '0' && (
                <>
                  <p className="originPrice">
                    <del>{originCurrencyUnitFn({ currencyId, price: price.toString() })}</del>
                  </p>
                  <div className="discountInfo">
                    <p className="discountRate">{discountRate}%</p>
                    <p className="discountPrice">
                      {sellingCurrencyUnitFn({ currencyId, sellingPrice })}
                    </p>
                  </div>
                </>
              )}
            </>
          )}
          {/* 제품이 품절이 아니고 가격 타입이 basic 이 아닐경우('text' || 'hide') */}
          {isSoldOut === 0 && priceTypeCode !== 'basic' && (
            <>
              <p className="originPrice">&nbsp;</p>
              <div className="discountInfo">
                <p className="discountPrice">{sellingTextOrBlankUnit}</p>
              </div>
            </>
          )}
        </ProductInfo>
      </ProductWrapper>
    </div>
  )
}

const ProductWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  border: 1px solid #efefef;
  border-radius: 4px;
  margin-top: 10px;
  width: 100%;
  height: auto;
  background-color: #fff;

  &:first-of-type {
    margin-top: 20px;
  }

  &.focused {
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.4);
    transition: box-shadow 0.3s;
  }
`
const ProductInfo = styled.div`
  padding-left: 20px;

  .originPrice {
    margin-top: 20px;
    font-size: 23px;

    del {
      text-decoration: line-through;
    }
  }

  .discountInfo {
    display: flex;
    align-items: baseline;
    font-size: 31px;

    .discountRate {
      color: #ff3d33;
      padding-right: 10px;
    }

    .discountPrice {
      font-weight: 700;
      color: #333;
    }
  }
`
const ProductTitle = styled.h3`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
  text-overflow: ellipsis;
  color: #555;
  font-size: 25px;
  font-weight: 400;
  line-height: 1.2;
`

const StyledSkeletonImage = styled.div`
  width: 150px;
  height: 150px;
`

export default Product
