import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { KeyPressDetails, useFocusable } from '@noriginmedia/norigin-spatial-navigation'
import ENV from 'libs/config'
import i from 'libs/i18n'
import { RootState } from 'libs/store'

interface Props {
  broadcastInfo: Broadcast | null
  onPressEnter: (props: object, details: KeyPressDetails) => void
}

const ChatBanner: React.FC<Props> = ({ broadcastInfo, onPressEnter }) => {
  const partnerList = useSelector((state: RootState) => state.app.partnerList)
  const recentBroadcastList = useSelector((state: RootState) => state.app.recentBroadcastList)

  const [bannerInfo, setBannerInfo] = useState({
    type: '',
    thumbnailUrl: '',
    productName: '',
    redirectUrl: '',
    content: '',
  })

  const recentBroadcastIndex = useMemo(
    () =>
      recentBroadcastList?.findIndex(
        (recentBroadcastInfo) => recentBroadcastInfo.broadcastId === broadcastInfo?.broadcastId,
      ),
    [broadcastInfo, recentBroadcastList],
  )

  const partnerIndex = useMemo(
    () =>
      partnerList?.findIndex(
        (recentBroadcastInfo) => recentBroadcastInfo.partnerId === broadcastInfo?.partnerId,
      ),
    [broadcastInfo, partnerList],
  )

  const { ref, focused } = useFocusable({
    onEnterPress: onPressEnter,
    focusKey: 'banner',
    extraProps: {
      section: 'banner',
      partnerIndex,
      recentBroadcastIndex,
      url: bannerInfo.redirectUrl,
      thumbnailUrl: bannerInfo.thumbnailUrl,
      bannerId: broadcastInfo?.bannerList[0]?.bannerId ?? '',
    },
  })

  /**
   * default banner 판단 및 정보 가져오기
   */
  useEffect(() => {
    if (!broadcastInfo) return
    if (!broadcastInfo?.bannerList[0]) {
      setBannerInfo({
        type: 'default',
        thumbnailUrl: '',
        productName: '',
        redirectUrl: '',
        content: '',
      })
      return
    }

    if (broadcastInfo?.bannerList[0]?.bannerTypeCode === 'imagetext') {
      setBannerInfo({
        type: 'imagetext',
        thumbnailUrl: broadcastInfo?.bannerList[0]?.imageUrl,
        productName: broadcastInfo?.broadcastName,
        redirectUrl: broadcastInfo?.bannerList[0]?.link ?? `${broadcastInfo?.shortUrl}?fc=sauceTV`,
        content: broadcastInfo?.bannerList[0]?.content ?? '',
      })
      return
    }

    if (broadcastInfo?.bannerList[0]?.bannerTypeCode === 'image') {
      setBannerInfo({
        type: 'image',
        thumbnailUrl: broadcastInfo?.bannerList[0]?.imageUrl,
        productName: broadcastInfo?.broadcastName,
        redirectUrl: broadcastInfo?.bannerList[0]?.link ?? `${broadcastInfo?.shortUrl}?fc=sauceTV`,
        content: '',
      })
      return
    }

    if (broadcastInfo?.bannerList[0]?.bannerTypeCode === 'text') {
      setBannerInfo({
        type: 'text',
        thumbnailUrl:
          broadcastInfo?.productDetailList[0]?.productThumbnailUrlList[0]?.thumbnailUrl ?? '',
        productName: broadcastInfo?.broadcastName,
        redirectUrl: broadcastInfo?.bannerList[0]?.link ?? `${broadcastInfo?.shortUrl}?fc=sauceTV`,
        content: broadcastInfo?.bannerList[0]?.content ?? '',
      })
      return
    }
  }, [broadcastInfo])

  return (
    <ChatBannerWrapper ref={ref}>
      {bannerInfo.type === 'default' && (
        <DefaultBanner isFocused={focused} align={'gapBetween'}>
          <img
            src={`${ENV.RESOURCE_URL}/images/brand-logo/${broadcastInfo?.partnerId}.png`}
            alt={`${broadcastInfo?.partnerId} logo`}
            className="logo"
          />
          <p className="word">{i.t('player_page.banner_default_text')}</p>
          <img
            src={`${ENV.RESOURCE_URL}/icons/arrow_forward.svg?q=60&f=webp`}
            alt={'바로가기'}
            className="logo_arrow"
          />
        </DefaultBanner>
      )}
      {bannerInfo.type === 'image' && (
        <ImageBanner
          isFocused={focused}
          src={`${bannerInfo.thumbnailUrl}?q=60&w=558&f=webp`}
          alt={bannerInfo.productName}
          className="rounded"
        />
      )}
      {bannerInfo.type === 'imagetext' && (
        <DefaultBanner isFocused={focused}>
          <img
            src={bannerInfo.thumbnailUrl}
            alt={`${broadcastInfo?.partnerId} image`}
            className="logo"
          />
          <p className="word">{bannerInfo.content}</p>
        </DefaultBanner>
      )}
      {bannerInfo.type === 'text' && (
        <DefaultBanner isFocused={focused} align={'center'}>
          <p className="word">{bannerInfo.content}</p>
        </DefaultBanner>
      )}
    </ChatBannerWrapper>
  )
}

const ChatBannerWrapper = styled.div`
  border-radius: 14px;
  width: 100%;
`

const DefaultBanner = styled.div<{ isFocused: boolean; align?: string }>`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 14px;
  border: 2px solid #d5d5d5;
  padding: 4px;

  ${({ isFocused }) =>
    isFocused &&
    css`
      box-shadow: 0 0 12px rgba(0, 0, 0, 0.5);
    `}

  ${({ align }) =>
    align === 'center'
      ? css`
          justify-content: center;
        `
      : align === 'gapBetween'
      ? css`
          justify-content: space-between;
        `
      : ''}

  .logo {
    display: flex;
    height: 60px;
    width: 60px;
    border-radius: 10px;
    border: 2px solid #d5d5d5;
    margin-right: 10px;
  }

  .word {
    font-size: 26px;
  }

  .logo_arrow {
    padding: 14px;
  }
`

const ImageBanner = styled.img<{ isFocused: boolean }>`
  border-radius: 14px;

  ${({ isFocused }) =>
    isFocused &&
    css`
      transition: box-shadow 0.5s;
      box-shadow: 0 0 12px rgba(0, 0, 0, 0.5);
    `}
`

export default ChatBanner
