import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { SET_IS_ONLINE, SET_IS_VISIBLE } from 'libs/store/session/session.store'

const GlobalContainer: React.FC = () => {
  const dispatch = useDispatch()

  /**
   * 온라인 여부와 뷰포트 표시 여부 감지 이벤트 매핑 useEffect
   */
  useEffect(() => {
    const handleOnline = () => dispatch(SET_IS_ONLINE(true))
    const handleOffline = () => dispatch(SET_IS_ONLINE(false))
    const handleChangeVisibility = () =>
      dispatch(SET_IS_VISIBLE(document.visibilityState === 'visible'))

    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)
    window.addEventListener('visibilitychange', handleChangeVisibility)

    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
      window.removeEventListener('visibilitychange', handleChangeVisibility)
    }
  }, [dispatch])

  return <></>
}

export default GlobalContainer
