const ko = {
  date_format: {
    date: '{{year}}년 {{month}}월 {{day}}일',
    schedule_time: '{{time,schedule_time}}',
  },

  time: {
    am: '오전',
    pm: '오후',
    today: '오늘',
    tomorrow: '내일',
    hour: '{{hour}}시',
    hour_minute: '{{hour}}:{{minute}}',
  },

  unit: {
    hundred_million: '억',
    issue_count: '{{count}}장',
    ten_thousand: '만',
    thousand: '천',
    won: '{{price}}원',
  },

  add: '추가하기',
  add_banned_terms: '추가하고 싶은 금칙어를 입력하세요.',
  administrator_commented: '관리자가 답글을 남겼습니다.',
  administrator_froze_chat: '관리자가 채팅창을 얼렸습니다.\n관리자만 채팅에 참여할 수 있습니다.',
  administrator_not_issue_coupons: '관리자는 쿠폰 발급이 되지 않습니다.',
  administrator_unfroze_chat: '관리자가 채팅창을 녹였습니다.\n채팅에 참여하실 수 있습니다.',
  age: '나이',
  agree_personal_info: '개인정보 수집 및 이용 동의',
  alarm_notifications: '알림받기',
  all_coupon_issued: '모든 쿠폰이 발급되었습니다.',
  already_added_banned_term: '이미 등록된 금칙어입니다.',
  auto_report_due_to_spam: '도배로 인한 본인 자동 신고',
  available_until_period: '{{period}}까지 사용 가능',
  banned_terms: '금칙어',
  banned_terms_added: '실시간 금칙어 목록에 추가되었습니다.',
  banned_terms_deleted: '실시간 금칙어 목록에서 삭제되었습니다.',
  banned_terms_included: '채팅에 금칙어가 포함되어 있습니다.',
  banned_terms_only_applicable_to_live: '* 추가된 금칙어는 해당 라이브 방송에서만 적용됩니다.',
  broadcast_alarm_notified: '방송 시작시 알림이 발송됩니다.',
  broadcast_alarm_success: '라이브 방송 알림이 설정되었습니다.',
  broadcast_disconnected: '현재 방송 신호가 끊겼습니다. 잠시만 기다려 주세요.',
  broadcast_ended: '라이브가 종료되었습니다.',
  broadcast_notice: '방송 공지사항',
  broadcast_restored: '방송 신호가 복구 되었습니다.',
  broadcast_schedule_can_be_changed: '방송시간은 당사의 방송 사정에 따라 변경될 수 있습니다.',
  broadcast_started: '라이브가 시작되었습니다.',
  broadcast_unstable: '앗! 방송상태가 원활하지 않습니다.',
  buttons_at_bottom_right: '※ 오른쪽 맨하단 버튼 >',
  cancel: '취소',
  cancel_notifications: '라이브 방송 알림을 취소하시겠습니까?',
  cancel_to_register_purchase_auth_winner: '당첨자 정보 등록을 취소하시겠습니까?',
  canceled_broadcast: '취소된 방송입니다.\n다음 라이브에서 만나요!',
  cannot_add_sold_out_to_wish_list: '품절된 상품은 찜할 수 없습니다.',
  cannot_be_replayed: '다시보기가 제공되지 않는 방송입니다.',
  cannot_edit_after_sign_in: '닉네임은 채팅 참여 시 노출되며, 입력된 정보는 수정할 수 없습니다.',
  cannot_edited_after_sent:
    '전송 완료한 답글은 수정, 삭제가 불가하오니 전송 전, 확인 부탁드립니다.',
  change_nickname: '채팅 닉네임 변경하기',
  change_to_portrait: '세로모드에서 이용해 주세요.',
  chat_report: '채팅 신고',
  chat_restrict: '채팅 금지',
  chat_restrict_included: '채팅 금지 포함',
  chat_restricted_by_administrator: '관리자에 의해 채팅이 금지되었습니다.',
  chat_restriction: '채팅금지',
  chat_warning: '부적절한 언어 사용 시 서비스 이용이 제한될 수 있습니다.',
  chatroom_frozen: '관리자에 의해 채팅이 얼려졌습니다.',
  check_broadcast_alarm_notifications: '라이브 방송 알림을 받으시겠어요?',
  check_code: '입장코드를 확인해주세요.',
  click_to_play_info_video: '안내 동영상을 재생하려면 클릭하세요.',
  close: '닫기',
  confirm: '확인',
  congratulations_on_winning: '당첨을 축하합니다.',
  connection_unstable: '앗! 연결이 원활하지 않습니다.\n잠시만 기다려 주세요.',
  contact_exclude_dashes: '연락처 (- 제외)',
  converting_to_vod: '라이브가 종료되어 VOD로 전환중입니다.',
  copy: '복사',
  coupon: '쿠폰',
  coupon_issued: '쿠폰이 발급되었습니다.',
  coupon_received: '쿠폰 받음',
  current_nickname: '현재 닉네임',
  days_ago: '일 전',
  delete: '삭제',
  delete_comment: '댓글 삭제',
  delete_report: '신고리스트에서 삭제',
  deleted_comment_no_revert: '삭제하신 댓글은 되돌릴 수 없습니다.',
  discount: '할인',
  do_cancel: '취소하기',
  do_copy: '복사하기',
  do_delete: '삭제하기',
  do_pin: '고정하기',
  do_report: '신고하기',
  download: '다운로드',
  edit: '수정',
  edit_comment: '내 댓글 수정',
  email: '이메일',
  enter_at_lease_one: '최소 1글자 이상 입력해주세요.',
  enter_at_least_3_purchase_number: '최소 3자리 이상 구매번호 입력',
  enter_banned_terms: '금칙어를 입력하세요.',
  enter_chat: '채팅 입장하기',
  enter_code: '입장코드 {{codeLength}}자리를 입력해 주세요.',
  enter_contact: '연락처를 입력하세요.',
  enter_contact_number_or_email: '연락처 또는 이메일 주소 입력 (미입력 시 미노출)',
  enter_email: '이메일을 입력하세요.',
  enter_max_15_chars: '최대 15자 입력',
  enter_max_40_chars: '최대 40자 입력',
  enter_name: '이름을 입력하세요.',
  enter_nickname: '닉네임을 입력하세요.',
  enter_purchase_authentication_winner: '구매인증 당첨자 입력',
  enter_purchase_number: '구매(주문) 번호를 입력하세요.',
  enter_reply: '답변을 입력하세요.',
  enter_same_message_consecutively: '동일한 채팅을 연속하여 입력할 수 없습니다.',
  enter_short_time_consecutively: '빠른 시간 내 채팅을 연속하여 입력할 수 없습니다.',
  enter_too_many_message: '채팅 입력이 과도하여 {{sec}}초간 입력할 수 없습니다.',
  enter_without_space: '띄어쓰기 없이 입력',
  entered_with_nickname: '{{nickname}}님 입장',
  entered_with_nickname_other: '{{nickname}}님 외 {{other}}명 입장',
  event_auto_exit_on_vod: 'VOD 전환 시, 이벤트는 자동으로 종료됩니다.',
  event_can_start_anytime_on_live: 'LIVE중 언제든지 이벤트를 시작할 수 있습니다.',
  event_management: '이벤트 관리',
  event_start: '이벤트 시작',
  exit: '나가기',
  expose_with_masking_and_max: '(최대 50명, 일부 정보는 마스킹(*)되어 노출됩니다.)',
  failed_issue_coupon: '쿠폰 발급에 실패하였습니다.',
  failed_to_enter_message: '채팅 입력을 실패하였습니다.',
  failed_to_enter_message_with_error:
    '채팅 입력을 실패하였습니다. \n에러 : [로그인 정보 조회에 실패하였습니다. \n재로그인을 부탁드립니다.]',
  female: '여성',
  fill_out_form_to_chat: '채팅 참여를 위해 아래 정보를 입력해 주세요.',
  freeze_chat: '채팅 얼리기',
  full: '풀영상보기',
  harassment_abuse: '희롱 또는 괴롭힘',
  hide_comments: '답글 숨기기',
  highlight: '하이라이트 보기',
  hours_ago: '시간 전',
  info_area: '안내사항 영역',
  info_image: '안내 이미지',
  info_image_displayed: '등록된 안내 이미지가\n시청자에게 노출중입니다.',
  information_message_to_winner: '당첨 관련 안내메시지',
  intro: '소개',
  invalid_email_format: '잘못된 형식의 이메일입니다.',
  invalid_phone_format: '잘못된 형식의 연락처입니다. 01012345678 형식으로 입력해주세요.',
  is_where_see_all_comments:
    '<bold>{{location}}</bold>에서 관리자가 작성한 모든 답글을 확인하실 수 있습니다.',
  issue_during_live: '라이브에서만 발급 가능',
  join_broadcast: '방송 입장하기',
  join_chat_real_time: '실시간 채팅 참여',
  join_secret: '시크릿 라이브 입장',
  leave_broadcast: '방송을 나가시겠습니까?',
  live_comment: '라이브 답글',
  live_comments_tab: '라이브 답글 탭',
  live_intro: '라이브 소개',
  live_notice: '라이브 공지',
  male: '남성',
  mins_ago: '분 전',
  more_comments: `답글 {{count}}개 더보기`,
  mr: '<bold>{{name}}</bold>님',
  must_agree_collect_use_info: '개인정보 수집 및 이용에 대해 필수로 동의하셔야 합니다.',
  must_select_all_option: '선택사항은 전부 선택해야 합니다.',
  name: '이름',
  new_nickname: '변경 닉네임',
  nickname: '닉네임',
  no: '아니오',
  no_content: '내용이 없습니다.',
  no_coupon: '사용가능한 쿠폰이 없습니다.\n다음 쿠폰을 기다려주세요!',
  no_intro: '등록된 소개가 없습니다.',
  no_notice: '등록된 공지가 없습니다.',
  no_products_in_wish_list: '찜한 상품이 없습니다.\n마음에 드는 상품을 찜해보세요.',
  no_reply: '등록된 답글이 없습니다.',
  no_shortcut: '등록한 단축어가 없습니다.',
  none: '없음',
  note: '유의사항',
  now: '방금전',
  obscene_message: '음란 메세지',
  obscenity_abuse: '음란성 또는 괴롭힘',
  only_possible_during_live: '라이브에서만 가능합니다.',
  optional: '선택사항',
  other_inappropriate_language: '기타 부적절한 발언',
  participate: '참여하기',
  participate_success: '구매인증 참여완료!',
  phone_call_only_mobile: '전화연결은 모바일에서 가능합니다.',
  phone_number: '전화번호',
  pin_chat: '채팅고정',
  pin_chat_to_top: '채팅 영역에 고정하시겠습니까?',
  please_enter_nickname: '닉네임을 입력해주세요.',
  please_input_accurately: '이벤트 당첨 시 활용되므로 정확하게 입력해주세요.',
  price_differ_after_live: '라이브 종료 시, 가격 및 혜택이 변경될 수 있습니다.',
  products: '판매 상품',
  profanity_aggressive_expression: '비속어 또는 증오심 표현',
  purchase: '구매하기',
  purchase_auth_icon_exposed_if_event_on:
    '이벤트 시작 ON으로 변경 시, 플레이어 내에 구매인증 아이콘이 노출됩니다.',
  purchase_auth_result_icon_exposed_if_announcement_on:
    '당첨자 발표 ON으로 변경 시, 플레이어 내에 구매인증 결과 아이콘이 노출됩니다.',
  purchase_authentication: '구매인증',
  purchase_authentication_event: '구매인증 이벤트',
  purchase_authentication_event_winner: '구매인증 이벤트 당첨자',
  purchase_authentication_participant_info: '구매인증 참여 정보',
  push_notifications: 'PUSH 알림',
  push_notifications_turned_off_in_my_lotte:
    'PUSH 알림이 꺼져있습니다.\n마이롯데>설정에서 PUSH 알림을 켜주세요.',
  real_time_chat: '실시간채팅',
  reason_for_reporting: '신고사유',
  receive_coupon: '쿠폰 받기',
  receive_notifications: '알림 받기',
  receive_notifications_of_broadcast: '방송 알림받기',
  receiving_notifications: '알림받는중',
  recent_shortcut: '최근 등록한 단축어',
  register: '등록',
  register_broadcast_alarm: '방송알림 신청',
  register_notice_too: '라이브 공지에도 등록됩니다.',
  register_success: '당첨자 등록이 완료되었습니다.',
  report_count: '신고 {{count}}',
  report_list: '신고 리스트',
  report_submitted: '신고가 접수 되었습니다.',
  required: '필수',
  select_reason_for_reporting: '신고 사유를 선택해 주세요.',
  send: '전송',
  send_report: '신고접수',
  service_restricted_with_false_report: '허위신고 접수 시 서비스 이용이 제한될 수 있습니다.',
  set_nickname: '닉네임 설정',
  share_broadcast: '방송 공유하기',
  share_livestream: '방송 공유',
  shortcut: '단축어',
  shortcut_can_use_in_liveconsole: '단축어 기능은 라이브콘솔에서 사용할 수 있습니다.',
  show_nickname_on_chat: '닉네임은 채팅 입력시 노출됩니다.',
  sign_in: '로그인',
  sign_in_chat: '채팅 참여',
  sign_in_info_timed_out: '로그인 정보가 만료되었습니다. \n재로그인을 부탁드립니다.',
  signed_in: '{{nickname}}으로 로그인하였습니다.',
  sold_out: '품절',
  spam: '스팸 메세지',
  speed: '배속',
  stop: '내보내기 중지',
  subscribe: '구독하기',
  subscribe_shop: `{{shopName}}의 라이브방송을 구독하시겠습니까?`,
  subscriber_with_count: '구독자 {{count}}',
  subscribing: '구독중',
  touch_turn_on_sound: '소리를 켜려면 누르세요.',
  transmit: '내보내기',
  turn_on_notifications: '알림켜기',
  unable_find_sign_in_path: '로그인 경로를 찾을 수 없습니다.',
  unfreeze_chat: '채팅 녹이기',
  unpin: '고정해제',
  unpin_chat: '채팅 고정 해제',
  unpin_chat_to_top: '채팅 영역에서 고정 해제하시겠습니까?',
  unsubscribe_shop: `{{shopName}}의 라이브방송을 구독취소하시겠습니까?`,
  up_to_discount: '최대 {{discount}} 할인',
  up_to_discount_upon_purchasing: '최소 {{threshold}} 이상 구매 시 최대 {{discount}} 할인',
  upon_purchasing: '최소 {{threshold}} 이상 구매 시',
  url_copied: 'URL 복사가 완료되었습니다.',
  verify: '인증하기',
  view_more: '더보기',
  view_on_app: '앱으로 보기',
  view_on_web: '웹으로 보기',
  view_terms: '약관보기',
  viewable_full_horizontal_video: '가로 송출 영상은 가로 시청 시 전체화면 시청이 가능합니다.',
  wait_until_live_start:
    '채팅은 라이브가 시작되면 참여하실 수 있습니다.\n라이브가 시작하기 전까지 기다려주세요.',
  winner_add: '당첨자 입력',
  winner_announcement: '당첨자 발표',
  winner_announcement_can_control_after_add:
    '당첨자 정보 입력 후 당첨자 발표를 ON/OFF 할 수 있습니다.',
  winner_info_can_modify_after_vod: 'VOD 전환 시에도 당첨자 정보를 입력/수정할 수 있습니다.',
  winner_information: '당첨자 정보',
  winner_management: '당첨자 관리',
  wish_list: '찜한 상품',
  wish_to_delete: '삭제하시겠습니까?',
  wish_to_receive_notifications: '라이브 방송 알림을 받으시겠습니까?',
  wish_to_sign_in: '로그인 하시겠습니까?',
  write_comments: '답글쓰기',
  writing_comments: '{{nickname}}에게 답글 남기는 중',
  yes: '예',

  common_brand: {
    ebay: 'G마켓',
    auction: '옥션',
    samsung: '삼성닷컴',
    ldfs: '롯데면세점',
    shinsegaelive: '신세계라이브쇼핑',
    lfmall: 'LF몰',
    yanolja: '야놀자',
    momq: '맘큐',
    hanatour: '하나투어',
    hanssem: '한샘',
    coway: '코웨이',
    ellotte: '롯데백화점',
    lotteon: '롯데온',
    livart: '현대 리바트',
    dongwonmall: '동원',
    'lg-uplus': '유플러스',
    abcmart: 'ABC마트',
    posa: '우체국쇼핑',
    innisfree: '이니스프리',
    happyprince: '해피프린스',
    pastelmall: '파스텔몰',
    thebanchan: '더반찬',
  },
  main_page: {
    event: '특별 기획전',
    recent: '최신 라이브',
    next_live: '방송 예정 라이브',
    top_10_live: '라이브 TOP 10',
    brand: '브랜드',
    more: '더보기',
    policy_context:
      '모비두 주식회사는 통신판매중개업자로서 통신판매의 당사자가 아닙니다. 따라서 상품, 거래, 배송, 환불, 기타 통신판매에 따른 법적 책임을 부담하지 않으며, 그러한 책임은 통신판매중개를 의뢰한 자 등 제3자에게 있습니다.',
    close_title: 'SauceTV를 종료하시겠어요?',
    close_cancel: '뒤로 이동',
    close_confirm: '종료',
    info_version: '버전',
    info_device: '기기',
    info_language: '언어',
    info_platform: '플랫폼',
    info_region: '지역',
    info_contact: '문의',
    info_close: '닫기',
  },
  schedule_page: {
    today_live: '오늘 라이브',
    tomorrow_live: '내일 라이브',
    no_regist_live: '등록된 라이브가 없습니다.',
  },
  partner_page: {
    contents_tab: '컨텐츠',
    schedule_tab: '스케쥴',
    no_regist_contents: '등록된 콘텐츠가 없습니다.',
    no_regist_live: '등록된 라이브가 없습니다.',
  },
  player_page: {
    entered_with_nickname: '{{nickname}}님 입장',
    entered_with_nickname_other: '{{nickname}}님 외 {{other}}명 입장',
    preset_1: '안녕하세요',
    preset_2: '좋아요!',
    preset_3: '웃겨요!',
    preset_4: '슬퍼요',
    preset_5: '지루해요',
    preset_6: '박수',
    preset_7: '놀라운데요~',
    icon_notice: '공지',
    icon_chat: '채팅',
    modal_qr_scan_info: '스마트폰으로 아래의 QR 코드를 스캔하세요.',
    modal_live_intro_tab: '라이브 소개',
    modal_live_intro_no_cont: '등록된 소개가 없습니다.',
    modal_live_notice_tab: '라이브 공지',
    modal_live_notice_no_cont: '등록된 공지가 없습니다.',
    modal_live_reply_tab: '라이브 답글',
    modal_live_reply_no_cont: '등록된 답글이 없습니다.',
    modal_close: '닫기',
    banner_default_text: '더 많은 방송 보기',

    product_title: '판매상품',
    product_info_text: '라이브 종료 시, 가격 및 혜택이 변경될 수 있습니다.',
    product_soldout: '품절',
    before_chat_text:
      '라이브 채팅이 시작되면 참여하실 수 있습니다. \n라이브를 시작하기 전까지 기다려 주세요.',
    before_notice: '방송 공지사항',
  },
}

export default ko
