import React from 'react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import {
  FocusableComponentLayout,
  FocusDetails,
  KeyPressDetails,
  useFocusable,
} from '@noriginmedia/norigin-spatial-navigation'

interface Props {
  image: string
  name: string
  partnerId: string
  onEnterPress: (props: object, details: KeyPressDetails) => void
  onFocus: (layout: FocusableComponentLayout, props: object, details: FocusDetails) => void
  index: number
}

const LogoTvCard: React.FC<Props> = ({ image, name, partnerId, onEnterPress, onFocus, index }) => {
  const { ref, focused } = useFocusable({
    onEnterPress,
    onFocus,
    focusKey: `brand-${partnerId}-${index}`,
    extraProps: {
      section: 'brand',
      partnerId,
      broadcastId: '',
      index,
    },
  })

  return (
    <StyledPartnerLogo ref={ref} isFocused={focused}>
      <div
        className="partner_logo"
        style={{ backgroundImage: `url(${image + '?q=70w=200&h=200&f=webp'})` }}
      />
      <div className="partner_name">{name}</div>
    </StyledPartnerLogo>
  )
}

const StyledPartnerLogo = styled.div<{ isFocused: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 15px;

  :nth-of-type(1) {
    margin-left: 0;
  }

  .partner_logo {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border: 10px solid #1f1f1f;
    ${({ isFocused }) =>
      isFocused &&
      css`
        border: 10px solid #fff;
      `}
  }
  .partner_name {
    text-align: center;
    font-size: 27px;
    letter-spacing: -1px;
    color: ${(props) => props.theme.brownGrey2};
    margin-top: 20px;
    width: 200px;
  }
`

export default LogoTvCard
