import React, { useEffect, useState } from 'react'
import { AiOutlinePushpin } from 'react-icons/ai'
import { useSelector } from 'react-redux'
import styled from '@emotion/styled'
import useBroadcastStatus from 'libs/hooks/useBroadcastStatus'
import { RootState } from 'libs/store'

const ChatAdminNotice: React.FC = () => {
  const currentNotice = useSelector((state: RootState) => state.player.currentNotice)

  const { isLive } = useBroadcastStatus()

  const [noticeText, setNoticeText] = useState<string | undefined>()

  /**
   * 라이브 공지 갱신 useEffect
   */
  useEffect(() => {
    setNoticeText(
      currentNotice
        ? (currentNotice?.datas || currentNotice?.data)?.replace(/\n/gi, '   ')
        : undefined,
    )
  }, [currentNotice])

  return (
    <ChatAdminNoticeWrapper>
      {currentNotice && isLive ? (
        <div className="noticeAdminCover">
          <AiOutlinePushpin className="pinIcon" />
          <div className="noticeAdminTextBox">
            <div className="noticeAdminTextFlow">
              <span>{noticeText}</span>
            </div>
          </div>
        </div>
      ) : null}
    </ChatAdminNoticeWrapper>
  )
}

const ChatAdminNoticeWrapper = styled.div`
  display: flex;
  padding: 0px;
  border-radius: 4px;
  width: 100%;

  .noticeAdminCover {
    color: #0c4da2;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    overflow: hidden;
    margin: 10px 0 20px 0;
    z-index: 20;

    .pinIcon {
      width: 30px;
      height: 30px;
      font-size: 30px;
      font-weight: 600;
    }

    .noticeAdminTextBox {
      padding: 0 10px;
      overflow: hidden;

      .noticeAdminTextFlow {
        width: 100%;
        overflow: hidden;

        span {
          display: inline-block;
          white-space: pre;
          font-size: 22px;
          font-weight: 600;
          animation: 25s adminTextMove infinite linear;
        }
      }
    }
  }

  p {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-size: 21px;
    font-weight: 700;
    line-height: 40px;
    color: #0c4da2;
  }

  @keyframes adminTextMove {
    0% {
      transform: translate(0%);
    }
    10% {
      transform: translate(0%);
    }
    95% {
      transform: translate(-100%);
    }
    100% {
      transform: translate(-100%);
    }
  }
`

export default ChatAdminNotice
