const PARTNERPAGE_TABNAME = {
  CONTENTS: 'contents',
  SCHEDULE: 'schedule',
}

const SCHEDULEPAGE_TABNAME = {
  TODAY: 'today',
  TOMORROW: 'tomorrow',
}

const PARTNER_PAGE_NAME = 'partner'

const SCHEDULE_PAGE_NAME = 'schedule'

export { PARTNER_PAGE_NAME, PARTNERPAGE_TABNAME, SCHEDULE_PAGE_NAME, SCHEDULEPAGE_TABNAME }
