import React, { PropsWithChildren } from 'react'
import styled from '@emotion/styled'

const Layout: React.FC<PropsWithChildren> = ({ children }) => {
  return <LayoutWrapper>{children}</LayoutWrapper>
}

const LayoutWrapper = styled.div`
  position: relative;
  padding: 0 40px;
  margin: 0 auto;
  width: 100%;
  min-width: 360px;
`

export default Layout
