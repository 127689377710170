import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TEST_PARTNER_ID_LIST } from 'libs/constants/testPartnerIdList'

interface InitialState {
  allBroadcastList: Broadcast[] | null
  allScheduleList: Broadcast[] | null
  broadcastListPerPartner: { partnerId: string; broadcastList: Broadcast[] }[] | null
  isBroadcastLoading: boolean
  isPartnerListLoading: boolean
  isPartnerBroadcastLoading: boolean
  isPartnerScheduleLoading: boolean
  isEventLoading: boolean
  isScheduleLoading: boolean
  eventSectionSetting: {
    showEventSection: boolean
    eventSectionTitle: string
  }
  partnerList: PartnerType[] | null
  recentBroadcastList: Broadcast[] | null
  recentScheduleList: Broadcast[] | null
  eventBroadcastList: Broadcast[] | null
  renderPartnerId: string | null
  selectedPartnerId: string | null
  selectedPartnerBroadcastList: Broadcast[] | null
  selectedPartnerScheduleList: Broadcast[] | null
}

const initialState: InitialState = {
  allBroadcastList: null,
  allScheduleList: null,
  broadcastListPerPartner: null,
  isBroadcastLoading: true,
  isPartnerListLoading: true,
  isPartnerBroadcastLoading: true,
  isPartnerScheduleLoading: true,
  isEventLoading: true,
  isScheduleLoading: true,
  eventSectionSetting: {
    showEventSection: false,
    eventSectionTitle: '',
  },
  partnerList: null,
  recentBroadcastList: null,
  recentScheduleList: null,
  eventBroadcastList: null,
  renderPartnerId: null,
  selectedPartnerId: null,
  selectedPartnerBroadcastList: null,
  selectedPartnerScheduleList: null,
}

const appStore = createSlice({
  name: 'app',
  initialState,
  reducers: {
    REQ_GET_PARTNER_LIST(state) {
      state.isPartnerListLoading = true
    },
    REQ_GET_RECENT_BROADCAST_LIST(
      state,
      { payload: { renderPartnerId } }: PayloadAction<{ renderPartnerId: string | null }>,
    ) {
      state.isBroadcastLoading = true
      state.renderPartnerId = renderPartnerId
    },
    REQ_GET_SCHEDULE_LIST(
      state,
      { payload: { renderPartnerId } }: PayloadAction<{ renderPartnerId: string | null }>,
    ) {
      state.isScheduleLoading = true
      state.renderPartnerId = renderPartnerId
    },
    REQ_GET_BROADCAST_LIST_OF_PARTNER(
      state,
      { payload: { partnerId } }: PayloadAction<{ partnerId: string }>,
    ) {
      state.isPartnerBroadcastLoading = true
      state.selectedPartnerId = partnerId
    },
    REQ_GET_SCHEDULE_LIST_OF_PARTNER(
      state,
      { payload: { partnerId } }: PayloadAction<{ partnerId: string }>,
    ) {
      state.isPartnerScheduleLoading = true
      state.selectedPartnerId = partnerId
    },
    REQ_GET_EVENT_BROADCAST_LIST(state) {
      state.isEventLoading = true
    },
    RES_GET_PARTNER_LIST(state, { payload }) {
      const excludeTestAccounts = payload.items.filter(
        (item) => !TEST_PARTNER_ID_LIST.includes(item.partnerId),
      )
      state.partnerList = excludeTestAccounts
      state.isPartnerListLoading = false
    },
    RES_GET_RECENT_BROADCAST_LIST(state, { payload }) {
      const excludeTestAccounts = payload.items.filter(
        (item) => !TEST_PARTNER_ID_LIST.includes(item.partnerId),
      )
      state.recentBroadcastList = excludeTestAccounts
      state.isBroadcastLoading = false
    },
    RES_GET_SCHEDULE_LIST(state, { payload }) {
      const excludeTestAccounts = payload.items.filter(
        (item) => !TEST_PARTNER_ID_LIST.includes(item.partnerId),
      )
      state.recentScheduleList = excludeTestAccounts
      state.isScheduleLoading = false
    },
    RES_GET_BROADCAST_LIST_OF_PARTNER(state, { payload }) {
      const excludeTestAccounts = payload.items.filter(
        (item) => !TEST_PARTNER_ID_LIST.includes(item.partnerId),
      )
      state.selectedPartnerBroadcastList = [...excludeTestAccounts]
      state.isPartnerBroadcastLoading = false
    },
    RES_GET_SCHEDULE_LIST_OF_PARTNER(state, { payload }) {
      const excludeTestAccounts = payload.items.filter(
        (item) => !TEST_PARTNER_ID_LIST.includes(item.partnerId),
      )
      state.selectedPartnerScheduleList = [...excludeTestAccounts]
      state.isPartnerScheduleLoading = false
    },
    RESET_SELECTED_PARTNER_BROADCAST_LIST(state) {
      state.selectedPartnerBroadcastList = null
      state.selectedPartnerId = null
    },
    RESET_SELECTED_PARTNER_SCHEDULE_LIST(state) {
      state.selectedPartnerScheduleList = null
      state.selectedPartnerId = null
    },
    REQ_SET_RENDER_PARTNER_ID(state, { payload }) {
      state.renderPartnerId = payload.renderPartnerId
    },
    RES_GET_EVENT_BROADCAST_LIST(state, { payload }) {
      state.eventBroadcastList = payload.items
      state.eventSectionSetting = {
        showEventSection: payload.showEventRow,
        eventSectionTitle: payload.sectionTitle ?? '',
      }
      state.isEventLoading = false
    },
  },
})

export const app = appStore.reducer
export const {
  REQ_GET_PARTNER_LIST,
  REQ_GET_RECENT_BROADCAST_LIST,
  REQ_GET_SCHEDULE_LIST,
  REQ_GET_BROADCAST_LIST_OF_PARTNER,
  REQ_GET_SCHEDULE_LIST_OF_PARTNER,
  REQ_GET_EVENT_BROADCAST_LIST,
  RES_GET_PARTNER_LIST,
  RES_GET_RECENT_BROADCAST_LIST,
  RES_GET_SCHEDULE_LIST,
  RES_GET_BROADCAST_LIST_OF_PARTNER,
  RES_GET_SCHEDULE_LIST_OF_PARTNER,
  RESET_SELECTED_PARTNER_BROADCAST_LIST,
  RESET_SELECTED_PARTNER_SCHEDULE_LIST,
  REQ_SET_RENDER_PARTNER_ID,
  RES_GET_EVENT_BROADCAST_LIST,
} = appStore.actions
