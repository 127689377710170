import React from 'react'
import styled from '@emotion/styled'
import ChatTopBrandIcon from './ChatTopBrandIcon'
import ChatTopTitle from './ChatTopTitle'

interface Props {
  broadcastName?: string
  partnerId: string | undefined
  partnerProfileImageUrl?: string
}

const ChatTop: React.FC<Props> = ({ broadcastName, partnerId, partnerProfileImageUrl }) => {
  return (
    <ChatTopWrapper>
      <ChatTopBrandIcon
        broadcastName={broadcastName}
        partnerProfileImageUrl={partnerProfileImageUrl}
      />
      <ChatTopTitle broadcastName={broadcastName} partnerId={partnerId || null} />
    </ChatTopWrapper>
  )
}

const ChatTopWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 25px;
  align-items: center;
`

export default ChatTop
