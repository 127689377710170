import React from 'react'
import { useLocation } from 'react-router-dom'
import styled from '@emotion/styled'
import HeaderLogo from 'components/logo/Logo'
import Layout from '../../wrappers/Layout'

const HeaderContainer: React.FC = () => {
  const environment = process.env.REACT_APP_ENV

  const { pathname } = useLocation()
  const path = pathname.split('/')[1]

  if (path === 'player') return null
  return (
    <Layout>
      <HeaderWrapper>
        <HeaderLeftWrapper>
          <HeaderLogo />
          <HeaderEnvDescription>
            {environment !== 'production' && <div>{environment} 환경입니다.</div>}
          </HeaderEnvDescription>
        </HeaderLeftWrapper>
      </HeaderWrapper>
    </Layout>
  )
}

const HeaderWrapper = styled.div`
  @font-face {
    font-family: 'Pretendard-Regular';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff')
      format('woff');
    font-weight: 400;
    font-style: normal;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 0px;
`
const HeaderLeftWrapper = styled.div`
  display: flex;
  align-items: center;
`

const HeaderEnvDescription = styled.div`
  color: red;
`

export default HeaderContainer
