import React from 'react'
import { useSelector } from 'react-redux'
import styled from '@emotion/styled'
import useBroadcastStatus from 'libs/hooks/useBroadcastStatus'
import { RootState } from 'libs/store'

const ChatRoomInfo: React.FC = () => {
  const incomingMessageData = useSelector((state: RootState) => state.player.incomingMessage)

  const { isLive } = useBroadcastStatus()

  return (
    <ChatRoomInfoWrapper>
      {isLive ? (
        <div className="chatRoomInfo">
          <p>{incomingMessageData}</p>
        </div>
      ) : (
        <p>&nbsp;</p>
      )}
    </ChatRoomInfoWrapper>
  )
}

const ChatRoomInfoWrapper = styled.div`
  display: flex;
  width: 100%;

  .chatRoomInfo {
    display: inline-block;
    padding: 0 20px;
    border-radius: 20px;
    background-color: #0000004d;

    p {
      font-size: 24px;
      font-weight: 400;
      color: #fff;
      line-height: 40px;
    }
  }
`

export default ChatRoomInfo
