import React from 'react'
import styled from '@emotion/styled'

const Loading: React.FC = () => {
  return (
    <LoadingWrapper>
      <LoadingContainer>
        <LoadingBall className="odd" />
        <LoadingBall className="even" />
        <LoadingBall className="odd" />
      </LoadingContainer>
    </LoadingWrapper>
  )
}

const LoadingWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #16161a;
  z-index: 20;
`

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 200px;
  gap: 50px;
`

const LoadingBall = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.orange};

  @keyframes load1 {
    0% {
      transform: translateY(18px);
    }
    32% {
      transform: translateY(-18px);
    }
    65% {
      transform: translateY(18px);
    }
    100% {
      transform: translateY(-18px);
    }
  }

  @keyframes load2 {
    0% {
      transform: translateY(-18px);
    }
    24% {
      transform: translateY(18px);
    }
    65% {
      transform: translateY(-18px);
    }
    100% {
      transform: translateY(18px);
    }
  }

  &.odd {
    animation: load1 1.5s linear infinite alternate;
  }

  &.even {
    animation: load2 1.5s linear infinite alternate;
  }
`

export default Loading
