import { useEffect, useState } from 'react'
import { useIsElementInViewport } from './useIsElementInViewPort'

export const useIsImageLoaded = () => {
  const { elementRef, isVisible } = useIsElementInViewport({
    root: null,
    threshold: 0.01,
  })
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    if (isLoaded || !isVisible) {
      return
    }

    setIsLoaded(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible])

  return { elementRef, isLoaded }
}
