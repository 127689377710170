import React from 'react'
import { MdLiveTv } from 'react-icons/md'
import styled from '@emotion/styled'
import i from 'libs/i18n'

const EmptyContent = () => {
  return (
    <EmptyContentWrapper>
      <MdLiveTv size={100} color={'#fff'} />
      <h1>{i.t('partner_page.no_regist_contents')}</h1>
    </EmptyContentWrapper>
  )
}

export default EmptyContent

const EmptyContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 0;
  width: 100%;
  gap: 60px;
  color: '#fff';

  h1 {
    color: white;
    font-size: 60px;
    line-height: 100px;
  }
`
