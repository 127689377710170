import React from 'react'
import { useSearchParams } from 'react-router-dom'
import PlayerContainer from 'containers/player/Player.container'

const PlayerPage: React.FC = () => {
  const [searchParams] = useSearchParams()
  const broadcastId = searchParams.get('broadcastId')

  if (!broadcastId) return null
  return <PlayerContainer broadcastId={broadcastId} isModal={false} />
}

export default PlayerPage
