import { Theme } from '@emotion/react'

export const defaultTheme: Theme = {
  white: '#ffffff',
  orange: '#ff6a1a',
  brownGrey: '#8f8f8f',
  brownGrey2: '#b5b5b5',
  black2: '#2f2f2f',
  black3: '#1f1f1f',
}
