import React, { useCallback, useRef } from 'react'
import { useSelector } from 'react-redux'
import styled from '@emotion/styled'
import {
  FocusContext,
  KeyPressDetails,
  useFocusable,
} from '@noriginmedia/norigin-spatial-navigation'
import i from 'libs/i18n'
import { RootState } from 'libs/store'
import Product from './Product'

interface Props {
  onPressEnter: (props: object, details: KeyPressDetails) => void
}

const ProductLayout: React.FC<Props> = ({ onPressEnter }) => {
  const broadcastInfo = useSelector((state: RootState) => state.player.selectedBroadcast)

  const { ref, focusKey } = useFocusable({
    focusKey: 'product',
  })

  const productDetailList = broadcastInfo?.productDetailList

  const scrollingRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null)

  const onFocusProduct = useCallback(
    ({ y }) => {
      if (scrollingRef.current) {
        scrollingRef.current.scrollTo({ top: y, behavior: 'smooth' })
      }
    },
    [scrollingRef],
  )

  return (
    <ProductContainer>
      <FocusContext.Provider value={focusKey}>
        <ProductTopInfoWrapper>
          <ProductLayoutTitle>
            {i.t('player_page.product_title')}&nbsp;
            <span>{productDetailList?.length ?? 0}</span>
          </ProductLayoutTitle>
          <ProductCautionInfo>
            <span>{i.t('player_page.product_info_text')}</span>
          </ProductCautionInfo>
        </ProductTopInfoWrapper>
        <ProductListWrapper ref={ref}>
          <ProductScrollableWrapper ref={scrollingRef}>
            {productDetailList?.map(
              (
                {
                  brandName,
                  currencyId,
                  discountRate,
                  isSoldout,
                  price,
                  productId,
                  productName,
                  priceTypeCode,
                  productThumbnailUrlList,
                  sellingPrice,
                  redirectUrl,
                },
                index,
              ) => {
                return (
                  <Product
                    key={`product-${index}`}
                    index={index}
                    brandName={brandName}
                    currencyId={currencyId}
                    discountRate={discountRate}
                    isSoldOut={isSoldout}
                    price={price}
                    productId={productId}
                    priceTypeCode={priceTypeCode}
                    productName={productName}
                    redirectUrl={redirectUrl ?? ''}
                    sellingPrice={sellingPrice.toString()}
                    thumbnailUrl={productThumbnailUrlList[0].thumbnailUrl}
                    onEnterPress={onPressEnter}
                    onFocus={onFocusProduct}
                  />
                )
              },
            )}
          </ProductScrollableWrapper>
        </ProductListWrapper>
      </FocusContext.Provider>
    </ProductContainer>
  )
}

const ProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100vw / 3);
  height: 100%;
  padding: 20px;
  margin: 20px 0 40px 0;
`

const ProductListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow: hidden;
`
const ProductTopInfoWrapper = styled.div`
  display: block;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`
const ProductLayoutTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 33px;
  font-weight: 700;
  span {
    color: #ff3d33;
  }
`
const ProductScrollableWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`
const ProductCautionInfo = styled.p`
  background-color: rgba(247, 248, 250, 0.6);
  padding: 18px 10px;
  margin-top: 0px;
  border-radius: 8px;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  span {
    width: 100%;
    font-size: 22px;
    color: #ff3d33;
    line-height: 22px;
    text-align: center;
    font-weight: 700;
  }
`

export default ProductLayout
