import React from 'react'
import styled from '@emotion/styled'

interface Props {
  thumbnailUrl: string
  productName: string
}

const Image: React.FC<Props> = ({ productName, thumbnailUrl }) => {
  return (
    <ImageWrapper>
      <img src={thumbnailUrl} alt={`${productName} 이미지`} />
    </ImageWrapper>
  )
}

const ImageWrapper = styled.div`
  position: relative;
  display: block;
  border: 1px solid #efefef;
  border-radius: 4px;
  width: 155px;
  min-width: 155px;
  height: 155px;
  min-height: 155px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export default Image
