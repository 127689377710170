import { useSelector } from 'react-redux'
import { BROADCAST_STATE_CODE } from 'libs/@types/enums'
import { RootState } from 'libs/store'

const { ACCEPTED, CANCEL, CONVERTING, ERROR, ON_AIR, READY, STAND_BY, SWITCHING, VOD, WARNING } =
  BROADCAST_STATE_CODE

/**
 * @hooks
 * @name useBroadcastStatus
 * @description broadcastState 를 모아서 제공하는 훅
 */
const useBroadcastStatus = () => {
  const selectedBroadcast = useSelector((state: RootState) => state.player.selectedBroadcast)

  const broadcastStateCode = selectedBroadcast?.broadcastStateCode
  const isAccepted = broadcastStateCode === ACCEPTED
  const isCancel = broadcastStateCode === CANCEL
  const isConverting = broadcastStateCode === CONVERTING
  const isError = broadcastStateCode === ERROR
  const isLive = broadcastStateCode === ON_AIR
  const isReady = broadcastStateCode === READY
  const isRejected = broadcastStateCode === null
  const isStandby = broadcastStateCode === STAND_BY
  const isSwitching = broadcastStateCode === SWITCHING
  const isVod = broadcastStateCode === VOD
  const isWarning = broadcastStateCode === WARNING

  const isBeforeBroadcast = isAccepted || isReady || isStandby
  const isBroadcastEnd = isCancel || isRejected || isVod

  return {
    isAccepted,
    isCancel,
    isConverting,
    isError,
    isLive,
    isReady,
    isRejected,
    isStandby,
    isSwitching,
    isVod,
    isWarning,
    isBeforeBroadcast,
    isBroadcastEnd,
  }
}

export default useBroadcastStatus
