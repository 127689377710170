import { RefObject, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import HlsPlayer from 'libs/hls/player'
import { SET_PLAYER } from 'libs/store/player/player.store'

const useVideoSetting = (videoRef: RefObject<HTMLVideoElement>) => {
  const dispatch = useDispatch()

  /**
   * 비디오 기본 컨피그 설정 useEffect
   */
  useEffect(() => {
    const video = videoRef.current
    if (!video) return

    video.playsInline = true
  }, [videoRef])

  /**
   * 비디오 객체 초기화 useEffect
   */
  useEffect(() => {
    const video = videoRef.current
    if (!video) return

    const hlsPlayer = new HlsPlayer()
    window.player = hlsPlayer
    dispatch(SET_PLAYER(hlsPlayer))
  }, [videoRef, dispatch])
}

export default useVideoSetting
