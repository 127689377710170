import { RefObject, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { SET_IS_PLAY, SET_IS_READY, SET_IS_SEEK_PLAYER } from 'libs/store/player/player.store'

const useVideoEventHandler = (videoRef: RefObject<HTMLVideoElement>) => {
  const dispatch = useDispatch()

  /**
   * 기본 이벤트 핸들러 매핑 useEffect
   */
  useEffect(() => {
    const video = videoRef.current
    if (!video) return

    const handleLoadedData = () => dispatch(SET_IS_READY(true))
    video.addEventListener<keyof HTMLVideoElementEventMap>('loadeddata', handleLoadedData)

    const handlePlay = () => dispatch(SET_IS_PLAY(true))
    video.addEventListener<keyof HTMLVideoElementEventMap>('play', handlePlay)

    const handlePause = () => dispatch(SET_IS_PLAY(false))
    video.addEventListener<keyof HTMLVideoElementEventMap>('pause', handlePause)

    const handleEnded = () => dispatch(SET_IS_PLAY(false))
    video.addEventListener<keyof HTMLVideoElementEventMap>('ended', handleEnded)

    const handleSeeking = () => dispatch(SET_IS_SEEK_PLAYER(true))
    video.addEventListener<keyof HTMLVideoElementEventMap>('seeking', handleSeeking)

    return () => {
      video.removeEventListener<keyof HTMLVideoElementEventMap>('loadeddata', handleLoadedData)
      video.removeEventListener<keyof HTMLVideoElementEventMap>('play', handlePlay)
      video.removeEventListener<keyof HTMLVideoElementEventMap>('pause', handlePause)
      video.removeEventListener<keyof HTMLVideoElementEventMap>('ended', handleEnded)
      video.removeEventListener<keyof HTMLVideoElementEventMap>('seeking', handleSeeking)
    }
  }, [videoRef, dispatch])
}

export default useVideoEventHandler
