import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import ENV from 'libs/config'
import { RootState } from 'libs/store'

/**
 * header 좌측 상단의 로고 컴포넌트
 */

const HeaderLogo: React.FC = () => {
  const renderPartnerId = useSelector((state: RootState) => state.app.renderPartnerId)

  const partnerBgUrl = useMemo(() => {
    switch (renderPartnerId) {
      case 'ebay':
        return `${ENV.RESOURCE_URL}/icons/logo_ebay.png`
      case null:
      default:
        return `${ENV.RESOURCE_URL}/icons/logo_sauceTV_1.svg`
    }
  }, [renderPartnerId])

  return <Logo bgUrl={partnerBgUrl} />
}

export default HeaderLogo

const Logo = styled.div<{ bgUrl: string }>`
  width: 220px;
  height: 48px;
  margin-right: 20px;
  ${({ bgUrl }) =>
    bgUrl &&
    css`
      background-image: url(${bgUrl});
    `}
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`
