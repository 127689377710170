import React, { useCallback, useRef } from 'react'
import { useSelector } from 'react-redux'
import styled from '@emotion/styled'
import {
  FocusableComponentLayout,
  FocusContext,
  FocusDetails,
  KeyPressDetails,
  useFocusable,
} from '@noriginmedia/norigin-spatial-navigation'
import SliderCard from 'components/card/SliderCard'
import i18n from 'i18next'
import i from 'libs/i18n'
import { RootState } from 'libs/store'

interface RecentBroadcastContainerProps {
  onPressEnter?: (props: object, details: KeyPressDetails) => void
  onFocus?: (layout: FocusableComponentLayout, props: object, details: FocusDetails) => void
}

const EventBroadcastContainer: React.FC<RecentBroadcastContainerProps> = (props) => {
  const { onPressEnter, onFocus } = props
  const eventSectionTitle = useSelector(
    (state: RootState) => state.app.eventSectionSetting.eventSectionTitle,
  )
  const eventBroadcastList = useSelector((state: RootState) => state.app.eventBroadcastList)
  const partnerList = useSelector((state: RootState) => state.app.partnerList)

  const { ref, focusKey } = useFocusable({
    focusKey: 'event',
    onFocus,
  })

  const scrollingRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null)

  const onAssetFocus = useCallback(
    (layout, extraProps, details) => {
      const { x: left } = layout
      const { event } = details
      if (scrollingRef.current) {
        scrollingRef.current.scrollTo({ left, behavior: !event ? 'instant' : 'smooth' })
      }
    },
    [scrollingRef],
  )

  return (
    <FocusContext.Provider value={focusKey}>
      <EventSectionWrapper ref={ref}>
        <EventSectionTitle>
          {(i18n.language === 'ko' || i18n.language === 'ko-KR') && eventSectionTitle
            ? eventSectionTitle
            : i.t('main_page.event')}
        </EventSectionTitle>
        <EventSectionScrollingWrapper ref={scrollingRef}>
          <EventSectionScrollingContent className="slider_box">
            {eventBroadcastList?.length &&
              eventBroadcastList?.map((broadcast, index) => {
                const {
                  broadcastId,
                  broadcastName,
                  broadcastStateCode,
                  broadcastThumbnailList,
                  partnerId,
                } = broadcast
                const representImage = broadcastThumbnailList?.filter(
                  ({ isRepresentative }) => !!isRepresentative,
                )
                const partnerInfo = partnerList?.find(
                  (partnerInfo) => partnerInfo.partnerId === partnerId,
                )

                const thumbnailUrl = representImage && representImage[0]?.thumbnailUrl

                return (
                  <SliderCard
                    key={`event-card-${broadcastId}`}
                    partnerId={partnerId}
                    broadcastStateCode={broadcastStateCode}
                    thumbnail={thumbnailUrl}
                    broadcastName={broadcastName}
                    broadcastId={broadcastId}
                    shopName={i.t(`common_brand.${partnerId}`)}
                    sectionName="event"
                    isShowPartnerInfo={true}
                    partnerProfileImageUrl={partnerInfo?.thumbnail}
                    onEnterPress={onPressEnter}
                    onFocus={onAssetFocus}
                    index={index}
                  />
                )
              })}
          </EventSectionScrollingContent>
        </EventSectionScrollingWrapper>
      </EventSectionWrapper>
    </FocusContext.Provider>
  )
}

const EventSectionWrapper = styled.div`
  width: 100%;
  padding-bottom: 20px;
`

const EventSectionTitle = styled.div`
  padding: 30px 0 0 20px;
  font-size: 48px;
  line-height: 64px;
  color: #fff;
  font-weight: 600;
`

const EventSectionScrollingContent = styled.div`
  display: flex;
  flex-direction: row;
`

const EventSectionScrollingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  overflow-x: auto;
  overflow-y: hidden;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
`
export default EventBroadcastContainer
