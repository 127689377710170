import React from 'react'
import { useSelector } from 'react-redux'
import styled from '@emotion/styled'
import {
  FocusContext,
  KeyPressDetails,
  useFocusable,
} from '@noriginmedia/norigin-spatial-navigation'
import ChatTop from 'components/chatTop/ChatTop'
import useBroadcastStatus from 'libs/hooks/useBroadcastStatus'
import useVodChat from 'libs/hooks/useVodChat'
import { RootState } from 'libs/store'
import ChatBanner from './ChatBanner'
import ChatBeforeLive from './ChatBeforeLive'
import ChatContent from './ChatContent'
import ChatInfo from './ChatInfo'
import ChatPreset from './ChatPreset'

interface Props {
  onPressEnter: (props: object, details: KeyPressDetails) => void
}

const ChatLayout: React.FC<Props> = ({ onPressEnter }) => {
  const canChat = useSelector((state: RootState) => state.player.selectedBroadcast?.canChat)
  const messageList = useSelector((state: RootState) => state.player.chatList)
  const broadcastInfo = useSelector((state: RootState) => state.player.selectedBroadcast)

  const { isBeforeBroadcast, isBroadcastEnd } = useBroadcastStatus()

  useVodChat()

  const { ref, focusKey } = useFocusable({
    focusKey: 'chatBottom',
  })

  const isShowChat = !!canChat && !isBeforeBroadcast && !isBroadcastEnd

  return (
    <FocusContext.Provider value={focusKey}>
      <ChatLayoutWrapper>
        {isBeforeBroadcast ? (
          <ChatBeforeWrapper ref={ref}>
            <ChatBeforeLive />
          </ChatBeforeWrapper>
        ) : (
          <>
            <ChatTop
              broadcastName={broadcastInfo?.broadcastName}
              partnerId={broadcastInfo?.partnerId}
              partnerProfileImageUrl={broadcastInfo?.shopImageUrl}
            />
            <ChatContent messageList={messageList} />
            <ChatBottomWrapper ref={ref}>
              <ChatBanner onPressEnter={onPressEnter} broadcastInfo={broadcastInfo} />
              <ChatPreset isShowChat={isShowChat} onPressEnter={onPressEnter} />
              <ChatInfo isShowChat={isShowChat} onPressEnter={onPressEnter} />
            </ChatBottomWrapper>
          </>
        )}
      </ChatLayoutWrapper>
    </FocusContext.Provider>
  )
}

const ChatLayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100vw / 3);
  height: 100vh;
  padding: 0 20px;
`

const ChatBottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`

const ChatBeforeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100vw / 3);
  height: 100vh;
`

export default ChatLayout
